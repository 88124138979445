import { useEffect, useRef } from 'react';


/**
 * A useEffect renamed in useUpdate. Hook that triggers every updates
 * @param effect Effector to run on updates
 * @param deps Dependencies list, as for `useEffect` hook
 */
export const useUpdate: typeof useEffect = (effect, deps) => {
	const isMounted = useRef(false);

	useEffect(() => {
		return effect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};
