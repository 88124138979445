import { CustomersMapper } from '@core/mappers/CustomersMapper';
import { GetCustomerResponse } from '@dto/Responses/Customers/GetCustomerResponse';
import { GetCustomerWithRecipientsResponse } from '@dto/Responses/Customers/GetCustomerWithRecipientsResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { Customer } from '@models/Customer';
import { CustomerWithDetails } from '@models/CustomerWithDetails';

import { CUSTOMERS } from '@transport/ApiDefinitions';
import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class CustomersService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('CustomersService');
		this.httpEngine = new GShopHttpEngine();
	}



	public async listCustomers(): Promise<Customer[]> {
		this.log.debug(`Fetching customers`);

		const responseDTO: GetCustomerResponse[] = await this.httpEngine.getAsArray<GetCustomerResponse>(GetCustomerResponse, CUSTOMERS.LIST);

		const customers = responseDTO.map(gcr => CustomersMapper.toCustomerModel(gcr));

		return customers;
	}



	public async getCustomerInfo(customerID: number): Promise<Customer | undefined> {
		this.log.debug(`Fetching #${customerID} customer info`);

		const url = CUSTOMERS.GET.replace('{customerID}', customerID.toString());

		const responseDTO: GetCustomerResponse[] = await this.httpEngine.getAsArray<GetCustomerResponse>(GetCustomerResponse, url);

		const customers = responseDTO.map(gcr => CustomersMapper.toCustomerModel(gcr));

		const customer: Customer | undefined = customers.shift();

		return customer;
	}



	public async listCustomersWithRecipients(onlyActive?: boolean): Promise<CustomerWithDetails[]> {
		this.log.debug(`Fetching customers with recipients [ONLYACTIVE: ${onlyActive}]`);

		const url = (onlyActive) ? CUSTOMERS.LIST_ACTIVE : CUSTOMERS.LIST_WITH_RECIPIENTS;

		const responseDTO: GetCustomerWithRecipientsResponse[] = await this.httpEngine.getAsArray<GetCustomerWithRecipientsResponse>(GetCustomerWithRecipientsResponse, url);

		const customers = responseDTO.map(gcr => CustomersMapper.toCustomerWithRecipientModel(gcr));

		return customers;
	}
}