import { UserMenu } from '@desktop/components/header/UserMenu';
import GShopLogo from '@images/store.png';
import { Badge } from 'primereact/badge';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import React, { ReactNode, useCallback, useState } from 'react';

import { useSidebarBadge } from '@hooks/sidebar/useSidebarBadge';
import { useAtomValue } from 'jotai/utils';
import { sidebarBadgeAtom } from '@atoms/SidebarBadgesAtom';
import { useSettings } from '@hooks/useSettings';
import { ModulesType, settingsAtom } from '@atoms/SettingsAtom';
import { Link, useHistory } from 'react-router-dom';
import { useUpdate } from '@libs/KzHooks/useUpdate';

import { useLog } from '@hooks/useLog';

import {
	FaBezierCurve,
	FaBox,
	FaBoxes,
	FaBug,
	FaChartLine,
	FaClipboardList,
	FaEuroSign,
	FaHome,
	FaInfo,
	FaList,
	FaRegFolderOpen,
	FaStar,
	FaUsers,
} from 'react-icons/fa';

import { GiGasMask } from 'react-icons/gi';
import { ImStatsDots } from 'react-icons/im';
import { IoPeople } from 'react-icons/io5';



export type SidebarMenuItem = {
	label: string,
	badge?: string,
	disabled?: boolean,
	to?: string,
	url?: string,
	icon?: string | ReactNode,
	style?: React.CSSProperties,
	target?: string,
	className?: string,
	command?: (event: React.SyntheticEvent<EventTarget>, item: SidebarMenuItem) => void,
	visible?: boolean,
	items?: SidebarMenuItem[],
}



type SidebarKzProps = {
	onMenuItemClickHandler?: (event: React.SyntheticEvent<EventTarget>, item: SidebarMenuItem) => void,

	children?: ReactNode
}


//#region Icon

type IconProps = {
	iconElement?: ReactNode,
}


const Icon: React.FC<IconProps> = ({ iconElement }) => {
	//<span className="icon-margins">{iconElement}</span>

	return iconElement
		   ?
		   ( <span style={ { marginRight: '5px' }}>{ iconElement }</span> )
		   :
		   ( <></> );
};
//#endregion



export const HorizontalBarKz: React.FC<SidebarKzProps> = ({ onMenuItemClickHandler }: SidebarKzProps) => {
	const log = useLog();

	// L'atom è superfluo, però in caso lo volessi usare da qualche altra parte, ho qui pronto uno snippet di codice già fatto
	const { isLoading, sidebarInfo } = useSidebarBadge();
	const badgesAtom = useAtomValue(sidebarBadgeAtom);

	const { settingsState } = useSettings();
	const settingAtom = useAtomValue(settingsAtom);

	const history = useHistory();

	const [ menuItems, setMenuItems ] = useState<SidebarMenuItem[]>([]);



	const loadMenu = useCallback(() => {
		const menu: Array<SidebarMenuItem> = [
			{ label: 'Dashboard', icon: <FaHome />, to: '/dashboard' },
			{
				label: 'Archivi', icon: <IoPeople />,
				items: [
					{ label: 'Clienti', icon: <FaUsers />, badge: sidebarInfo?.total_customers.toString(), to: '/customers/' },
					{ label: 'Articoli', icon: <FaStar />, badge: sidebarInfo?.total_articles.toString(), to: '/products/' },
					{ label: 'Scadenziario', icon: <FaEuroSign />, to: '/payments/' },
				],
			},
			{
				label: 'Ordini', icon: <FaClipboardList />,
				items: [
					{ label: 'Nuovo ordine', icon: <FaList />, to: '/orders/new' },
					{ label: 'Visualizza ordini', icon: <FaRegFolderOpen />, to: '/orders/list' },
				],
			},
			{
				label: 'Statistiche', icon: <ImStatsDots />,
				items: [
					{ label: 'VOC', icon: <GiGasMask />, to: '/statistics/voc', visible: ( settingAtom.modulesAvailables.some(x => x == ModulesType.VOC) ) },
					{
						label  : 'Venduto per categoria', icon: <FaBezierCurve />, to: '/statistics/custom/sales-by-category',
						visible: ( settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_CATEGORY) ),
					},
					{
						label  : 'Venduto per sottocategoria', icon: <FaBoxes />, to: '/statistics/custom/sales-by-subcategory',
						visible: ( settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_SUBCATEGORY) ),
					},
					{
						label  : 'Venduto per articolo', icon: <FaBox />, to: '/statistics/custom/sales-by-article',
						visible: ( settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_ARTICLE) ),
					},
					{
						label  : 'Previsione fatturato', icon: <FaChartLine />, to: '/statistics/custom/sales-forecast',
						visible: ( settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_FORECAST) ),
					},
				],
			},
			{ label: 'About', icon: <FaInfo />, to: '/about' },
			{ label: 'Debug', icon: <FaBug />, to: '/debug', visible: import.meta.env.DEV },
		];

		return menu;
	}, [ sidebarInfo, settingAtom ]);



	useUpdate(() => {
		if (isLoading) {
			return;
		}

		const menuItems = loadMenu();
		setMenuItems(menuItems);
	}, [ isLoading, sidebarInfo, settingAtom ]);


	const itemRenderer = (item) => (
		<a className="flex align-items-center p-menuitem-link">
			<span className={item.icon} />
			<span className="mx-2">{item.label}</span>
			{item.badge && <Badge className="ml-auto" value={item.badge} />}
			{item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
		</a>
	);



	const createMenuItem = (item: SidebarMenuItem): MenuItem => {
		const horizontalMenuItem = {
			label: item.label,
			icon : <Icon iconElement={ item.icon } />,
			template: itemRenderer,
		} as MenuItem;

		return horizontalMenuItem;
	}


	// Crea ricorsivamente tutti i menu items (usando la property items)
	const renderedMenuItems = useCallback((): MenuItem[] => {
		const m = menuItems.map((item) => createMenuItem(item));

		return m;

	}, [ menuItems ]);



	const start = (
		<>
			<Link to="/" style={ { marginRight: '5px' }}>
				<img className="logo" width="32" alt="logo" src={ GShopLogo } />
				{ /* <img style={{ marginLeft:10 }} width="32" alt="logo" src={ 'assets/logo192.png' } /> */ }
			</Link>

			{/*<Link to="/">*/}
			{/*	<h1>GShop</h1>*/}
			{/*</Link>*/}
		</>
	);

	const end = (
		<UserMenu />
	);


	return (
		<Menubar model={ renderedMenuItems() } start={ start } end={ end } />
	);
};
