import { GetArticleWithDiscounts } from '@dto/Responses/Articles/GetArticleWithDiscountsResponse';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { PagedData } from '@models/PagedData';
import { ArticlesService } from '@services/ArticlesService';
import { useQuery } from '@tanstack/react-query';



const DEFAULT_ARTICLES_PER_PAGE = 50;


export type ArticlesIvecoDiscountRequest = {
	Customer: number,
	Article?: string,
	Description?: string,
	Categories?: string[],
	PageNumber: number,
	PageSize: number,
	SortField?: string,
	SortMode?: number,
}


export const createDefaultIvecoArticlesDiscountRequest = (customerID: number): ArticlesIvecoDiscountRequest => {
	return {
		Customer: customerID,
		PageNumber: 1,
		PageSize: DEFAULT_ARTICLES_PER_PAGE,
	}
}


export const useArticlesIvecoWithDiscountList = (ar: ArticlesIvecoDiscountRequest) => {
	const log = useLog();

	const articlesService = useSingleton<ArticlesService>(() => new ArticlesService(), []);

	const queryInfo = useQuery<PagedData<GetArticleWithDiscounts>>({
		queryKey: ['list-iveco-articles-with-discount', ar],
		queryFn: async () => articlesService.listIvecoArticlesWithDiscounts(ar.Customer, ar.PageNumber, ar.PageSize, ar.SortField, ar.SortMode, ar.Article,  ar.Description, ar.Categories),
		keepPreviousData: true
	});

	return {
		...queryInfo,
		products: queryInfo.data?.data ?? new Array<GetArticleWithDiscounts>(),
		totalRecords: queryInfo.data?.TotalRecords ?? 0,
	}
};
