import { plainToClass } from '@deepkit/type';
import { AxiosError } from 'axios';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';



export type DTOResponse<T> = new (...args: any[]) => T;


export interface HeaderValue {
	Name: string,
	Value: string
}


export interface RequestParameters {
	Headers?: HeaderValue[],
	Url: string,
	Query?: URLSearchParams | undefined,
	Body?: any,
}


export enum RequestMethod {
	POST,
	GET
}



export abstract class BaseHttpEngine {
	protected log: BaseLogger;

	protected abstract executeRequest(requestMethod: RequestMethod, rp: RequestParameters): Promise<any>;
	protected abstract processException(ex: Error | AxiosError): Promise<void>;



	protected constructor() {
		this.log = new KzLogger("BaseHttpEngine");
	}



	protected deserializeResponse<T>(type: DTOResponse<T>, data: any): T {
		try {
			const obj: T = plainToClass(type, data);

			return obj;
		}
		catch {
			const modelName: string = type.name;

			throw new WebException(WebExceptionErrors.INVALID_MODEL_RESPONSE, `[${modelName}] \nCannot deserialized response: [${data}]\n`);
		}
	}



	protected deserializeResponseAsArray<T>(type: DTOResponse<T>, data: any[]): T[] {
		try {
			const obj = data.map(x => plainToClass(type, x));

			return obj;
		}
		catch {
			const modelName: string = type.name;

			throw new WebException(WebExceptionErrors.INVALID_MODEL_RESPONSE, `[${modelName}] \r\nCannot deserialized response: [${data}]\n`);
		}
	}



	// eslint-disable-next-line @typescript-eslint/require-await
	protected async getAuthToken(): Promise<string> {
		return "";
	}



	protected async prepareHeaders(): Promise<HeaderValue[]> {
		const headers = new Array<HeaderValue>();

		// Aggiungo il token Bearer per l'autenticazione base
		const token = await this.getAuthToken();
		headers.push({ Name: 'Authorization', Value : `Bearer ${token}`} );

		return headers;
	}



	/**
	 * Template di esecuzione richiesta (preparazione header - lancio della richiesta - deserializzazione della risposta).
	 * NB: non metto in un blocco try catch volutamente, in quanto voglio che se c'è un exception, venga gestita dai layer sopra.
	 * @param requestMethod metodo GET / POST
	 * @param type model della response che verrà deserializzato
	 * @param rp  model della request
	 * @returns {Promise<T>} model deserializzato, se non ci sono state exception
	 */
	protected async launchRequestAndDeserialize<T>(requestMethod: RequestMethod, type: DTOResponse<T>, rp: RequestParameters): Promise<T> {
		// Preparo gli headers per la request
		rp.Headers = await this.prepareHeaders();

		// Eseguo la richiesta
		const response = await this.executeRequest(requestMethod, rp);

		// La response va poi deserializzata
		const obj = this.deserializeResponse<T>(type, response);

		return obj;
	}



	protected async launchRequestAndDeserializeAsArray<T>(requestMethod: RequestMethod, type: DTOResponse<T>, rp: RequestParameters): Promise<T[]> {
		// Preparo gli headers per la request
		rp.Headers = await this.prepareHeaders();

		// Eseguo la richiesta
		const response = await this.executeRequest(requestMethod, rp);

		// La response va poi deserializzata
		const obj = this.deserializeResponseAsArray<T>(type, response);

		return obj;
	}



	protected async get<T>(type: DTOResponse<T>, url: string, getParams?: any): Promise<T> {
		const rp: RequestParameters = {
			Url: url,
			Query : getParams
		}

		const obj = await this.launchRequestAndDeserialize<T>(RequestMethod.GET,type, rp);

		return obj;
	}



	protected async post<T>(type: DTOResponse<T>, url: string, queryParams?: any, bodyParams?: any): Promise<T> {
		const rp: RequestParameters = {
			Url: url,
			Query: queryParams,
			Body : bodyParams
		}

		const response = await this.launchRequestAndDeserialize<T>(RequestMethod.POST, type, rp);

		return response;
	}



	protected async getAsArray<T>(type: DTOResponse<T>, url: string, getParams?: any): Promise<T[]> {
		const rp: RequestParameters = {
			Url: url,
			Query : getParams
		}

		const obj = await this.launchRequestAndDeserializeAsArray<T>(RequestMethod.GET,type, rp);

		return obj;
	}



	protected async postAsArray<T>(type: DTOResponse<T>, url: string, queryParams?: any, bodyParams?: any): Promise<T[]> {
		const rp: RequestParameters = {
			Url: url,
			Query: queryParams,
			Body : bodyParams
		}

		const response = await this.launchRequestAndDeserializeAsArray<T>(RequestMethod.POST, type, rp);

		return response;
	}
}
