import { AxiosHttpEngine } from '@libs/KzHttpEngine/AxiosHttpEngine';
import { AuthRepository } from '@core/repositories/AuthRepository';
import { ResponseFail } from '@dto/Responses/ResponseFail';
import { plainToClass } from '@deepkit/type';
import { ApiException } from '@libs/KzHttpEngine/Exceptions/ApiException';
import { AxiosError, AxiosResponse } from 'axios';
import { DTOResponse } from '@libs/KzHttpEngine/BaseHttpEngine';
import { GShopRequest } from '@dto/Requests/GShopRequest';
import { ObjectUtility } from '@libs/utility/ObjectUtility';
import { HttpEngineUtility } from '@libs/KzHttpEngine/HttpEngineUtility';
import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';



export class GShopHttpEngine extends AxiosHttpEngine {
	protected override async getAuthToken(): Promise<string> {
		const token = await AuthRepository.getToken();

		return token;
	}



	protected override async processException(ex: Error | AxiosError): Promise<void> {
		const error: AxiosError = ex as AxiosError;
		const errorResponse: AxiosResponse | undefined = error.response;


		// Se è un errore di connessione, lo processo come una WebException
		if (error.isAxiosError) {
			switch (errorResponse?.status) {
				case 401:
					await this.onUnauthorizedResponse();
			}
			throw new WebException(errorResponse?.status ?? WebExceptionErrors.UNKNOWN_ERROR, `[${errorResponse?.status} - ${errorResponse?.statusText}] ${errorResponse?.data}`);
		}

		// Errore proveniente da GShop (ho un json che contiene il campo Code )
		if (errorResponse && !error.isAxiosError) {
			const resFail: ResponseFail = plainToClass(ResponseFail, errorResponse.data as object);

			throw new ApiException(resFail.code, resFail.error, undefined, resFail.stacktrace);
		}

		// Richiamo comunque l'handler base dell'exception se non ho gestito l'exception
		await super.processException(ex);
	}



	protected async onUnauthorizedResponse(): Promise<void> {
		// Se ho ricevuto un 401 perchè è scaduto il token o non sono autorizzato, resetto i token
		await AuthRepository.clearToken();
	}



	protected mapRequestModelToUrlParams(req?: GShopRequest): URLSearchParams {
		// Converto la Request in un KevValueRecord[]
		const kvParams = ObjectUtility.toKeyValue(req);

		// Una volta standardizzata la request in KVR [{ key : name, value : value }, { key : name2, value : value2 }, { key : objproperty3, value : objpropertyvalue3 }, ...]
		// La converto in un UrlSearchParam
		// Alternativa: potrei mandarla anche come qs.stringify() ma preferisco UrlSearchParam, più versatile
		const urlsp: URLSearchParams = HttpEngineUtility.fromKVToSearchParams(kvParams);

		//const urlsp: URLSearchParams = HttpEngineUtility.fromObjToSearchParams(req);          // Potrei usare anche l'object diretto

		return urlsp;
	}



	protected mapRequestModelToJSON(req?: GShopRequest): unknown {
		const jsonModel = req?.toJson();

		return jsonModel;
	}



	public override async get<T>(type: DTOResponse<T>, url: string, getParams?: GShopRequest): Promise<T> {
		const urlsp = this.mapRequestModelToUrlParams(getParams);

		return super.get(type, url, urlsp);
	}



	public override async post<T>(type: DTOResponse<T>, url: string, queryParams?: any, bodyParams?: GShopRequest): Promise<T> {
		const urlsp = this.mapRequestModelToUrlParams(bodyParams);

		return super.post(type, url, queryParams, urlsp);
	}



	public async postJson<T>(type: DTOResponse<T>, url: string, queryParams?: any, postParams?: GShopRequest): Promise<T> {
		const jsonModel = this.mapRequestModelToJSON(postParams);

		return super.post(type, url, queryParams, jsonModel);
	}



	public override async getAsArray<T>(type: DTOResponse<T>, url: string, getParams?: GShopRequest): Promise<T[]> {
		const urlsp = this.mapRequestModelToUrlParams(getParams);

		return super.getAsArray(type, url, urlsp);
	}



	public override async postAsArray<T>(type: DTOResponse<T>, url: string, queryParams?: any, postParams?: GShopRequest): Promise<T[]> {
		const urlsp = this.mapRequestModelToUrlParams(postParams);

		return super.postAsArray(type, url, queryParams, urlsp);
	}
}