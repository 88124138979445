import { useCallback, useMemo } from 'react';

import { AuthService } from '@services/AuthService';

import { useMountAsync } from '@libs/KzHooks/useMountAsync';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { useLog } from '@hooks/useLog';

import { authAtom, AuthContextState, AuthenticationState } from '@atoms/AuthAtom';
import { useAtom } from 'jotai';



export const useAuthentication = () => {
	const log = useLog();
	const authService = useSingleton<AuthService>(() => new AuthService(), []);
	const [ authState, setAuthState ] = useAtom(authAtom);


	// Caricamento del token JWT se esiste e se non l'ho ancora caricato (per evitare di caricarlo ad ogni uso dell'hook
	useMountAsync(async () => {
		// Caricamento JWT se non è ancora presente un utente loggato
		log.info("Loading JWT...");

		// TODO: Verificare se serve o se si può levare senza sideeffect
		if (authState.user != undefined) {
			return;
		}

		// Jotai: 1.3.7 => 2021-10-08
		// La function è async, quindi posso fare l'await qui e richiamare una volta setAuthState senza aver conflitti.
		const loggedUser = await authService.getLoggedUser();

		// Richiamo setAuthState SENZA async, così evito questi problemi/exception
		setAuthState((draft: AuthContextState) => {
			// Problemi di race-condition o re-renderting nella dev mode
			//draft.user = await authService.getLoggedUser();   // Non posso usare un setAuthState in modo async, altrimenti genera un exception: "Reading pending atom state in write operation. We throw a promise for now.".
			draft.user = loggedUser;
			draft.status = (draft.user === undefined) ? AuthenticationState.UNAUTHORIZED : AuthenticationState.LOGGED;
		});
	});



	const login = useCallback(async (username: string, password: string): Promise<boolean> => {
		const bLoggedIn = await authService.login(username, password);

		if (bLoggedIn) {
			const loggedUser = await authService.getLoggedUser();

			setAuthState((draft: AuthContextState) => {
				draft.user = loggedUser;
				draft.status = (draft.user === undefined) ? AuthenticationState.UNAUTHORIZED : AuthenticationState.LOGGED;
			})
		}

		return bLoggedIn;
	}, [authService, setAuthState]);



	const logout = useCallback(async (): Promise<boolean> => {
		await authService.logout();

		setAuthState((draft: AuthContextState) => {
			draft.user = undefined;
			draft.status = AuthenticationState.UNAUTHORIZED;
		})

		return true;
	}, [authService, setAuthState]);



	return useMemo(() => ({
		login,
		logout,
		status: authState.status,
		userInfo: authState.user
	}), [
		login,
		logout,
		authState,
	]);
};
