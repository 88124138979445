import { GetSalesByArticleResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesByArticleResponse';

import { DataFormatters } from '@guiutility/DataFormatters';

import { useLog } from '@hooks/useLog';

import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import React, { ReactNode } from 'react';



type SalesChartProps = {
	salesInfo: GetSalesByArticleResponse[],
	isLoading: boolean,
	children?: ReactNode
}



const quantitaBodyTemplate = (rowData: GetSalesByArticleResponse) => {
	return DataFormatters.formatQuantity(rowData.quantita_venduto);
};



const dataBodyTemplate = (rowData: GetSalesByArticleResponse) => {
	return DataFormatters.formatDate(rowData.ultima_vendita);
};



const importoBodyTemplate = (rowData: GetSalesByArticleResponse) => {
	return DataFormatters.formatPrice(rowData.importo_venduto);
};




export const CardTableSalesByArticle: React.FC<SalesChartProps> = ({ salesInfo, isLoading }: SalesChartProps) => {
	const log = useLog();


	const formatFooterTemplate = () => {
		const totalSales: number = salesInfo.reduce((total, current) => total + current.importo_venduto, 0);

		return (
			<>
				<span className="font-bold">
					Totale acquistato: {DataFormatters.formatPrice(totalSales)}
				</span>
			</>
		)
	}


	const dtParameters: DataTableProps<GetSalesByArticleResponse[]> = {
		scrollable: true,
		scrollHeight: "500px",
		rowHover: true,
		stripedRows: true,
		paginator: false,

		lazy: false,
		loading: isLoading,
		value: salesInfo,

		columnResizeMode: "fit",
		emptyMessage: "Nessun articolo venduta",

		footer: formatFooterTemplate(),
		dataKey: "articolo",
	}


	return (
		<>
			<DataTable className="tablecategory p-datatable-sm" {...dtParameters}>
				<Column field="articolo" header="Cod.Art." style={{width: '10rem'}} sortable />
				<Column field="descrizione" header="Descrizione" sortable />

				<Column field="quantita_venduto" header="Quantita" style={{width: '10rem'}} body={quantitaBodyTemplate} sortable />
				<Column field="ultima_vendita" header="Ultima vendita" style={{width: '10rem'}} body={dataBodyTemplate} sortable />
				<Column field="importo_venduto" header="Importo" style={{width: '10rem'}} body={importoBodyTemplate} sortable />
			</DataTable>
		</>
	);
};
