import { useMemo, useState } from 'react';


type IState = string | number | boolean | undefined;


export interface Actions<T = IState> {
	setLeft: () => void;
	setRight: () => void;
	toggle: (value?: T) => void;
}


function useToggleWithRevert<T = boolean | undefined>(): [boolean, Actions<T>];



function useToggleWithRevert<T = IState>(defaultValue: T): [T, Actions<T>];



function useToggleWithRevert<T = IState, U = IState>(
	defaultValue: T,
	reverseValue: U,
): [T | U, Actions<T | U>];



/**
	A hook that switch value between two states.
 * */
function useToggleWithRevert<D extends IState = IState, R extends IState = IState>(
	defaultValue: D = false as D,
	reverseValue?: R,
) {
	const [state, setState] = useState<D | R>(defaultValue);

	const actions = useMemo(() => {
		const reverseValueOrigin = (reverseValue === undefined ? !defaultValue : reverseValue) as D | R;

		const toggle = (value?: D | R) => {
			if (value !== undefined) {
				setState(value);
				return;
			}
			setState((s) => (s === defaultValue ? reverseValueOrigin : defaultValue));
		};

		const setLeft = () => setState(defaultValue);

		const setRight = () => setState(reverseValueOrigin);
		return {
			toggle,
			setLeft,
			setRight,
		};
	}, [defaultValue, reverseValue]);

	return [state, actions];
}


export default useToggleWithRevert;





/*
const toggleReducer = (state: boolean, nextValue?: any) =>
	typeof nextValue === 'boolean' ? nextValue : !state;

const useToggle = (initialValue: boolean): [boolean, (nextValue?: any) => void] => {
	return useReducer<Reducer<boolean, any>>(toggleReducer, initialValue);
};

export default useToggle;
*/






/**
 * useToggle (switch from true to false with toggle())
 */
/*
export const useToggle = (initialState = false): [boolean, any] => {
	const [state, setState] = useState<boolean>(initialState);

	// Define and memorize toggler function in case we pass down the comopnent, this function change the boolean value to it's opposite value
	const toggle = useCallback((): void => setState(state => !state), []);

	return [state, toggle]
}
*/