import React, { useRef } from 'react';

import { SplitButton } from 'primereact/splitbutton';
import { useAuthentication } from '@hooks/useAuthentication';
import { ToastSeverityEnum, useToast } from '@hooks/useToast';


export const UserMenu: React.FC = () => {
	const toolbarRef = useRef(null);
	const menuRef = useRef(null);

	const { userInfo, logout } = useAuthentication();
	const { showToast } = useToast();

	const items = [
		{
			label: 'Esci',
			icon: 'pi pi-sign-out',
			command: async () => {
				await logout();

				showToast(ToastSeverityEnum.SUCCESS, "Logout", "Sei stato disconnesso!");
			}
		}
	];

	if (userInfo === undefined)
		return <></>;

	return (
		<ul ref={toolbarRef} className="topbar-menu p-unselectable-text" role="menubar">
			<li>
				{/*<Avatar icon="pi pi-user" className="mr-2" size="xlarge" />*/}

				<SplitButton label={userInfo.Name} icon="pi pi-user" model={items} />
			</li>

			{/*<li role="none" className="topbar-submenu">*/}
			{/*	<button type="button" role="menuitem" className="p-link">Resources</button>*/}
			{/*	<CSSTransition nodeRef={menuRef} classNames="p-connected-overlay" timeout={{ enter: 120, exit: 100 }} unmountOnExit>*/}
			{/*		<ul ref={menuRef} role="menu" aria-label="Resources">*/}
			{/*			<li role="none"><Link to="/support" role="menuitem"><span>Support</span></Link></li>*/}
			{/*			<li role="none"><a href="https://forum.primefaces.org/viewforum.php?f=57" role="menuitem" target="_blank" rel="noopener noreferrer"><span>Forum</span></a></li>*/}
			{/*			<li role="none"><a href="https://discord.gg/gzKFYnpmCY" role="menuitem" target="_blank" rel="noopener noreferrer"><span>Discord Chat</span></a></li>*/}
			{/*			<li role="none"><a href="https://github.com/primefaces/primereact" role="menuitem" target="_blank" rel="noopener noreferrer"><span>Source Code</span></a></li>*/}
			{/*		</ul>*/}
			{/*	</CSSTransition>*/}
			{/*</li>*/}
		</ul>
	);
}