import { toastAtom } from '@atoms/ToastAtom';
import { initApp } from '@core/startup';
import { ErrorHandler, GlobalErrorBoundaryFallback } from '@guiutility/ErrorHandlers/GlobalErrorBoundaryFallback';
import { GlobalErrorsHandler } from '@guiutility/ErrorHandlers/GlobalErrorsHandler';

import { LayoutDesktopRoutes } from '@layouts/desktop/pages/LayoutDesktopRoutes';
import { useMount } from '@libs/KzHooks/useMount';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { useAtom } from 'jotai';
import { PrimeReactProvider } from 'primereact/api';

import { Toast } from 'primereact/toast';

import React, { Suspense, useRef } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import '@desktop/styles/global/App.scss';



const wrapperClassName = classNames('layout-wrapper', 'layout-menu-light', {
	'p-input-filled'   : 'filled',
	'p-ripple-disabled': false,
});



// Initialize app
initApp();



const App: React.FC = () => {
	const [ toastRef, setToastState ] = useAtom(toastAtom);        // Creo un atom per il toast e associo il suo ref. In questo modo tramite l'hook useToast, accedo da qualsiasi pagina

	const toast = useRef<Toast>(null);

	useMount(() => {
		setToastState(toast.current);

		document.title = 'GShop v2 ' + dayjs(__BUILD_DATE__).format('YYYY-MM-DD @ HH.mm') + ' by Claudio - Gigashop';
	});


	return (
		<PrimeReactProvider>
			<div className={ wrapperClassName }>
				<GlobalErrorsHandler>
					<ErrorBoundary FallbackComponent={ GlobalErrorBoundaryFallback } onError={ ErrorHandler } onReset={ () => { /* reset the state of your app so the error doesn't happen again */
					} }>
						<Suspense fallback="Loading...">
							{/*<Router basename="/gshop"> se lo metto in una subdirectory e non in un virtualhost con DocumentRoot a /. Adeguare anche base in vite.config a tipo /gshop */}
							<Router basename="/">
								<Toast ref={ toast } />

								<LayoutDesktopRoutes />
							</Router>
						</Suspense>
					</ErrorBoundary>
				</GlobalErrorsHandler>
			</div>
		</PrimeReactProvider>
	);
};


export default App;
