import { t } from '@deepkit/type';


export abstract class GShopPagedResponse<T> {
	public abstract data: T[];

	@t public currentpage!: number;
	@t public pagesize!: number;
	@t public totalpages!: number;
	@t public totalrecords!: number;
}

