import React from 'react';

import { useLog } from '@hooks/useLog';
import { useParams } from 'react-router-dom';
import { useMount } from '@libs/KzHooks/useMount';

import { OrderDetailsRequest, useOrderDetails } from '@hooks/orders/useOrderDetails';

import { CardCustomer } from '@layouts/desktop/pages/private/orders/OrderDetails/CardCustomer';
import { CardRecipient } from '@layouts/desktop/pages/private/orders/OrderDetails/CardRecipient';

import { CardOrderRows } from '@layouts/desktop/pages/private/orders/OrderDetails/CardOrderRows';
import { CardNotes } from '@layouts/desktop/pages/private/orders/OrderDetails/CardNotes';

import './OrderDetail.scss';
import { CardTotals } from '@layouts/desktop/pages/private/orders/OrderDetails/CardTotals';



interface OrderDetailRouteParam {
	orderCode: string,
	orderNumber: string,
}



const OrderDetail: React.FC = () => {
	const log = useLog();

	const { orderCode, orderNumber } = useParams<OrderDetailRouteParam>();
	const orderType: OrderDetailsRequest = {
		OrderCode: orderCode,
		OrderNumber: orderNumber,
	};

	const { isLoading, orderDetails } = useOrderDetails(orderType);


	useMount(() => {
		log.clear();
	});



	return (
		<>
			<div className="content-section introduction">
				<h1>Dettaglio ordine: {orderDetails?.ordineid}</h1>
			</div>

			<div className="content-section implementation">
				<div className="grid mb-3">
					<div className="col-6">
						<CardCustomer customer={orderDetails?.customer} />
					</div>

					<div className="col-6">
						<CardRecipient recipient={orderDetails?.recipient}/>
					</div>
				</div>

				<div className="card">
					<CardOrderRows orderRows={orderDetails?.righe_documento} />
				</div>

				<div className="card">
					<CardTotals order={orderDetails} />
				</div>

				<div className="card">
					<CardNotes order={orderDetails} />
				</div>
			</div>
		</>
	);
};


export { OrderDetail };
