import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { PrivateLayoutContent } from '@layouts/desktop/pages/private/PrivateLayoutContent';
import { LoginRedirect } from '@desktop/components/router/LoginRedirect';


type PrivateRouteProps = {
	isAuth: boolean,
	component: ReactNode,
	path: string,
	exact?: boolean,
}



export const PrivateRoute: React.FC<PrivateRouteProps> = ({isAuth, component, path, exact}: PrivateRouteProps) => {
	if (!isAuth)
		return (<LoginRedirect />);

	return (
		<Route path={path} exact={exact}>
			<PrivateLayoutContent>
				{ component }
			</PrivateLayoutContent>
		</Route>
	)
};
