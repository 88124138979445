import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';
import { GShopPagedResponse } from '@dto/Responses/GShopPagedResponse';



export class GetArticleResponse extends GShopResponse {
	@t public articolo!: string;
	@t public descrizione!: string;
	@t public descrizione_aggiuntiva!: string;
	@t public unita_di_misura!: string;
	@t public codice_iva!: string;
	@t public articolo_di_magazzino!: string;
	@t public categoria!: string;
	@t public descrizione_categoria!: string;
	@t public sottocategoria!: string;
	@t public descrizione_sottocategoria!: string;
	@t public sottogruppo!: string;
	@t public descrizione_sottogruppo!: string;
	@t public pezzi_per_confezione!: string;
	@t public unita_di_misura_coefficente!: string;
	@t public coefficente_moltiplicativo!: number;
	@t public peso_articolo!: number;
	@t public peso_lordo!: number;
	@t public data_aggiornamento_listini!: Date;
	@t public non_scontabile!: string;
	@t public sconto_massimo!: string;
	@t public status!: string;
	@t public listino_1!: number;
	@t public sconti_listino_1!: string;
	@t public listino_2!: number;
	@t public sconti_listino_2!: string;
	@t public listino_3!: number;
	@t public sconti_listino_3!: string;
	@t public listino_4!: number;
	@t public sconti_listino_4!: string;
	@t public listino_5!: number;
	@t public sconti_listino_5!: string;
	@t public caricato_tot!: number;
	@t public scaricato_tot!: number;
	@t public esistente_tot!: number;
	@t public ordinato_clienti_tot!: number;
	@t public disponibile_tot!: number;
	@t public impegnato_tot!: number;
	@t public ordinato_fornitori_tot!: number;
	@t public ean8!: string;
	@t public ean13!: string;
	@t public photoscount!: number;
	@t public data_creazione!: Date;
	@t public data_ultimo_acquisto!: Date;
	@t public data_ultima_vendita!: Date;
}



export class GetArticlesPagedResponse extends GShopPagedResponse<GetArticleResponse> {
	@t.array(GetArticleResponse) public data: GetArticleResponse[] = [];
}
