import { ArticleRequest, defaultArticleRequest, useArticlesList } from '@hooks/articles/useArticlesList';
import { useLog } from '@hooks/useLog';

import { TableProducts } from '@layouts/desktop/pages/private/products/ProductsList/TableProducts';
import { useMount } from '@libs/KzHooks/useMount';
import React, { useState } from 'react';
import { useImmer } from 'use-immer';



export const ProductsList: React.FC = () => {
	const log = useLog();

	const [ firstRowDisplayed, setFirst ] = useState<number>(0);
	const [ reqParams, setRequest ] = useImmer<ArticleRequest>(defaultArticleRequest);

	const { isLoading, products, totalRecords } = useArticlesList(reqParams);


	useMount(() => {
		log.clear();
	});



	const onPaginator = (currentPage: number, rowsPerPage: number): void => {
		setRequest((draft) => {
			draft.PageNumber = currentPage +1;
			draft.PageSize = rowsPerPage;
		});
	};



	return (
		<div className="card">
			<h5>Prodotti a catalogo</h5>
			<TableProducts products={products} pageSize={reqParams.PageSize} isLoading={isLoading} totalRecords={totalRecords} onPageChangedHandler={onPaginator} />
		</div>
	);
};
