import { t } from '@deepkit/type';
import { GShopResponse } from '@dto/Responses/GShopResponse';



export class GetArticleImagesResponse extends GShopResponse {
	@t public url_image!: string;
	@t public url_thumbnail!: string;
	@t public description!: string;
	@t public title!: string;
}
