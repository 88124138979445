import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { OrdersService } from '@services/OrdersService';
import { CustomersService } from '@services/CustomersService';
import { Order } from '@models/Order';
import { RecipientsService } from '@services/RecipientsService';
import { Customer } from '@models/Customer';
import { Recipient } from '@models/Recipient';


export type OrderDetailsRequest = {
	OrderCode: string,
	OrderNumber: string,
}


export const defaultOrderDetailsRequest: OrderDetailsRequest = {
	OrderCode: "",
	OrderNumber: ""
}



/**
 * useOrderDetails(): Visualizza il dettaglio di un ordine (completo di info cliente e destinatario) Scelta di design:
 * Per avere le informazioni sull'ordine avevo varie possibilità
 * - 1. endpoint /order/OC/2 che restituiva già in modo autonomo Dati dell'ordine, dati del cliente, dati del destinatario.
 *  Quali dati del destinatario doveva restituire? Tre-quattro? E del destinatario? Altri due-tre campi? Se me ne servono di più? Devono modificare il backend e adeguare i model.
 *
 * Ho optato per far restituire il semplice ordine, poi sarà il client che andrà ad interrogare il backend nuovamente facendo una query sull'endpoint customer e su quello recipient.
 * In questo modo, i model restituiti sono belli completi, pure troppo, ma in caso di modifica sul frontend la modifica è molto rapida, in quanto i model sono già belli completi.
 * Skippo l'aspetto security dove magari si riescono ad esfiltrare troppi dati. Parliamo di agenti all'interno dell'azienda che devono recuperare dati. ATTUALMENTE, poi potrebbe variare, non lo considero un problema
 *
 * Lato frontend come gestisco la cosa? Non voglio andar a toccare l'OrderService facendo dependency injection di altri service (CustomerService e RecipientService), altrimenti ci son troppi sideeffect.
 * Allora gestisco la cosa sugli hooks. Nell'hooks useOrderDetails, utilizzo i service che mi interessano (OrderService, CustomerService, RecipientService) ed eseguo le query come chained, dependendent, ovvero
 * Query Order => Query Customer => Query Recipient.
 * @param odr
 * @return {{isLoading: boolean, orderDetails: Order | null}}
 */
export const useOrderDetails = (odr: OrderDetailsRequest) => {
	const log = useLog();

	const ordersService = useSingleton<OrdersService>(() => new OrdersService(), []);
	const customerService = useSingleton<CustomersService>(() => new CustomersService(), []);
	const recipientService = useSingleton<RecipientsService>(() => new RecipientsService(), []);

	// Query per info generiche dell'ordine
	const queryOrder = useQuery<Order>({
		queryKey: ['get-orders-detail', odr], 
		queryFn: async () => ordersService.getOrderDetails(odr.OrderCode, odr.OrderNumber) 
	});

	const orderInfo: Order | undefined = queryOrder.data;

	// Query per info sul cliente dell'ordine
	const cdr: number = orderInfo?.codice ?? 0;
	const queryCustomer = useQuery<Customer | undefined>({
		queryKey: ['get-customer-details', cdr], 
		queryFn: async () => customerService.getCustomerInfo(cdr), enabled: !!orderInfo?.codice
	});



	// Query per info sul destinatario dell'ordine
	const rdr: number = orderInfo?.destinatario ?? 0;
	const queryRecipient = useQuery<Recipient | undefined>({
		queryKey: ['get-recipient-details', rdr],
		queryFn: async () => recipientService.getRecipientInfo(rdr), enabled: !!orderInfo?.codice
	})

	// Man mano che arrivano le info varie, compongo l'Order
	if (orderInfo) {
		orderInfo.customer = queryCustomer.data;
		orderInfo.recipient = queryRecipient.data;
	}

	const isLoading = queryOrder.isLoading || queryCustomer.isLoading || queryRecipient.isLoading;

	return {
		isLoading,
		orderDetails: orderInfo ?? undefined,
	}
};
