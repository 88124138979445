import { GShopRequest } from '@dto/Requests/GShopRequest';
import { t, classToPlain } from '@deepkit/type';



export class LoginRequest implements GShopRequest {
    @t  public Username!: string;
    @t  public Password!: string;



    public constructor(username: string, password: string) {
        this.Username = username;
        this.Password = password;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(LoginRequest, this);

        return js;
    }

}
