import { VOCGroupedType } from '@atoms/SettingsAtom';
import { GetVOCStatsResponse } from '@dto/Responses/Statistics/GetVOCStatsResponse';

import { DataFormatters } from '@guiutility/DataFormatters';

import { useLog } from '@hooks/useLog';
import { useSettings } from '@hooks/useSettings';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps, DataTableRowToggleEvent } from 'primereact/datatable';
import React, { ReactNode } from 'react';
import { ImStatsBars } from 'react-icons/im';
import { useImmer } from 'use-immer';



type VocTableProps = {
	vocInfo: GetVOCStatsResponse[],
	isLoading: boolean,
	children?: ReactNode
}



const DEFAULT_UM = "KG";



const getStatsKey = (vgt: VOCGroupedType): keyof GetVOCStatsResponse => {
	switch (vgt) {
		case VOCGroupedType.CATEGORY:
			return "categoria" as keyof GetVOCStatsResponse

		case VOCGroupedType.SUBCATEGORY:
			return "sottocategoria" as keyof GetVOCStatsResponse

		case VOCGroupedType.LINE_PRODUCTION:
			return "linea_produzione" as keyof GetVOCStatsResponse
	}
}



const getStatsDescription = (vgt: VOCGroupedType): keyof GetVOCStatsResponse => {
	switch (vgt) {
		case VOCGroupedType.CATEGORY:
			return "descrizione_categoria"

		case VOCGroupedType.SUBCATEGORY:
			return "descrizione_sottocategoria"

		case VOCGroupedType.LINE_PRODUCTION:
			return "descrizione_lineaproduzione"

		default:
			return "descrizione_sottocategoria"
	}
}


//#region Formattaers (quantity, percent, ecc)

const totalConsumedBodyTemplate = (rowData: GetVOCStatsResponse) => {
	return `${DataFormatters.formatQuantity(rowData.peso_totale_acquistato, 2)} ${DEFAULT_UM}`;
};



const vocPercentBodyTemplate = (rowData: GetVOCStatsResponse) => {
	return `${DataFormatters.formatPercent(rowData.percentuale_voc)}`;
};



const totalVocConsumedBodyTemplate = (rowData: GetVOCStatsResponse) => {
	return `${DataFormatters.formatPercent(rowData.peso_voc_consumato)}`;
};



const residuoSeccoPercentBodyTemplate = (rowData: GetVOCStatsResponse) => {
	return `${DataFormatters.formatPercent(rowData.percentuale_residuosecco)}`;
};



const totalResiduoSeccoConsumedBodyTemplate = (rowData: GetVOCStatsResponse) => {
	return `${DataFormatters.formatPercent(rowData.peso_residuosecco_consumato)}`;
};
//#endregion



export const CardVOCTable: React.FC<VocTableProps> = ({ vocInfo, isLoading }: VocTableProps) => {
	const log = useLog();

	const [ expandedRows, setExpandedRows ] = useImmer<GetVOCStatsResponse[]>(new Array<GetVOCStatsResponse>());
	const { settingsState } = useSettings();

	const groupKey = getStatsKey(settingsState.vocGroupedBy);
	const groupDescription = getStatsDescription(settingsState.vocGroupedBy);



	const onRowToggle = (e: DataTableRowToggleEvent) => {
		setExpandedRows(e.data as GetVOCStatsResponse[]);
	};



	const formatRowGroupHeaderTemplate = (rowData: GetVOCStatsResponse) => {
		const totalBuy = vocInfo.filter(x => x[groupKey] == rowData[groupKey])
			.reduce((partial_sum, x) => partial_sum + x.peso_totale_acquistato, 0);

		const totalVoc = vocInfo.filter(x => x[groupKey] == rowData[groupKey])
			.reduce((partial_sum, x) => partial_sum + x.peso_voc_consumato, 0);

		const totalRs = vocInfo.filter(x => x[groupKey] == rowData[groupKey])
			.reduce((partial_sum, x) => partial_sum + x.peso_residuosecco_consumato, 0);

		return (
			<>
				<span className="font-bold">
					<ImStatsBars /> [{rowData[groupKey] || "-----"}] <span className="capitalize">{rowData[groupDescription]?.toString().toLowerCase() || "Nessuna descrizione"}</span>
				</span>

				<div className="box-voc">
					<span className="columns-quantity">Totale acquistato:&nbsp;
						<span className="font-bold">
							{`${DataFormatters.formatQuantity(totalBuy, 2)} ${DEFAULT_UM}`}
						</span>
					</span>

					{/* Totale Residuo secco */}
					<span className="columns-quantity">Totale Residuo secco:&nbsp;
						<span className="font-bold">
							{`${DataFormatters.formatQuantity(totalRs, 2)} ${DEFAULT_UM}`}
						</span>
					</span>

					{/* Totale VOC */}
					<span className="columns-quantity">Totale VOC:&nbsp;
						<span className="font-bold">
							{`${DataFormatters.formatQuantity(totalVoc, 2)} ${DEFAULT_UM}`}
						</span>
					</span>
				</div>

				{/*<span style={{ float: 'right', textAlign: 'right', marginRight: '2em' }}>Totale acquistato:&nbsp;*/}
				{/*	<span className="font-bold" style={{ marginLeft: '1em', marginRight: '4em' }}>*/}
				{/*		{`${DataFormatters.formatQuantity(totalBuy, 2)} ${DEFAULT_UM}`}*/}
				{/*	</span>*/}

				{/*	/!* Totale Residuo secco *!/*/}
				{/*	<span className="columns-quantity" style={{ marginRight: '4em' }}>Totale Residuo secco:&nbsp;*/}
				{/*		<span className="font-bold">*/}
				{/*			{`${DataFormatters.formatQuantity(totalRs, 2)} ${DEFAULT_UM}`}*/}
				{/*		</span>*/}
				{/*	</span>*/}

				{/*	/!* Totale VOC *!/*/}
				{/*	<span className="columns-quantity" style={{ marginRight: '2em' }}>Totale VOC:&nbsp;*/}
				{/*		<span className="font-bold">*/}
				{/*			{`${DataFormatters.formatQuantity(totalVoc, 2)} ${DEFAULT_UM}`}*/}
				{/*		</span>*/}
				{/*	</span>*/}
				{/*</span>*/}
			</>
			// <span className="font-bold">
			// 	<ImStatsBars /> [{rowData[groupKey] || "-----"}] <span className="capitalize">{rowData[groupDescription]?.toString().toLowerCase() || "Nessuna descrizione"}</span>
			// </span>
		)
	}



	/**
	 * Creao il footer con i totali degli articoli con VOC per ogni raggruppamento (o categoria o sottocategoria).
	 * Dismesso e spostato sull'header
	 * @param rowData
	 * @returns {JSX.Element}
	 */
	const formatRowGroupFooterTemplate = (rowData: GetVOCStatsResponse) => {
		return (
			<>

			</>
		);
		/*const totalBuy = vocInfo.filter(x => x[groupKey] == rowData[groupKey])
										.reduce((partial_sum, x) => partial_sum + x.peso_totale_acquistato, 0);

		const totalVoc = vocInfo.filter(x => x[groupKey] == rowData[groupKey])
			.reduce((partial_sum, x) => partial_sum + x.peso_voc_consumato, 0);

		const totalRs = vocInfo.filter(x => x[groupKey] == rowData[groupKey])
			.reduce((partial_sum, x) => partial_sum + x.peso_residuosecco_consumato, 0);

		return (
			<>
				<td colSpan={2} style={{ textAlign: 'right' }}>Totale acquistato</td>
				<td>
					<span className="font-bold">
						{`${DataFormatters.formatQuantity(totalBuy, 2)} ${DEFAULT_UM}`}
					</span>
				</td>

				{ // Totale Residuo secco }
				<td>&nbsp;</td>
				<td className="columns-quantity">
					<span className="font-bold">
						{`${DataFormatters.formatQuantity(totalRs, 2)} ${DEFAULT_UM}`}
					</span>
				</td>

				{ /* Totale VOC }
				<td>&nbsp;</td>
				<td className="columns-quantity">
					<span className="font-bold">
						{`${DataFormatters.formatQuantity(totalVoc, 2)} ${DEFAULT_UM}`}
					</span>
				</td>
			</>
		);*/
	}



	const formatFooterTableTemplate = (vocInfo: GetVOCStatsResponse[]) => {
		const totalBuy = vocInfo.reduce((partial_sum, x) => partial_sum + x.peso_totale_acquistato, 0);
		const totalRs = vocInfo.reduce((partial_sum, x) => partial_sum + x.peso_residuosecco_consumato, 0);
		const totalVoc = vocInfo.reduce((partial_sum, x) => partial_sum + x.peso_voc_consumato, 0);

		return (
			<>
				<br />
				<span className="font-bold">Totale acquistato:&nbsp;
					<span className="font-bold" style={{ marginLeft: '1em', marginRight: '4em' }}>
							{`${DataFormatters.formatQuantity(totalBuy, 2)} ${DEFAULT_UM}`}
					</span>

					{/* Totale Residuo secco */}
					<span className="columns-quantity" style={{ marginRight: '4em' }}>Totale Residuo secco:&nbsp;
						<span className="font-bold">
								{`${DataFormatters.formatQuantity(totalRs, 2)} ${DEFAULT_UM}`}
						</span>
					</span>

					{/* Totale VOC */}
					<span className="columns-quantity" style={{ marginRight: '2em' }}>Totale VOC:&nbsp;
						<span className="font-bold">
							{`${DataFormatters.formatQuantity(totalVoc, 2)} ${DEFAULT_UM}`}
						</span>
					</span>
				</span>
			</>
		);
	}



	const dtParameters: DataTableProps<GetVOCStatsResponse[]> = {
		scrollable: true,
		// scrollHeight: "750px",
		rowHover: true,
		stripedRows: true,
		showGridlines: true,

		lazy: false,
		loading: isLoading,
		totalRecords: vocInfo.length,
		value: vocInfo,

		rowGroupMode: "subheader",
		groupRowsBy: groupKey,
		sortMode: "single",
		sortField: groupKey,
		sortOrder: 1,

		expandableRowGroups: true,
		expandedRows: expandedRows,

		onRowToggle: onRowToggle,

		rowGroupHeaderTemplate: formatRowGroupHeaderTemplate,
		rowGroupFooterTemplate: formatRowGroupFooterTemplate,

		columnResizeMode: "fit",
		emptyMessage: "Nessun articolo trovato",
		footer: formatFooterTableTemplate(vocInfo),
		dataKey: "articolo",
		//rowClassName: formatRow,
	}


	return (
		<DataTable className="editable-cells-table p-datatable-sm" { ...dtParameters } >
			{/*<Column field="sottocategoria" header="Sottocategoria" />*/ }
			<Column field="articolo" header="Articolo" style={ { width: '10%' } } />
			<Column field="descrizione" header="Descrizione" />
			<Column field="peso_totale_acquistato" header="Consumato" body={ totalConsumedBodyTemplate } style={ { width: '6%' } } />
			<Column field="percentuale_residuosecco" header="% Res.Secco" body={ residuoSeccoPercentBodyTemplate } style={ { width: '6%' } } className="columns-quantity" />
			<Column field="peso_residuosecco_consumato" header="Res.Secco" body={ totalResiduoSeccoConsumedBodyTemplate } style={ { width: '6%' } } className="columns-quantity" />
			<Column field="percentuale_voc" header="% VOC" body={ vocPercentBodyTemplate } style={ { width: '6%' } } className="columns-quantity" />
			<Column field="peso_voc_consumato" header="VOC (KG)" body={ totalVocConsumedBodyTemplate } style={ { width: '6%' } } className="columns-quantity" />
		</DataTable>
	);
};
