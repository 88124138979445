import { GetArticlesRequest } from '@dto/Requests/Articles/GetArticlesRequest';
import { GetArticleExistencesResponse } from '@dto/Responses/Articles/GetArticleExistencesResponse';
import { GetArticleImagesResponse } from '@dto/Responses/Articles/GetArticleImagesResponse';
import { GetArticleResponse, GetArticlesPagedResponse } from '@dto/Responses/Articles/GetArticleResponse';
import { GetArticleWithDiscounts, GetArticleWithDiscountsPagedResponse } from '@dto/Responses/Articles/GetArticleWithDiscountsResponse';
import { GetCategoriesResponse } from '@dto/Responses/Articles/GetCategoriesResponse';
import { GetFavoritesArticleWithDiscounts, GetFavoritesArticleWithDiscountsPagedResponse } from '@dto/Responses/Articles/GetFavoritesArticleWithDiscountsResponse';
import { GetIvecoArticleWithDiscountsPagedResponse } from '@dto/Responses/Articles/GetIvecoArticleWithDiscountsResponse';
import { GetTinteArticleWithDiscountsPagedResponse } from '@dto/Responses/Articles/GetTinteArticleWithDiscountsResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { PagedData } from '@models/PagedData';

import { ARTICLES } from '@transport/ApiDefinitions';
import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class ArticlesService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('Articles Service');
		this.httpEngine = new GShopHttpEngine();
	}



	public async listArticles(pageNumber: number, pageSize: number, sortField?: string, sortMode?: number): Promise<PagedData<GetArticleResponse>> {
		this.log.debug(`Fetching articles`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode);

		const responseDTO: GetArticlesPagedResponse = await this.httpEngine.get<GetArticlesPagedResponse>(GetArticlesPagedResponse, ARTICLES.LIST, articleParams);

		const pagedData = new PagedData<GetArticleResponse>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listCategories(): Promise<GetCategoriesResponse[]> {
		this.log.debug(`Fetching categories`);

		const responseDTO: GetCategoriesResponse[] = await this.httpEngine.getAsArray<GetCategoriesResponse>(GetCategoriesResponse, ARTICLES.LIST_CATEGORIES);

		return responseDTO;
	}



	public async listCustomerFavoritesCategories(customerID: number): Promise<GetCategoriesResponse[]> {
		this.log.debug(`Fetching categories`);

		const url = ARTICLES.LIST_FAV_CATEGORIES.replace('{customerID}', customerID.toString());

		const responseDTO: GetCategoriesResponse[] = await this.httpEngine.getAsArray<GetCategoriesResponse>(GetCategoriesResponse, url);

		return responseDTO;
	}



	public async getArticlesExistences(article: string): Promise<GetArticleExistencesResponse[]> {
		this.log.debug(`Fetching articles existences`);

		const url = ARTICLES.GET_EXISTENCES.replace('{article}', article);

		const responseDTO: GetArticleExistencesResponse[] = await this.httpEngine.getAsArray<GetArticleExistencesResponse>(GetArticleExistencesResponse, url);

		return responseDTO;
	}



	public async getArticleImages(article: string): Promise<GetArticleImagesResponse[]> {
		this.log.debug(`Fetching articles images`);

		const url = ARTICLES.GET_IMAGES.replace('{article}', article);

		const responseDTO: GetArticleImagesResponse[] = await this.httpEngine.getAsArray<GetArticleImagesResponse>(GetArticleImagesResponse, url);

		return responseDTO;
	}



	public async listArticlesWithDiscounts(customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetArticleWithDiscounts>> {
		this.log.debug(`Fetching articles with discounts`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);

		const url = ARTICLES.LIST_DISCOUNTS.replace('{customerID}', customerID.toString());
		const responseDTO: GetArticleWithDiscountsPagedResponse = await this.httpEngine.get<GetArticleWithDiscountsPagedResponse>(GetArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listNewArticlesWithDiscounts(customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetArticleWithDiscounts>> {
		this.log.debug(`Fetching only new articles with discounts`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);
		articleParams.OnlyNew = true;

		const url = ARTICLES.LIST_DISCOUNTS.replace('{customerID}', customerID.toString());
		const responseDTO: GetArticleWithDiscountsPagedResponse = await this.httpEngine.get<GetArticleWithDiscountsPagedResponse>(GetArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listObsoleteArticlesWithDiscounts(customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetArticleWithDiscounts>> {
		this.log.debug(`Fetching only obsolete articles with discounts`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);
		articleParams.OnlyObsolete = true;

		const url = ARTICLES.LIST_DISCOUNTS.replace('{customerID}', customerID.toString());
		const responseDTO: GetArticleWithDiscountsPagedResponse = await this.httpEngine.get<GetArticleWithDiscountsPagedResponse>(GetArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listTinteArticlesWithDiscounts(customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetArticleWithDiscounts>> {
		this.log.debug(`Fetching tinte articles with discounts`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);

		const url = ARTICLES.LIST_TINTE.replace('{customerID}', customerID.toString());
		const responseDTO = await this.httpEngine.get<GetTinteArticleWithDiscountsPagedResponse>(GetTinteArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listIvecoArticlesWithDiscounts(customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetArticleWithDiscounts>> {
		this.log.debug(`Fetching IVECO articles with discounts`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);

		const url = ARTICLES.LIST_IVECO.replace('{customerID}', customerID.toString());
		const responseDTO = await this.httpEngine.get<GetIvecoArticleWithDiscountsPagedResponse>(GetIvecoArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listSlowRotatingArticlesWithDiscounts(customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetArticleWithDiscounts>> {
		this.log.debug(`Fetching only slow rotating articles with discounts`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);
		articleParams.OnlySlowRotating = true;

		const url = ARTICLES.LIST_DISCOUNTS.replace('{customerID}', customerID.toString());
		const responseDTO: GetArticleWithDiscountsPagedResponse = await this.httpEngine.get<GetArticleWithDiscountsPagedResponse>(GetArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}



	public async listCustomerFavoritesArticles(year: number, customerID: number, pageNumber: number, pageSize: number, sortField?: string, sortMode?: number, articolo?: string, descrizione?: string, categories?: string[]): Promise<PagedData<GetFavoritesArticleWithDiscounts>> {
		this.log.debug(`Fetching favorites customer articles`);

		const articleParams: GetArticlesRequest = new GetArticlesRequest(pageNumber, pageSize, sortField, sortMode, articolo, descrizione, categories);

		const url = ARTICLES.LIST_FAVORITES.replace('{customerID}', customerID.toString())
							.replace('{year}', year.toString());
		const responseDTO: GetFavoritesArticleWithDiscountsPagedResponse = await this.httpEngine.get<GetFavoritesArticleWithDiscountsPagedResponse>(GetFavoritesArticleWithDiscountsPagedResponse, url, articleParams);

		const pagedData = new PagedData<GetFavoritesArticleWithDiscounts>(responseDTO.data, responseDTO);

		return pagedData;
	}
}
