import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { DashboardService } from '@services/DashboardService';
import { useAtom } from 'jotai';
import { sidebarBadgeAtom } from '@atoms/SidebarBadgesAtom';
import { useUpdateButSkipMount } from '@libs/KzHooks/UseUpdateButSkipMount';
import { GetCountRegistryResponse } from '@dto/Responses/Dashboard/GetCountRegistryResponse';



export const useSidebarBadge = () => {
	const log = useLog();

	const dashboardService = useSingleton<DashboardService>(() => new DashboardService(), []);

	const queryInfo = useQuery<GetCountRegistryResponse>({
		queryKey: [ 'sidebar-badges' ],
		queryFn: async () => dashboardService.getCountRegistry()
	});

	const [ badgeSidebar, setBadgeSidebar ] = useAtom(sidebarBadgeAtom);

	useUpdateButSkipMount(() => {
		setBadgeSidebar((draft) => {
			draft.totalCustomers = queryInfo.data?.total_customers ?? 0;
			draft.totalArticles = queryInfo.data?.total_articles ?? 0;
		});
	}, [ queryInfo.data ]);


	return {
		...queryInfo,
		sidebarInfo: queryInfo.data,
	}
};
