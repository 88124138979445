export class Recipient {
	public codice!: number;
	public ragione_sociale_1!: string;
	public ragione_sociale_2!: string;
	public nickname!: string;
	public indirizzo!: string;
	public localita!: string;
	public cap!: string;
	public provincia!: string;
	public stato!: string;
	public partita_iva!: string;
	public codice_fiscale!: string;
	public telefoni!: string;
	public telefax!: string;
	public cellulari!: string;
}
