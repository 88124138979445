import { OrdersMapper } from '@core/mappers/OrdersMapper';
import { GetBackOrdersRequest } from '@dto/Requests/Orders/GetBackOrdersRequest';
import { GetOrderDetailsRequest } from '@dto/Requests/Orders/GetOrderDetailsRequest';
import { GetOrdersHeadersRequest } from '@dto/Requests/Orders/GetOrdersHeadersRequest';
import { InsertOrderRequest } from '@dto/Requests/Orders/InsertOrderRequest';
import { GetOrderDetailsResponse } from '@dto/Responses/Orders/GetOrderDetailsResponse';
import { GetOrdersHeadersPagedResponse, GetOrdersHeadersResponse } from '@dto/Responses/Orders/GetOrdersHeadersResponse';
import { InsertOrderResponse } from '@dto/Responses/Orders/InsertOrderResponse';
import { ListBackOrdersResponse } from '@dto/Responses/Orders/ListBackOrdersResponse';
import { ListDepositsResponse } from '@dto/Responses/Orders/ListDepositsResponse';
import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { ObjectUtility } from '@libs/utility/ObjectUtility';
import { Cart } from '@models/Cart';
import { Order } from '@models/Order';
import { PagedData } from '@models/PagedData';

import { ORDERS } from '@transport/ApiDefinitions';
import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export type OrderType = 'A' | 'S' | 'N';



export class OrdersService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('OrdersService');

		this.httpEngine = new GShopHttpEngine();
	}



	public async listOrdersHeaders(orderType: OrderType, page: number, pageSize: number, customerID?: number): Promise<PagedData<GetOrdersHeadersResponse>> {
		this.log.debug(`Fetching orders ${orderType}`);

		const orderParams: GetOrdersHeadersRequest = new GetOrdersHeadersRequest(orderType, page, pageSize, customerID);

		const responseDTO: GetOrdersHeadersPagedResponse = await this.httpEngine.get<GetOrdersHeadersPagedResponse>(GetOrdersHeadersPagedResponse, ORDERS.LIST_ORDER_BY_HEADERS, orderParams);

		const ordersHeaders = new PagedData<GetOrdersHeadersResponse>(responseDTO.data, responseDTO);

		return ordersHeaders;
	}



	public async getBackOrders(customerID?: number): Promise<ListBackOrdersResponse[]> {
		this.log.debug(`Get backorders for customer #${customerID}`);

		if (ObjectUtility.isEmpty(customerID) || customerID === 0) {
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid customerID ${typeof (customerID)} for back orders...`);
		}

		const orderParams: GetBackOrdersRequest = new GetBackOrdersRequest(customerID);

		const responseDTO = await this.httpEngine.getAsArray<ListBackOrdersResponse>(ListBackOrdersResponse, ORDERS.GET_BACKORDERS, orderParams);

		return responseDTO;
	}



	public async listDeposits(): Promise<ListDepositsResponse[]> {
		this.log.debug(`Fetching deposit lists`);

		const responseDTO: ListDepositsResponse[] = await this.httpEngine.getAsArray<ListDepositsResponse>(ListDepositsResponse, ORDERS.LIST_DEPOSIT);

		return responseDTO;
	}



	public async getOrderDetails(orderCode: string, orderNumber: string): Promise<Order> {
		this.log.debug(`Fetching details for order ${orderCode}${orderNumber}`);

		const orderParams: GetOrderDetailsRequest = new GetOrderDetailsRequest(orderCode, Number.parseInt(orderNumber));

		// Si, potrei usar direttamente i parametri invece che creare una request ridondante. Però se in futuro mettessi un replace automatico in base al nome della property, sarebbe già parzialmente implementato
		const url = ORDERS.GET_DETAILS.replace('{orderCode}', orderParams.OrderCode)
											.replace('{orderNumber}', orderParams.OrderNumber.toString());

		const responseDTO: GetOrderDetailsResponse = await this.httpEngine.get<GetOrderDetailsResponse>(GetOrderDetailsResponse, url);

		const orderDetails = OrdersMapper.toOrderDetailsModel(responseDTO);

		return orderDetails;
	}



	public async insertNewOrder(cart: Cart): Promise<boolean> {
		this.log.debug(`Sending new order for cart ${cart.ID}`);

		const orderParams: InsertOrderRequest = OrdersMapper.toInsertOrder(cart);

		const responseDTO: InsertOrderResponse = await this.httpEngine.postJson<InsertOrderResponse>(InsertOrderResponse, ORDERS.NEW_ORDER, undefined, orderParams);

		return (responseDTO.Documento != '' && responseDTO.Numero != 0);
	}
}