/* eslint-disable unicorn/consistent-function-scoping */

import { discountBodyTemplate, formatCartRow, priceBodyTemplate, priceTotalAmountBodyTemplate } from '@desktop/components/orders/DataTableTemplates';
import { InputDiscount } from '@desktop/components/orders/InputDiscount';
import { DataFormatters } from '@guiutility/DataFormatters';
import { DiscountsUtility } from '@guiutility/DiscountsUtility';
import { useLog } from '@hooks/useLog';
import { useSettings } from '@hooks/useSettings';

import { orderSelectedArticlesAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderSelectedArticlesAtom';

import { DialogSelectArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogSelectArticles';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { CartRow } from '@models/Cart';
import { ArticleRow } from '@services/CartService';

import { List } from 'immutable';

import { useAtomValue } from 'jotai/utils';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';

import { DataTable, DataTableProps } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Messages } from 'primereact/messages';
import { Row } from 'primereact/row';
import { Nullable } from 'primereact/ts-helpers';
import React, { ReactNode, useRef, useState } from 'react';



type TabArticoliProps = {
	onAddedProductsHandler: (products: ArticleRow[]) => void,
	onUpdateCartRowHandler: (row: CartRow, quantity: number, price: number, discount: string) => void,
	onDeleteCartRowHandler: (e: HTMLElement, row: CartRow) => void,
	onSetSpecialCartRowHandler: (e: HTMLElement, row: CartRow) => void,
	isCustomerSelected: boolean,
	customerID: number,
	cartRows: List<CartRow> | undefined,
	children?: ReactNode
}



const VISIBLE_COLUMNS = 5;


export const TabArticles: React.FC<TabArticoliProps> = ({ onAddedProductsHandler, onUpdateCartRowHandler, onDeleteCartRowHandler, onSetSpecialCartRowHandler, isCustomerSelected, customerID, cartRows }: TabArticoliProps) => {
	const log = useLog();

	const op = useRef(null);

	const [ isShowArticoli, toggleDialogShowArticoli ] = useState<boolean>(false);

	const alertTableArticoli = useRef<Messages>(null);

	const selectedProducts = useAtomValue(orderSelectedArticlesAtom);
	const { settingsState } = useSettings();



	const onBtnAddArticle = (): void => {
		toggleDialogShowArticoli(true);
	}



	const hideDialog = (bConfirmedProducts: boolean) => {
		// Quando clicco su "Aggiungi" dentro il children DialogSelectArticolo, lo nascondo (è un evento del parent (TabArticles) che nasconde il children (DialogSelectArticolo) )
		toggleDialogShowArticoli(false);

		// E' ridondante, ma rende più chiaro i passaggi, ovvero hideDialog è la callback che nasconde il DialogArticles, dopo averlo nascosto, faccio scattare l'evento onHideAndConfirmedDialog, che mi servirà per capire
		// che ho chiuso il dialog e sto aggiungendo degli articoli
		if (bConfirmedProducts)
			onHideAndConfirmedDialog();
	}



	const onHideAndConfirmedDialog = () => {
		// Quando viene chiuso il dialog, chiamo l'handler che provvederà ad aggiunger le righe selezionate al carrello tramite CartService che gestirà il tutto
		onAddedProductsHandler(selectedProducts);
	}



	const onRowValueChange = (rowData: CartRow, qta: Nullable<string | number>, price: Nullable<string | number>, discount: string | number) => {
		const newQta = (qta == undefined) ? "1" : qta.toString();
		let newDiscount = discount?.toString() ?? rowData.CustomDiscount;     // Se c'è lo sconto impostato dall'agente uso quello, in alternativa metterò quello personale (non posso andare sotto lo sconto personalizzato)

		// Se ho scritto il tasto "+" evito di processare il resto (altrimenti mi sovrascrive il valore attuale con quello vecchio (es 8+5 => 8+5+..3 non riesco a scriverlo perchè mi metterebbe sempre 8+5 visto che fa il replace dell'ultimo +)
		//if (newDiscount.slice(-1) === "+")
			//return;
			//newDiscount = newDiscount.slice(0, -1);

		const normalizedCustomDiscount = DiscountsUtility.normalizeDiscount(rowData.CustomDiscount);
		const normalizedNewDiscount = DiscountsUtility.normalizeDiscount(newDiscount);
		const normalizedMaxDiscount = DiscountsUtility.normalizeDiscount(rowData.MaxDiscount);

		if (normalizedNewDiscount < 0 || normalizedNewDiscount > 100)
			newDiscount = rowData.CustomDiscount;

		// Non posso fare sconti superiori a quelli impostati sull'articolo (Campo Locazione)
		if (normalizedMaxDiscount != 0 && normalizedNewDiscount > normalizedMaxDiscount) {
			newDiscount = rowData.CustomDiscount;

			alertTableArticoli.current?.show(
				{
					severity: 'warn',
					detail: `Lo sconto per l'articolo ${rowData.Product} supera il massimo consentito (${rowData.MaxDiscount}%)`,
					life: 5000,
				},
			);
		}

		// Se NON posso fare sconti inferiori a quelli di listino (esempio: 3 + 1 (compri 3 articoli a prezzo pieno sconto 0 e te ne regalo uno sconto 100), vado a controllare se lo sconto è inferiore a quello listino
		if (!settingsState.allowUnderDiscount) {
			// Es: Sconto 20% su articolo XXX => non posso fare uno sconto <20% perchè questo è il minimo sindacale su cui non posso scendere
			// eslint-disable-next-line unicorn/no-lonely-if
			if (normalizedNewDiscount < normalizedCustomDiscount)
				newDiscount = rowData.CustomDiscount;
		}

		onUpdateCartRowHandler(rowData, Number(newQta), Number(price),newDiscount)
	}



	const quantityBodyTemplate = (rowData: CartRow) => {
		return <InputNumber
			value={rowData.Quantity}
			onValueChange={(e) => onRowValueChange(rowData, e.value, rowData.Price, rowData.Discount)}
			showButtons={true}

			buttonLayout="horizontal"

			min={1}
			step={1}

			incrementButtonIcon="pi pi-plus"
			incrementButtonClassName="p-button-success"

			decrementButtonIcon="pi pi-minus"
			decrementButtonClassName="p-button-danger"

			mode="decimal"

			size={2}

			inputStyle={{ textAlign: 'center' }}
		/>
	}



	const quantityCoeffBodyTemplate = (rowData: CartRow) => {
		const qtaEffettiva = ( rowData.Coefficient_Multiply > 0 ) ? rowData.Quantity * rowData.Coefficient_Multiply : rowData.Quantity;

		return <span className="font-bold">{ qtaEffettiva }</span>
	}



	const onValidateDiscountEditor = () => {
		log.info('AAA');
	}



	/**
	 * Textbox a scomparsa sulla RIGA per modificare lo sconto (se è attiva l'opzione dai settings)
	 * @param rowData CartRow
	 * @returns {JSX.Element} TextBox per variare lo sconto
	 */
	const discountEditor = ({ rowData }: any) => {
		const r: CartRow = rowData as CartRow;

		if (rowData.NotDiscountable) // Qualora NON sia scontabile, restituisco l'element generato dal bodyTemplate (questo perchè il body è formattato tramite template, e diventat ridondante riscriver qua la routine)
			return discountBodyTemplate(rowData);

		const rxAllowNumbersAndMinus = /[\d+]+$/;

		const inputID = `discount_${r.ID}`;
		const discount = (r.Discount === "") ? r.CustomDiscount : r.Discount;

		log.info("discountEditor => " + discount);

		return (
			<InputDiscount size={3} firstValue={discount} cbOnValidated={(newDiscount) => onRowValueChange(r, r.Quantity, r.Price, newDiscount)} />
			//<InputText size={3} keyfilter={rxAllowNumbersAndMinus} value={discount} onBlur={onValidateDiscountEditor} onChange={(e) => onRowValueChange(r, r.Quantity, r.Price, e.target.value) } />
			// <InputNumber inputId={inputID} size={3} mode="decimal" useGrouping={false} min={0} max={100} suffix="%" value={discount} onValueChange={(e) => onRowValueChange(r, r.Quantity, r.Price, e.value) } />
		)
	}



	/**
	 * Textbox a scomparsa sulla RIGA per modificare il prezzo (se è attiva l'opzione dai settings)
	 * @param rowData CartRow
	 * @returns {JSX.Element} TextBox per variare il prezzo
	 */
	const priceEditor = ({ rowData }: any) => {
		const r: CartRow = rowData as CartRow;

		const inputID = `price_${r.ID}`;
		const price = r.Price; //(r.Price === 0) ? r.OriginalPrice : r.Price;

		return (
			<InputNumber inputId={inputID} size={5} mode="currency" currency="EUR" useGrouping={false} value={price} onValueChange={(e) => onRowValueChange(r, r.Quantity, e.value, r.Discount) } />
		)
	}



	/**
	 * actionBodyTemplate Action cell (elimazione riga carrello)
	 * @param rowData - model CartRow della riga del carrello/ordine
	 */
	const actionBodyTemplate = (rowData: CartRow) => {
		return (
			<>
				{ settingsState.markSpecialRow && <Button icon="pi pi-star" className="p-button-rounded p-button-warning buttons-actions" onClick={(e) => onSetSpecialCartRowHandler(e.currentTarget, rowData) } /> }
				<Button icon="pi pi-trash" className="p-button-rounded p-button-danger buttons-actions" onClick={(e) => onDeleteCartRowHandler(e.currentTarget, rowData) } />
				{ /* TODO: Provare a convertirla con un useCallback per migliorare le performance o con MouseEvent<T> */ }
			</>
		);
	};



	const formatFooter = (): string => {
		return "";
	}



	/**
	 * Footer con totale del carrello (calcolato sommando le varie righe)
	 * @param cartRows righe del carrello
	 * @returns {JSX.Element}
	 */
	const footerGroup = (cartRows: List<CartRow> | undefined): ReactNode => {
		const totalCartAmount = cartRows?.reduce( (totalCartAmount: number, currentCart: CartRow) => {
																return totalCartAmount + currentCart.TotalAmount;
															}, 0) ?? 0;


		return (
			<ColumnGroup>
				<Row>
					<Column footer="Totale ordine:" colSpan={VISIBLE_COLUMNS} footerStyle={{ textAlign: 'right' }} />
					<Column footer={DataFormatters.formatPrice(totalCartAmount) } footerStyle={{ textAlign: 'right' }} />
				</Row>
			</ColumnGroup>
		);
	}


	const dtParameters: DataTableProps<CartRow[]> = {
		scrollable: true,
		scrollHeight: "750px",
		rowHover: true,
		stripedRows: true,
		paginator: false,
		editMode: "cell",

		lazy: false,
		loading: false,
		value: cartRows?.toArray(),

		columnResizeMode: "fit",
		emptyMessage: "Nessun articolo inserito",
		dataKey: "ID",

		footer: formatFooter(),
		footerColumnGroup: footerGroup(cartRows),

		rowClassName: formatCartRow
	}



	return (
		<>
			<DataTable className="p-datatable-sm orders-new-table" {...dtParameters} >
				<Column field="ID" header="ID" style={{ width: '2%', display: 'none' }} />
				<Column field="Product" header="Articolo" style={{ width: '5%', overflow: 'hidden' }} />
				<Column field="Description" header="Descrizione"  />
				{/*<Column field="UM" header="UM" style={{ width: '2%' }} />*/}
				{/*<Column field="VAT" header="IVA"  style={{ width: '3%' }} />*/}
				<Column field="Quantity" header="Quantita"  style={{ width: '10%' }} body={quantityBodyTemplate} />
				{ settingsState.useMultiplyCoefficient && <Column field="Quantity_Coef" header="Qta Effettiva"  style={{ width: '10%' }} body={quantityCoeffBodyTemplate} /> }
				<Column field="Price" header="Prezzo"  style={{ width: '10%', textAlign: 'right' }} className="columns-price" body={priceBodyTemplate} editor={settingsState.pricesEditable ? priceEditor : undefined} />
				<Column field="Discount" header="Sconto"  style={{ width: '10%', textAlign: 'center' }} body={discountBodyTemplate} editor={settingsState.discountsEditable ? discountEditor : undefined} />
				<Column field="TotalAmount" header="Totale"  style={{ width: '5%', textAlign: 'right'  }} body={priceTotalAmountBodyTemplate} />
				<Column exportable={false} style={{ width: '8%', textAlign: 'center' }} body={actionBodyTemplate} />
			</DataTable>

			<br />

			<Button label="Aggiungi articolo" icon="pi pi-external-link" onClick={onBtnAddArticle} disabled={!isCustomerSelected} />

			{/* Form Dialog a popup con selezione degli articoli */}
			<DialogSelectArticles customerID={customerID} isVisible={isShowArticoli} cbHideDialog={hideDialog} />

			<Messages ref={alertTableArticoli} />
		</>
	)
}
