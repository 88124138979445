import { Customer } from '@models/Customer';
import { Recipient } from '@models/Recipient';


export class OrderRow {
	public articolo!: string;
	public descrizione!: string;
	public unita_di_misura!: string;
	public quantita_1!: number;
	public quantita_2!: number;
	public quantita_3!: number;
	public prezzo!: number;
	public sconti!: string;
	public importo!: number;
	public codice_iva!: string;
	public id!: string;
}


export class Order {
	public documento!: string;
	public flag!: string;
	public stato!: string;
	public ordineid!: string;
	public data!: string;
	public codice!: number;
	public customer: Customer | undefined;
	public pagamento!: string;
	public banca!: number;
	public agente!: number;
	public destinatario!: number;
	public recipient: Recipient | undefined;
	public spedizioniere!: number;
	public listino!: number;
	public sigla!: string;
	public imponibile_1!: number;
	public codice_iva_1!: string;
	public imposta_1!: number;
	public imponibile_2!: number;
	public codice_iva_2!: string;
	public imposta_2!: number;
	public imponibile_3!: number;
	public codice_iva_3!: string;
	public imposta_3!: number;
	public imponibile_4!: number;
	public codice_iva_4!: string;
	public imposta_4!: number;
	public imponibile_5!: number;
	public codice_iva_5!: string;
	public imposta_5!: number;
	public imponibile_6!: number;
	public codice_iva_6!: string;
	public imposta_6!: number;
	public totale_merce!: number;
	public percentuale_sconto!: number;
	public importo_sconto!: number;
	public netto_merce!: number;
	public spese_trasporto!: number;
	public spese_imballo!: number;
	public spese_varie!: number;
	public spese_incasso!: number;
	public spese_bolli!: number;
	public totale_imponibile!: number;
	public totale_imposta!: number;
	public totale_documento!: number;
	public totale_in_valuta!: number;
	public totale_da_pagare!: number;
	public data_inizio!: string;
	public ora_inizio!: string;
	public colli!: string;
	public cartoni!: string;
	public peso!: string;
	public peso_lordo!: string;
	public volume!: string;
	public palette!: string;
	public trasporto_a_mezzo!: string;
	public porto!: string;
	public causale_trasporto!: string;
	public aspetto_beni!: string;
	public annotazioni!: string;
	public filler!: string;
	public workstation!: string;
	public cig_cup!: string;
	public id_sdi!: string;

	public righe_documento!: OrderRow[];
}
