export enum WebExceptionErrors {
    INVALID_MODEL_REQUEST,
    INVALID_MODEL_RESPONSE,
    NETWORK_OFFLINE,
    UNKNOWN_ERROR,
}



export class WebException extends Error {
    public Date: Date;
    public ErrorCode: WebExceptionErrors;


    constructor(errorCode: WebExceptionErrors, message: string) {
        super();     // Pass remaining arguments (including vendor specific ones) to parent constructor

        if (Error.captureStackTrace) {    // Maintains proper stack trace for where our error was thrown (only available on V8)
            Error.captureStackTrace(this, WebException);
        }

        // Custom debugging information
        this.Date = new Date();
        this.ErrorCode = errorCode;
        this.message = message;
    }
}
