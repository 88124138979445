import { t } from '@deepkit/type';
import { DiscountsUtility } from '@guiutility/DiscountsUtility';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { ArticleRow } from '@services/CartService';
import { List } from 'immutable';



export class CartRow {
	@t public ID!: number;
	@t public RowID!: number;
	@t public Product!: string;
	@t public Description!: string;
	@t public UM!: string;
	@t public VAT!: string;
	@t public Quantity!: number;
	@t public Price!: number;
	@t public Packs!: number;
	@t public Pieces_per_Pack!: number;
	@t public Coefficient_Multiply!: number;
	@t public Discount!: string;
	@t public OriginalPrice!: number;
	@t public DiscountedPrice!: number;
	@t public CustomPrice!: number;
	@t public CustomDiscount!: string;
	@t public NotDiscountable!: boolean;
	@t public MaxDiscount!: string;
	@t public TotalAmount!: number;
	@t public SpecialRow!: boolean;
	@t public Notes!: string;
}


type CartRowID = {
	index: number;
	rowData: CartRow | undefined;
}



export class Cart {
	@t public ID!: string;

	@t public CustomerID!: number;
	@t public RecipientID!: number;

	@t public DepositID!: number;

	@t public isSendEmail!: boolean;
	@t public EmailCustomer!: string;

	@t public NoteCliente!: string;
	@t public NoteAgente!: string;

	@t public Rows: List<CartRow>;

	@t.exclude() private log: BaseLogger;         // localforage NON riesce a serializzarlo, e non riesco ad escluderlo da essa


	constructor() {
		this.ID = "";
		this.CustomerID = 0;
		this.RecipientID = 0;
		this.NoteCliente = "";
		this.NoteAgente = "";
		this.DepositID = -1;
		this.isSendEmail = false;
		this.EmailCustomer = "";

		this.Rows = List<CartRow>();

		this.log = new KzLogger("Cart");
	}



	/**
	 * Aggiungo un articolo al carrello. ArticleRow è diverso da CartRow in quanto ci sono degli ID e dei valori calcolati dal Cart stesso (TotalAmount)
	 * @param product ArticleRow, è un wrapper dei parametri da passare, invece che fare una function con mille parametri addProduct(articolo, descrizione, prezzo, sconto, ecc ecc ecc)
	 */
	public addProduct(product: ArticleRow): void {
		const cr = new CartRow();

		cr.ID = this.getMaxID() +1;
		cr.RowID = this.getMaxID() +1;
		cr.Product = product.articolo;
		cr.Description = product.descrizione;
		cr.VAT = product.codice_iva;
		cr.UM = product.um;
		cr.SpecialRow = product.specialrow;
		cr.Notes = product.notes;

		cr.Quantity = product.quantita_3 ?? 0;
		cr.Pieces_per_Pack = product.quantita_2;
		cr.Packs = product.quantita_1;

		cr.Price = (product.prezzo_personalizzato === 0) ? product.prezzo : product.prezzo_personalizzato;
		cr.Discount = (product.prezzo_personalizzato === 0) ? product.sconto : "";

		cr.MaxDiscount = product.sconto_massimo;
		cr.CustomPrice = product.prezzo_personalizzato;
		cr.CustomDiscount = (product.prezzo_personalizzato === 0) ? product.sconto_personalizzato : "";

		cr.NotDiscountable = product.non_scontabile;

		cr.OriginalPrice = product.prezzo_listino;
		cr.DiscountedPrice = (product.prezzo_personalizzato === 0) ? product.prezzo_scontato : product.prezzo_personalizzato;

		cr.Coefficient_Multiply = product.coefficiente_moltiplicativo;

		console.info(product);
		console.info(cr);

		cr.TotalAmount = (product.prezzo_personalizzato === 0) ?
						 this.calculateTotalAmount(product.quantita_3, product.prezzo, product.sconto)
															 :
						 this.calculateTotalAmount(product.quantita_3, product.prezzo_personalizzato, "");

		this.Rows = this.Rows.push(cr);
	}



	/**
	 * Rimuove una riga dal carrello
	 * @param cartRowID ID della riga da eliminare
	 */
	public removeProduct(cartRowID: number): void {
		const index = this.Rows.findIndex(cr => cr.ID == cartRowID);
		if (index > -1) {
			this.Rows = this.Rows.splice(index, 1);
		}
	}



	/**
	 * Aggiorna la quantità 3 di una riga ed eventualmente lo sconto
	 * @param cartRowID ID riga da aggiornare
	 * @param quantity nuova quantita
	 * @param price
	 * @param discount sconto
	 */
	public updateCartRow(cartRowID: number, quantity: number, price: number, discount: string): void {
		const cri = this.getCartRow(cartRowID);

		if (cri.rowData != undefined) {
			cri.rowData.Price = price;
			cri.rowData.Discount = discount;
			cri.rowData.Quantity = quantity;
			cri.rowData.TotalAmount = this.calculateTotalAmount(quantity, price, discount);

			this.Rows = this.Rows.set(cri.index, cri.rowData);
		}
	}



	/**
	 * Imposto una riga come special (tipo in black........ o da fare attenzione sull'ordine) (associata a ContoLavoroCorpo)
	 * @param cartRowID ID riga da aggiornare
	 * @param isSpecial flag special o no
	 */
	public updateSpecialRow(cartRowID: number, isSpecial: boolean): void {
		const cri = this.getCartRow(cartRowID);

		if (cri.rowData != undefined) {
			cri.rowData.SpecialRow = isSpecial;

			this.Rows = this.Rows.set(cri.index, cri.rowData);
		}
	}



	/**
	 * Aggiungo una nota sulla riga del carrello (associata ai riferimenti)
	 * @param cartRowID ID riga da aggiornare
	 * @param notes riferimenti da metter
	 */
	public updateNotes(cartRowID: number, notes: string): void {
		const cri = this.getCartRow(cartRowID);

		if (cri.rowData != undefined) {
			cri.rowData.Notes = notes;

			this.Rows = this.Rows.set(cri.index, cri.rowData);
		}
	}



	/**
	 * Svuoto TUTTE le righe del carrello
	 */
	public clear(): void {
		this.Rows = this.Rows.clear();
	}



	/**
	 * Cerco una riga specifica all'interno del carrello, per poi andare ad aggiornarla in base al suo index
	 * @param cartRowID ID riga da cercare
	 * @returns {CartRowID} model CartRow + ID riga (server per aggiornare la riga nella collection)
	 */
	private getCartRow(cartRowID: number): CartRowID {
		const index = this.Rows.findIndex(cr => cr.ID == cartRowID);

		const cri: CartRowID = {
			index : index,
			rowData : (index > -1) ? this.Rows.get(index) ?? undefined : undefined,
		}

		return cri;
	}



	/**
	 * Generazione ID Riga basata sul numero più alto che trovo
	 * @returns {number}
	 */
	private getMaxID(): number {
		const cr: CartRow | undefined = this.Rows.maxBy(cr => cr.ID);

		return (cr === undefined) ? 0 : cr.ID;
		// this.Rows.reduce((r1, r2) => r1.ID > r2.ID ? r1 : r2).ID;        // Per gli array (non la uso perchè uso una List immutable.js)
		// Math.max(...this.Rows.map(cr => cr.ID), 0);                      // Lento
	}



	/**
	 * Calcolo del totale della riga del carrello
	 * @param quantity quantita totale
	 * @param price prezzo unitario
	 * @param discount sconto da applicare al prezzo
	 * @returns {number} importo totale
	 */
	private calculateTotalAmount(quantity: number, price: number, discount: string): number {
		if (discount === "")
			return quantity * price;

		const priceDiscounted = DiscountsUtility.calculateDiscount(price, discount);
		return quantity * priceDiscounted;
	}
}
