import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';


export class GetVOCStatsResponse extends GShopResponse {
    @t  public articolo!: string;
    @t  public descrizione!: string;
    @t  public unita_di_misura!: string;
    @t  public categoria!: string;
    @t  public descrizione_categoria!: string;
    @t  public sottocategoria!: string;
    @t  public descrizione_sottocategoria!: string;
    @t  public sottogruppo!: string;
    @t  public descrizione_sottogruppo!: string;
    @t  public linea_produzione!: string;
    @t  public descrizione_lineaproduzione!: string;
    @t  public peso_articolo!: number;
    @t  public peso_lordo!: number;
    @t  public percentuale_residuosecco!: number;
    @t  public percentuale_voc!: number;
    @t  public totale_acquistati!: number;
    @t  public peso_totale_acquistato!: number;
    @t  public peso_voc_consumato!: number;
    @t  public peso_residuosecco_consumato!: number;
}
