import React from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
//import { Card } from 'primereact/card';

import './Homepage.scss';

//import GShopLogo from '@images/store.png';


export const Homepage: React.FC = () => (

	<>
		<div className="box-home">
			<div className="grid grid-nogutter surface-0 text-800">
				<div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
					<section>
						<span className="block text-6xl font-bold mb-1">GShop</span>
						<div className="text-6xl text-primary font-bold mb-3">Shop eXperience</div>

						<p className="mt-0 mb-4 text-700 line-height-3">Accedi subito con le tue credenziali nell&apos;area riservata per poter consultare le tue statistiche ed effettuare ordini come agente</p>
					</section>
				</div>

				<div className="col-12 md:col-6 overflow-hidden">
					{/*<img src={GShopLogo} alt="GShop" style={{height: 200}} />*/}
				</div>
			</div>

			<div className="box-home-info grid surface-0 text-center">
				<div className="col-12 md:col-4 mb-4 px-5">
					<span className="p-3 shadow-2 mb-3">
						<i className="pi pi-desktop text-4xl text-blue-500"/>
					</span>
					<div className="text-900 mb-3 font-medium">A misura di agente</div>
					<span className="text-700 text-sm">Vestito appositamente per le esigenze degli agenti</span>
				</div>

				<div className="col-12 md:col-4 mb-4 px-5">
					<span className="p-3 shadow-2 mb-3">
						<i className="pi pi-lock text-4xl text-blue-500"/>
					</span>
					<div className="text-900 mb-3 font-medium">SSL Encryption</div>
					<span className="text-700 text-sm">Sicurezza trasmissione dei dati tramite certificato SSL</span>
				</div>

				<div className="col-12 md:col-4 mb-4 px-5">
					<span className="p-3 shadow-2 mb-3">
						<i className="pi pi-check-circle text-4xl text-blue-500"/>
					</span>
					<div className="text-900 mb-3 font-medium">Facile da usare</div>
					<span className="text-700 text-sm">L&apos;interfaccia semplificata ti permette</span>
				</div>

				<div className="col-12 md:col-4 mb-4 px-5">
					<span className="p-3 shadow-2 mb-3">
						<i className="pi pi-globe text-4xl text-blue-500"/>
					</span>
					<div className="text-900 mb-3 font-medium">Supporto</div>
					<span className="text-700 text-sm">Hai dubbi o hai trovato errori? Contatta Gigashop!</span>
				</div>

				<div className="col-12 md:col-4 mb-4 px-5">
					<span className="p-3 shadow-2 mb-3">
						<i className="pi pi-mobile text-4xl text-blue-500"/>
					</span>
					<div className="text-900 mb-3 font-medium">Multipiattaforma</div>
					<span className="text-700 text-sm">Desktop - Smartphone - Tablet</span>
				</div>

				<div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
					<span className="p-3 shadow-2 mb-3">
						<i className="pi pi-shield text-4xl text-blue-500"/>
					</span>
					<div className="text-900 mb-3 font-medium">Sicurezza</div>
					<span className="text-700 text-sm">I tuoi dati sono al sicuro</span>
				</div>
			</div>

			<div className="surface-100 text-700 text-center p-6 my-6">
				<Link to="/login">
					<Button label="Accedi" icon="pi pi-user" className="w-3 font-bold py-3 my-3 p-button-raised" />
				</Link>
			</div>

			<div className="surface-0 text-700 text-center mt-8">
				<div className="surface-0 text-blue-600"><i className="pi pi-star"/>&nbsp;Sviluppato da Claudio Fronza - Gigashop</div>
			</div>
		</div>
	</>
);