import React, { ReactNode } from 'react';

//import 'chart.js/auto';       <<-- Senza tree-shaking (non serve Chart.register)
import { BarController, CategoryScale, Chart, ChartData, ChartOptions, Filler, Legend, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useLog } from '@hooks/useLog';
import { useImmer } from 'use-immer';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { GetSalesBySubcategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesBySubcategoryResponse';


// Da capire se è meglio metterlo in onMount()
Chart.register(
	BarController,
	CategoryScale,
	Filler,
	Title,
	Tooltip,
	Legend
);



type SalesChartProps = {
	salesInfo: GetSalesBySubcategoryResponse[],
	isLoading: boolean,
	children?: ReactNode
}



type SalesChartModel = {
	labels: string[],
	data: number[],
}


const defaultSalesChartModel: SalesChartModel = {
	labels: [],
	data: []
}



const lightOptions: ChartOptions<'bar'> = {
	maintainAspectRatio: false,
	// aspectRatio: 0.5,
	plugins: {
		legend: {
			display: false,
			position: 'left',
			labels: {
				color: '#495057'
			}
		}
	}
};



export const CardChartSalesBySubcategory: React.FC<SalesChartProps> = ({ salesInfo, isLoading }: SalesChartProps) => {
	const log = useLog();

	const [ chartValues, setChartValues ] = useImmer<SalesChartModel>(defaultSalesChartModel);

	const chartData: ChartData<'bar'> = {
		labels: chartValues.labels,
		datasets: [
			{
				indexAxis: 'y',
				type: 'bar',
				label: 'Importo',
				backgroundColor: 'rgba(54, 162, 235, 1)',
				borderColor: 'white',
				borderWidth: 2,
				data: chartValues.data
			},
		]
	}



	const mapVocToChartModel = () => {
		const cd: SalesChartModel = {
			labels: [],
			data: []
		};

		for (const gvsr of salesInfo) {
			cd.labels.push(gvsr.descrizione_sottocategoria ?? gvsr.sottocategoria);
			cd.data.push(gvsr.importo_venduto);
		}

		setChartValues(cd);
	}



	useUpdate(() => {
		if (!isLoading)
			mapVocToChartModel();
	}, [ salesInfo, isLoading ]);


	return (
		<>
			<div className="flex justify-content-center">
				<Bar data={chartData} options={lightOptions} style={ {height: "500px" }} />
			</div>
		</>
	);
};
