import { cartStore } from '@gui/stores/CartStore';
import { useLog } from '@hooks/useLog';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';

import React, { useRef, useState } from 'react';
import { Menubar } from 'primereact/menubar';

import './Debug.scss';



const DebugZone: React.FC = () => {
	const log = useLog();

	const abc = cartStore.fullName;

	const city = cartStore.address.city.get();
	const city2 = cartStore.address.city.use();
	const fullName = cartStore.fullName.get();
	const fullName2 = cartStore.fullName.use();

	//const router = useRouter();

	//const pippo = cartStore.addTodo("aaa");


	const onClickTest = () => {
		log.info('onClickTest', { city, fullName, fullName2 });

		cartStore.name.set("pippo");
	}


	const onClick2Test = () => {
		cartStore.addTodo("aaa");
	}


	const onClickReset = () => {
		localStorage.removeItem('cart-storage');
		//cartStore.persist.clearStorage()
	}

	const items = [
		{
			label: 'Home',
			icon: 'pi pi-home'
		},
		{
			label: 'Features',
			icon: 'pi pi-star'
		},
		{
			label: 'Projects',
			icon: 'pi pi-search',
			items: [
				{
					label: 'Components',
					icon: 'pi pi-bolt'
				},
				{
					label: 'Blocks',
					icon: 'pi pi-server'
				},
				{
					label: 'UI Kit',
					icon: 'pi pi-pencil'
				},
				{
					label: 'Templates',
					icon: 'pi pi-palette',
					items: [
						{
							label: 'Apollo',
							icon: 'pi pi-palette'
						},
						{
							label: 'Ultima',
							icon: 'pi pi-palette'
						}
					]
				}
			]
		},
		{
			label: 'Contact',
			icon: 'pi pi-envelope'
		}
	];


	return (
		<div className="about-panel">
			{/*<Menubar model={items} />*/}

			<div className="card">
				<h5>Debug Zone - { dayjs(__BUILD_DATE__).format('YYYY-MM-DD @ HH.mm') }</h5>

				<h5>city GET - { city }</h5>
				<h5>city USE - { city2 }</h5>
				<h5>fullName - { fullName }</h5>
				<h5>fullName2 - { fullName2 }</h5>

				<Button type="button" icon="pi pi-search" label="Test" onClick={ onClickTest } /> <br />
				<Button type="button" icon="pi pi-search" label="Test2" onClick={ onClick2Test } /> <br />
				<Button type="button" icon="pi pi-check" label="Remove Storage" onClick={ onClickReset } />
			</div>
		</div>
	);
};


export { DebugZone };
