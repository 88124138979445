import { ThemeProvider } from '@contexts/ThemeContext';

import { Headerbar } from '@desktop/components/header/Headerbar';
import { HorizontalBarKz } from '@desktop/components/menu/horizontalbar/HorizontalBarKz';

import { SidebarKz } from '@desktop/components/menu/sidebar/kz/SidebarKz';
import { useLog } from '@hooks/useLog';
import { useSettings } from '@hooks/useSettings';
import classNames from 'classnames';
import React, { ReactNode } from 'react';



type LayoutProps = {
	children: ReactNode
}



export const PrivateLayoutContent: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
	const log = useLog();

	const { settingsState } = useSettings();

	return (
		<ThemeProvider>

			{ ( settingsState.sidebarVertical ) ?
			  (
				  <>
					  <Headerbar />
					  <SidebarKz />
				  </> )
			 :

			  <HorizontalBarKz />
			}

			<div className={classNames('layout-content', { 'sidebar-menu': settingsState.sidebarVertical, 'horizontal-menu': !settingsState.sidebarVertical })}>
				{ children }
			</div>
		</ThemeProvider>
	);
};
