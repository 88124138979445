import { paginatorTemplate } from '@desktop/components/commons/DataTablePaginator';
import { CustomerSelection } from '@desktop/components/customers/CustomerSelection';

import { dateBodyTemplate, formatRow, ordineIDBodyTemplate, priceTotaleBodyTemplate, priceTotaleImponibileBodyTemplate, priceTotaleIVABodyTemplate } from '@desktop/components/orders/DataTableTemplates';
import { GetOrdersHeadersResponse } from '@dto/Responses/Orders/GetOrdersHeadersResponse';

import { defaultOrderListRequest, OrderListRequest, useOrdersList } from '@hooks/orders/useOrdersList';

import { useLog } from '@hooks/useLog';
import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';

import { Accordion, AccordionTab, AccordionTabOpenEvent } from 'primereact/accordion';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable, DataTableProps, DataTableStateEvent } from 'primereact/datatable';
import React, { useState } from 'react';
import { useImmer } from 'use-immer';

import './OrdersList.scss';



const OrdersList: React.FC = () => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<OrderListRequest>(defaultOrderListRequest);

	const [ firstRowDisplayed, setFirst ] = useState<number>(0);
	const { isLoading, ordersHeader, totalRecords } = useOrdersList(reqParams);


	const onTabOpenHandler = (e: AccordionTabOpenEvent) => {
		switch (e.index) {
			case 0:
				setRequest((draft) => {
					draft.OrderType = "A";
				});

				break;

			case 1:
				setRequest((draft) => {
					draft.OrderType = "N";
				});

				break;

			case 2:
				setRequest((draft) => {
					draft.OrderType = "S";
				});

				break;
		}
	}



	const onPaginator = (e: DataTableStateEvent): void => {
		setRequest((draft) => {
			draft.PageNumber = (e.page === undefined) ? 1 : e.page +1;
			draft.PageSize = e.rows;
		});

		setFirst(e.first);
	};



	const dtParameters: DataTableProps<GetOrdersHeadersResponse[]> = {
		rowHover: true,
		stripedRows: true,
		paginator: true,
		paginatorTemplate: paginatorTemplate,

		lazy: true,
		loading: isLoading,
		totalRecords: totalRecords,         // Attenzione che il pager fa triggerare onPage se totalRecords è INFERIORE al rows (righe per pagina)
		rows: reqParams.PageSize,
		value: ordersHeader,

		first: firstRowDisplayed,           // In base al numero del record, il paginator segna in quale pagina è attualmente posizionato  1 2 > 3 < 4 5 6
		onPage: onPaginator,

		// columnResizeMode: "fit",
		emptyMessage: "Nessun ordine trovato",
		footer: "",
		dataKey: "ordineid",
		rowClassName: formatRow,
	}


	const dtColumns: ColumnProps[] = [
		{ field: 'ordineid', header: '#', body: ordineIDBodyTemplate, style: { width: '5%' }},
		{ field: 'data', header: 'Data', body: dateBodyTemplate, style: { width: '7%' }},
		{ field: 'nominativo_cliente', header: 'Cliente', style: { width: '32%' }},
		{ field: 'nominativo_destinatario', header: 'Destinatario', style: { width: '32%' }},
		{ field: 'articoli_ordinati', header: '# Articoli', style: { width: '6%' }},
		{ field: 'totale_imponibile', header: 'Imponibile', body: priceTotaleImponibileBodyTemplate, style: { width: '6%' }},
		{ field: 'totale_imposta', header: 'IVA', body: priceTotaleIVABodyTemplate, style: { width: '6%' }},
		{ field: 'totale_documento', header: 'Totale', body: priceTotaleBodyTemplate, style: { width: '6%' }},
	];


	const colums = dtColumns.map((col, i) =>{
		return <Column key={col.field} {...col } />
	});



	//#region Eventi CardCustomer: Selezione del cliente dal TreeSelect

	const onSelectedCustomer = (customer: CustomerWithDetails, recipient: Recipient | undefined) => {
		setRequest((draft) => {
			draft.CustomerID = customer.codice;
		});
	};

	//#endregion



	return (
		<>
			<div className="content-section introduction">
				<h1>Elenco ordini WEB</h1>
				<p>Qui trovi tutti gli ordini effettuati</p>
			</div>

			<div className="content-section implementation button-demo">
				<div className="card">
					<CustomerSelection showOnlyActive={true} onCustomerSelectedHandler={onSelectedCustomer} />
				</div>

				<div className="card">
					<Accordion onTabOpen={onTabOpenHandler} activeIndex={0}>
						<AccordionTab header="Aperti">
							<DataTable className="p-datatable-sm" {...dtParameters}>
								{ colums }
							</DataTable>
						</AccordionTab>


						<AccordionTab header="Confermati">
							<DataTable className="p-datatable-sm" {...dtParameters}>
								{ colums }
							</DataTable>
						</AccordionTab>


						<AccordionTab header="Fatturati">
							<DataTable className="p-datatable-sm" {...dtParameters}>
								{ colums }
							</DataTable>
						</AccordionTab>
					</Accordion>
				</div>
			</div>
		</>
	);
};


export { OrdersList };
