import { classToPlain, t } from '@deepkit/type';
import { GShopRequest } from '@dto/Requests/GShopRequest';



export class InsertOrderRowRequest {
    @t public ID!: number;
    @t public Articolo!: string;
    @t public Descrizione!: string;
    @t public UM!: string;
    @t public Codice_IVA!: string;
    @t public Quantita_1!: number;
    @t public Quantita_2!: number;
    @t public Quantita_3!: number;
    @t public Coefficient_Multiply!: number;
    @t public Prezzo!: number;
    @t public Sconti!: string;
    @t public SpecialRow!: boolean;
    @t public Notes!: string;
}



export class InsertOrderRequest implements GShopRequest {
    @t public CodiceCliente!: number;
    @t public CodiceDestinatario!: number;

    @t public NoteCliente!: string;
    @t public NoteAgente!: string;

    @t public Deposito!: number;
    @t public EmailCliente!: string;
    @t public SendEmail!: boolean;

    @t.array(InsertOrderRowRequest) public Rows: Array<InsertOrderRowRequest>;


    public constructor() {
        this.Rows = new Array<InsertOrderRowRequest>();
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(InsertOrderRequest, this);

        return js;
    }
}
