import { GetPaymentsResponse } from '@dto/Responses/Payments/GetPaymentsResponse';

import { DataFormatters } from '@guiutility/DataFormatters';
import React from 'react';



/**
 * formatRow coloro TUTTA la riga in base allo stato della scadenza (Insoluto / Legale / Nuova)
 * @param data - model dell'ordine riassunto
 */
export const formatRow = (data: GetPaymentsResponse) => {
	return {
		'row-insoluto': data.stato_pagamento === "I",
	};
};



//#region Lista Scadenziario
/**
 * scadenzaBodyTemplate formattazione della data
 * @param rowData - model GetPaymentsResponse (scadenziario_cliente) della riga
 */
export const scadenzaBodyTemplate = (rowData: GetPaymentsResponse) => {
	return DataFormatters.formatDate(rowData.scadenza);
};



/**
 * dateBodyTemplate formattazione della data del documento
 * @param rowData - model GetPaymentsResponse (scadenziario_cliente) della riga
 */
export const dataBodyTemplate = (rowData: GetPaymentsResponse) => {
	return DataFormatters.formatDate(rowData.data_documento);
};



/**
 * importoBodyTemplate formattazione decimali e valuta del totale scadenza
 * @param rowData - model GetPaymentsResponse (scadenziario_cliente) della riga
 */
export const importoBodyTemplate = (rowData: GetPaymentsResponse) => {
	return DataFormatters.formatPrice(rowData.importo);
};



/**
 * statusTemplate formattazione della flag stato della scadenza
 * @param status - string flag dello stato (I N L P)
 */
export const statusTemplate = (status: string) => {
	if (status == undefined)
		return <span>-</span>

	switch (status.toUpperCase()) {
		case "I":
			return <span className="stato-badge status-insoluto">INSOLUTA</span>

		case "N":
			return <span className="stato-badge status-nuova">NUOVA</span>

		case "L":
			return <span className="stato-badge status-legale">LEGALE</span>

		case "P":
			return <span className="stato-badge status-pagata">PAGATA</span>

		default:
			return <span className="stato-badge">SCONOSCIUTA</span>
	}
};



/**
 * statoBodyTemplate formattazione dello stato della scadenza
 * @param rowData - model GetPaymentsResponse (scadenziario_cliente) della riga
 */
export const statoBodyTemplate = (rowData: GetPaymentsResponse) => {
	return statusTemplate(rowData.stato_pagamento);
};

//#endregion
