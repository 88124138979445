import { t, classToPlain } from '@deepkit/type';
import { GShopRequest } from '@dto/Requests/GShopRequest';



export class GetOrderDetailsRequest implements GShopRequest {
    @t public OrderCode!: string;
    @t public OrderNumber!: number;


    public constructor(orderCode: string, orderNumber: number) {
        this.OrderCode = orderCode;
        this.OrderNumber = orderNumber;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(GetOrderDetailsRequest, this);

        return js;
    }
}
