import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { CustomersService } from '@services/CustomersService';
import { CustomerWithDetails } from '@models/CustomerWithDetails';


const DEFAULT_CUSTOMERS_PER_PAGE = 50;


export type CustomerListRequest = {
	onlyActive: boolean | undefined,
}


export const defaultCustomerListRequest: CustomerListRequest = {
	onlyActive: undefined,
};



/**
 * Hook per la lista dei clienti (attivi e non). Pare tutto un accrocchio, ma è per ottimizzare le query.
 * Attualmente non posso impostare uno state da una prop. Questo vuol dire che non posso fare un <CustomerSelect active={true}> e poi passarlo all'hook perchè potrebbero esserci bug / race condition e altra merda difficile da individuale
 * Sono tutti daccordo sull'inizializzare gli state tramite hooks useEffect(), nel mio caso un useUpdate(, [variabile_che_inizializza])
 * Usando questo sistema però, ho il problema che al primo update ho la props INIZIALIZZATA tramite defaultCustomerListRequest con valore default (onlyActive: FALSE) e viene lanciata una query, poi la reqParams viene aggiornata
 * con il props (useUpdate(, [showOnlyActive]) e viene triggherata nuovamente una richiesta all'endpoint con il nuovo valore (onlyActive: TRUE)
 * Quindi ho implementato tutta sta rozzata a livello di hook + tutta una rozzata a livello di useUpdate() con Immer dove controllo se era null il parametro iniziale e semmai restituisco una nuova draft per ovviare al problema di
 * inutili doppie chiamate
 * @param cr
 * @returns
 */
export const useCustomersList = (cr: CustomerListRequest)=> {
	const log = useLog();

	const customersService = useSingleton<CustomersService>(() => new CustomersService(), []);

	// Lanciola query se ho un valore impostato (false o true sulla flag per vedere i clienti attivi)
	const isEnabled = cr.onlyActive !== undefined;

	const queryInfo = useQuery<CustomerWithDetails[]>({
		queryKey: ['list-customers', cr],
		queryFn: async () => customersService.listCustomersWithRecipients(cr.onlyActive), enabled: isEnabled
	});


	return {
		...queryInfo,
		customers: queryInfo.data ?? new Array<CustomerWithDetails>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
