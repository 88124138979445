import { useAsyncEffect } from 'use-async-effect';


/**
 * useMountAsyncEffect (semplice wrapper di onEffect con dipendenze[] per usarlo solo al mount)
 * @param mountFn - funzione da lanciare al mount del componente
 */
export const useMountAsync = (mountFn: () => Promise<void>) => useAsyncEffect(async () => await mountFn(), []);

//export const useMountAsync = (mountFn: () => Promise<void>) => useEffect(() => {(async () => { await mountFn() })()}, []);

