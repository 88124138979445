import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { OrdersService } from '@services/OrdersService';
import { ListDepositsResponse } from '@dto/Responses/Orders/ListDepositsResponse';



export const useDepositList = () => {
	const log = useLog();

	const ordersService = useSingleton<OrdersService>(() => new OrdersService(), []);

	const queryInfo = useQuery<ListDepositsResponse[]>({
		queryKey: ['list-deposits'],
		queryFn: async () => ordersService.listDeposits()
	});

	return {
		...queryInfo,
		depositList: queryInfo.data ?? new Array<ListDepositsResponse>(),
	}
};
