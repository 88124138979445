import { t } from '@deepkit/type';
import { GShopResponse } from '@dto/Responses/GShopResponse';



export class ListBackOrdersResponse extends GShopResponse {
    @t  public documento!: string;
    @t  public numero!: number;
    @t  public id!: number;
    @t  public data!: string;
    @t  public articolo!: string;
    @t  public descrizione!: string;
    @t  public qta_ordinata!: number;
    @t  public qta_scaricata!: number;
}
