import { useMemo } from 'react';
import useToggleWithRevert from './useToggleWithRevert';


export interface Actions {
	setTrue: () => void;
	setFalse: () => void;
	toggle: (value?: boolean | undefined) => void;
}



/**
 * A hook that elegantly manages boolean values.
 * */
export default function useBoolean(defaultValue = false): [boolean, Actions] {
	const [state, { toggle }] = useToggleWithRevert(defaultValue);

	const actions: Actions = useMemo(() => {
		const setTrue = () => toggle(true);
		const setFalse = () => toggle(false);
		return { toggle, setTrue, setFalse };
	}, [toggle]);

	return [state, actions];
}
