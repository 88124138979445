import { atomWithImmer } from 'jotai/immer'


export interface OrderAnnotazioni {
	NoteAgente: string;
	NoteCliente: string;
}


const defaultAnnotazioni: OrderAnnotazioni = {
	NoteCliente : "",
	NoteAgente : "",
};


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const orderAnnotazioniAtom = atomWithImmer<OrderAnnotazioni>(defaultAnnotazioni);
