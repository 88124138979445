/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { KzLogger } from '@libs/loggers/KzLogger';

import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';

import { BaseHttpEngine, HeaderValue, RequestMethod, RequestParameters } from '@libs/KzHttpEngine/BaseHttpEngine';
import { BaseLogger } from '@libs/loggers/BaseLogger';



export class AxiosHttpEngine extends BaseHttpEngine {
    protected override log: BaseLogger;



    constructor() {
        super();
        this.log = new KzLogger("AxiosHttpEngine");
    }



    protected getRequestMethod(requestMethod: RequestMethod): 'GET' | 'POST' {
        switch (requestMethod) {
            case RequestMethod.GET:
                return 'GET';

            case RequestMethod.POST:
                return 'POST';
        }
    }



    protected createHeaders(rawHeaders: HeaderValue[] | undefined) {
        const headers: Record<string, any> = {};

        if (!rawHeaders)
            return headers;

        //(authToken != "") && { 'Authorization': `Bearer ${authToken}` }
        for (const header of rawHeaders) {
            headers[header.Name] = header.Value;
        }

        return headers;
    }



    protected async executeRequest(requestMethod: RequestMethod, rp: RequestParameters): Promise<any> {
        try {
            const axiosMethod = this.getRequestMethod(requestMethod);

            const config: AxiosRequestConfig = {
                method: axiosMethod,
                headers: this.createHeaders(rp.Headers),
                maxContentLength: 1024 * 1024 * 10,//Infinity,
                url: rp.Url,
                data: rp?.Body,         //  'PUT', 'POST', and 'PATCH' - When no `transformRequest` is set, must be of one of the following types:
                                        // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams - // - Browser only: FormData, File, Blob
                params: rp?.Query,      // the URL parameters to be sent with the request - Must be a plain object or a URLSearchParams object
            };

            this.log.info(`[${axiosMethod}] ${rp.Url}`);

            // Eseguo la richiesta
            const { data } = await axios.request(config);

            // NB: Se il webserver restituisce qualcosa nel range 2xx è accettato. Qualsiasi altro valore differente (3xx 4xx 5xx) rientra nei casi di Exception e van gestiti come richieste fallite (richiesta malformata o problema connessione)
            return data;
        }
        catch (ex: any) {
            await this.processException(ex);
        }
    }



    // eslint-disable-next-line @typescript-eslint/require-await
    protected async processException(ex: Error | AxiosError): Promise<void> {
        const error: AxiosError = ex as AxiosError;
        const reqConfig: AxiosRequestConfig<any> | undefined = error.config;
        const errorResponse: AxiosResponse | undefined = error.response;

        this.log.error(`[${reqConfig?.method?.toUpperCase()}] Error requesting: ${reqConfig?.url}`);

        // Risposta dal webserver (The request was made and the server responded with a status code that falls out of the range of 2xx )
        if (errorResponse) {
            // Errore dato magari da un proxy (tipo quello di Webpack, dove non ho ricevuto una risposta dal mio backend)
            throw new WebException(errorResponse.status, errorResponse.data as string);
        }

        // No Response = network problem
        if (error.request) {    // Non c'è response vuol dire che c'è stato un problema sulla connessione (core down, line down, ecc) The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser
            throw new WebException(WebExceptionErrors.NETWORK_OFFLINE, 'Network problems');
        }

        // No Request e No Response vuol dire che non è proprio partita la richiesta, probabilmente un errore di configurazione
        throw new WebException(WebExceptionErrors.UNKNOWN_ERROR, 'Unknown problem. Maybe some error on config of request');
    }
}
