import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';

import { useLog } from '@hooks/useLog';

import { toastAtom } from '@atoms/ToastAtom';


//export type TOAST_SEVERITY = 'success' | 'info' | 'warn' | 'error'

export enum ToastSeverityEnum {
	SUCCESS = 'success',
	INFO    = 'info',
	WARN    = 'warn',
	ERROR   = 'error'
}



export const useToast = () => {
	const log = useLog();

	const [ toastRef ] = useAtom(toastAtom);

	const showToast = useCallback((level: ToastSeverityEnum, title: string, details: string, timeout = 5000) => {
		toastRef?.show({
			severity: level,
			summary: title,
			detail: details,
			life: timeout,
			contentClassName: ""
		});
		/** Per generarne più di uno alla volta, va passato un array di properties.
		ES: toastRef.show([
			{severity:'info', summary:'Message 1', detail:'Message 1 Content', life: 3000},
			{severity:'info', summary:'Message 2', detail:'Message 2 Content', life: 3000},
			{severity:'info', summary:'Message 3', detail:'Message 3 Content', life: 3000}
		]);
		*/

	}, [toastRef]);


	const clearToast = useCallback(() => {
		toastRef?.clear();

	}, [toastRef]);


	return useMemo(() => ({
		showToast,
		clearToast
	}),[
		showToast,
		clearToast
	]);
}
