import { GetCustomerResponse } from '@dto/Responses/Customers/GetCustomerResponse';
import { Customer } from '@models/Customer';
import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';
import { GetCustomerWithRecipientsDetailsResponse, GetCustomerWithRecipientsResponse } from '@dto/Responses/Customers/GetCustomerWithRecipientsResponse';
import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';



// noinspection DuplicatedCode
export class CustomersMapper {
	public static toCustomerModel(SourceDto: GetCustomerResponse): Customer {
		if (SourceDto == undefined)
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof(SourceDto)} DTO`);

		const c: Customer = new Customer();

		c.codice = SourceDto.codice;
		c.ragione_sociale_1 = SourceDto.ragione_sociale_1;
		c.ragione_sociale_2 = SourceDto.ragione_sociale_2;
		c.nickname = SourceDto.nickname;
		c.indirizzo = SourceDto.indirizzo;
		c.localita = SourceDto.localita;
		c.cap = SourceDto.cap;
		c.provincia = SourceDto.provincia;
		c.stato = SourceDto.stato;
		c.partita_iva = SourceDto.partita_iva;
		c.codice_fiscale = SourceDto.codice_fiscale;
		c.telefoni = SourceDto.telefoni;
		c.telefax = SourceDto.telefax;
		c.cellulari = SourceDto.cellulari;
		c.ufficio = SourceDto.ufficio;
		c.no_split_payment = SourceDto.no_split_payment;
		c.pagamento = SourceDto.pagamento;
		c.descrizione_pagamento = SourceDto.descrizione_pagamento;
		c.agente = SourceDto.agente;
		c.destinatario = SourceDto.destinatario;
		c.spedizioniere = SourceDto.spedizioniere;
		c.deposito_predefinito = SourceDto.deposito_predefinito;
		c.tipo = SourceDto.tipo;
		c.descrizione_tipo = SourceDto.descrizione_tipo;
		c.area = SourceDto.area;
		c.nazione = SourceDto.nazione;
		c.listino = SourceDto.listino;
		c.sconto = SourceDto.sconto;
		c.fido = SourceDto.fido_massimo;
		c.annotazioni = SourceDto.annotazioni;
		c.sito_web = SourceDto.sito_web;
		c.riferimento_e_mail_1 = SourceDto.riferimento_e_mail_1;
		c.indirizzo_e_mail_1 = SourceDto.indirizzo_e_mail_1;
		c.riferimento_e_mail_2 = SourceDto.riferimento_e_mail_2;
		c.indirizzo_e_mail_2 = SourceDto.indirizzo_e_mail_2;
		c.riferimento_e_mail_3 = SourceDto.riferimento_e_mail_3;
		c.indirizzo_e_mail_3 = SourceDto.indirizzo_e_mail_3;
		c.riferimento_e_mail_pec = SourceDto.riferimento_e_mail_pec;
		c.indirizzo_e_mail_pec = SourceDto.indirizzo_e_mail_pec;
		c.pec_fattura_elettronica = SourceDto.pec_fattura_elettronica;

		return c;
	}



	public static toCustomerWithRecipientModel(SourceDto: GetCustomerWithRecipientsResponse): CustomerWithDetails {
		if (SourceDto == undefined)
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof(SourceDto)} DTO`);

		const cwd: CustomerWithDetails = new CustomerWithDetails();

		cwd.codice = SourceDto.codice;
		cwd.ragione_sociale_1 = SourceDto.ragione_sociale_1;
		cwd.ragione_sociale_2 = SourceDto.ragione_sociale_2;
		cwd.nickname = SourceDto.nickname;
		cwd.indirizzo = SourceDto.indirizzo;
		cwd.localita = SourceDto.localita;
		cwd.cap = SourceDto.cap;
		cwd.provincia = SourceDto.provincia;
		cwd.stato = SourceDto.stato;
		cwd.partita_iva = SourceDto.partita_iva;
		cwd.codice_fiscale = SourceDto.codice_fiscale;
		cwd.telefoni = SourceDto.telefoni;
		cwd.telefax = SourceDto.telefax;
		cwd.cellulari = SourceDto.cellulari;
		cwd.ufficio = SourceDto.ufficio;
		cwd.no_split_payment = SourceDto.no_split_payment;
		cwd.pagamento = SourceDto.pagamento;
		cwd.descrizione_pagamento = SourceDto.descrizione_pagamento;
		cwd.agente = SourceDto.agente;
		cwd.destinatario = SourceDto.destinatario;
		cwd.spedizioniere = SourceDto.spedizioniere;
		cwd.deposito_predefinito = SourceDto.deposito_predefinito;
		cwd.tipo = SourceDto.tipo;
		cwd.descrizione_tipo = SourceDto.descrizione_tipo;
		cwd.area = SourceDto.area;
		cwd.nazione = SourceDto.nazione;
		cwd.listino = SourceDto.listino;
		cwd.sconto = SourceDto.sconto;
		cwd.fido = SourceDto.fido_massimo;
		cwd.annotazioni = SourceDto.annotazioni;
		cwd.sito_web = SourceDto.sito_web;
		cwd.riferimento_e_mail_1 = SourceDto.riferimento_e_mail_1;
		cwd.indirizzo_e_mail_1 = SourceDto.indirizzo_e_mail_1;
		cwd.riferimento_e_mail_2 = SourceDto.riferimento_e_mail_2;
		cwd.indirizzo_e_mail_2 = SourceDto.indirizzo_e_mail_2;
		cwd.riferimento_e_mail_3 = SourceDto.riferimento_e_mail_3;
		cwd.indirizzo_e_mail_3 = SourceDto.indirizzo_e_mail_3;
		cwd.riferimento_e_mail_pec = SourceDto.riferimento_e_mail_pec;
		cwd.indirizzo_e_mail_pec = SourceDto.indirizzo_e_mail_pec;
		cwd.pec_fattura_elettronica = SourceDto.pec_fattura_elettronica;

		cwd.recipients = SourceDto.recipients.map(x => this.toRecipientModel(x));

		return cwd;
	}



	public static toRecipientModel(SourceDto: GetCustomerWithRecipientsDetailsResponse): Recipient {
		if (SourceDto == undefined)
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (SourceDto)} DTO`);

		const r: Recipient = new Recipient();

		r.codice = SourceDto.codice;
		r.ragione_sociale_1 = SourceDto.ragione_sociale_1;
		r.ragione_sociale_2 = SourceDto.ragione_sociale_2;
		r.indirizzo = SourceDto.indirizzo;
		r.localita = SourceDto.localita;
		r.cap = SourceDto.cap;
		r.provincia = SourceDto.provincia;
		r.stato = SourceDto.stato;
		r.partita_iva = SourceDto.partita_iva;
		r.codice_fiscale = SourceDto.codice_fiscale;
		r.telefoni = SourceDto.telefoni;
		r.telefax = SourceDto.fax;
		r.cellulari = SourceDto.cellulari;

		return r;
	}
}