import dayjs from 'dayjs';


export class DataFormatters {
    private static numberFormatter = new Intl.NumberFormat("it-IT", {
        style: "decimal",
        useGrouping: false,
    });



    private static percentFormatter = new Intl.NumberFormat("it-IT", {
        style: "decimal",
        maximumFractionDigits: 1,
        useGrouping: false,
    });



    private static priceFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: 'EUR'
    });



    /**
     * formatPrice formatta il prezzo secondo uno standard (EUR)
     * @param price - prezzo da visualizzare
     */
    public static formatPrice(price: number | undefined): string {
        return this.priceFormatter.format(price ?? 0);
    }



    /**
     * formatQuantity formatta la quantità con il numero di decimali voluto
     * @param quantity - quantità da visualizzare
     * @param decimals - decimali da visualizzare
     */
    public static formatQuantity(quantity: number, decimals?: number): string {
        // Se non passo i decimali, uso il formatter statico, più performante in quanto inizializzato solo una volta
        if (decimals == undefined)
            return this.numberFormatter.format(quantity);

        const formatter = new Intl.NumberFormat("it-IT", {
            style: "decimal",
            maximumFractionDigits: decimals,
            useGrouping: false,
        });

        return formatter.format(quantity);
    }



    /**
     * formatPercent formatta la percentuale
     * @param percent - percentuale da visualizzare
     */
    public static formatPercent(percent: number): string {
        return this.percentFormatter.format(percent);
    }



    /**
     * formatDate formattazione di una data dd-mm-yyyy
     * @param date - data visualizzare
     */
    public static formatDate(date: string): string {
        return dayjs(date).format("DD-MM-YYYY");
    }
}