import { ListBackOrdersResponse } from '@dto/Responses/Orders/ListBackOrdersResponse';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { ObjectUtility } from '@libs/utility/ObjectUtility';
import { OrdersService } from '@services/OrdersService';
import { useQuery } from '@tanstack/react-query';



export type BackOrdersRequest = {
	customerID: number | undefined,
}


export const defaultOrderListRequest: BackOrdersRequest = {
	customerID: undefined,
};



export const useBackOrders = (bor: BackOrdersRequest) => {
	const log = useLog();

	const ordersService = useSingleton<OrdersService>(() => new OrdersService(), []);

	const queryInfo = useQuery<ListBackOrdersResponse[]>({
															  enabled : !ObjectUtility.isEmpty(bor.customerID) && (bor.customerID !== 0),
															  queryKey: [ 'get-backorders', bor ],
															  queryFn : async () => await ordersService.getBackOrders(bor.customerID),
														  });

	return {
		...queryInfo,
		backOrders: queryInfo.data,
	};
};
