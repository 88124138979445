//import { atom } from 'jotai';
import { atomWithImmer } from 'jotai/immer'


export type SidebarBadgeContextState = {
	totalCustomers: number;
	totalArticles: number;
}



const defaultSidebarBadgeState: SidebarBadgeContextState = {
	totalCustomers: 0,
	totalArticles: 0
};



// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const sidebarBadgeAtom = atomWithImmer<SidebarBadgeContextState>(defaultSidebarBadgeState);