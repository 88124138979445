import { addLocale, locale } from 'primereact/api';


//#region Traduzione ITA

function initializeLanguages(): void {
	addLocale('it', {
		accept: 'SI',
		reject: 'NO',
		choose: 'Scegli',
		upload: 'Upload',
		cancel: 'Annulla',
		dayNames: ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'],
		dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
		dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
		monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
		monthNamesShort: ['Geb', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
		today: 'Oggi',
		clear: 'Cancella',
		weekHeader: 'Wk',
		firstDayOfWeek: 1,
		dateFormat: 'dd/mm/yy',
		weak: 'Debole',
		medium: 'Medio',
		strong: 'Forte',
		passwordPrompt: 'Inserisci una password',
	});

	locale('it');
}

//#endregion



export function initApp(): void {
	initializeLanguages();
}