import { GetPaymentsListRequest } from '@dto/Requests/Payments/GetPaymentsListRequest';
import { GetPaymentsResponse } from '@dto/Responses/Payments/GetPaymentsResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';

import { PAYMENTS } from '@transport/ApiDefinitions';
import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class PaymentsService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('PaymentsService');
		this.httpEngine = new GShopHttpEngine();
	}



	public async listPayments(customerID?: number, status?: string): Promise<GetPaymentsResponse[]> {
		this.log.debug(`Fetching payments`);

		const paymentsParams: GetPaymentsListRequest = new GetPaymentsListRequest(status);

		const url = PAYMENTS.LIST_PAYMENTS.replace('{customerID}', customerID?.toString() ?? '');

		const responseDTO: GetPaymentsResponse[] = await this.httpEngine.getAsArray<GetPaymentsResponse>(GetPaymentsResponse, url, paymentsParams);

		return responseDTO;
	}
}