import { formatCartRow } from '@desktop/components/orders/DataTableTemplates';
import { ListBackOrdersResponse } from '@dto/Responses/Orders/ListBackOrdersResponse';
import { DataFormatters } from '@guiutility/DataFormatters';
import { BackOrdersRequest, defaultOrderListRequest, useBackOrders } from '@hooks/orders/useBackOrders';
import { useLog } from '@hooks/useLog';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import React, { ReactNode } from 'react';
import { useImmer } from 'use-immer';



type TabBackOrderProps = {
	customerID: number | undefined,
	children?: ReactNode
}



const dataBodyTemplate = (rowData: ListBackOrdersResponse) => {
	return DataFormatters.formatDate(rowData.data);
};



const qtaBodyTemplate = (rowData: ListBackOrdersResponse) => {
	return DataFormatters.formatQuantity(rowData.qta_ordinata - rowData.qta_scaricata, 2);
};



export const TabBackorder: React.FC<TabBackOrderProps> = ({ customerID }: TabBackOrderProps) => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<BackOrdersRequest>(defaultOrderListRequest);
	const { isLoading, backOrders } = useBackOrders(reqParams);


	useUpdate(() => {
		if (isLoading)
			return;

	}, [ isLoading, backOrders ]);



	useUpdate(() => {
		if (customerID === 0)
			return;

		setRequest((draft) => {
			draft.customerID = customerID;
		})
	}, [ customerID ]);




	const dtParameters: DataTableProps<ListBackOrdersResponse[]> = {
		scrollable: true,
		scrollHeight: "750px",
		rowHover: true,
		stripedRows: true,
		paginator: false,

		lazy: false,
		loading: false,
		value: backOrders,

		columnResizeMode: "fit",
		emptyMessage: "Nessun articolo inserito",
		dataKey: "articolo",

		rowClassName: formatCartRow
	}

	return (
		<>
			<h6>Ordini inevasi</h6>
			<DataTable className="p-datatable-sm orders-new-table" {...dtParameters} >
				<Column field="documento" header="Documento" style={{ width: '5%' }} />
				<Column field="numero" header="Numero" style={{ width: '5%', overflow: 'hidden' }} />
				<Column field="data" header="Data" style={{ width: '5%' }} body={dataBodyTemplate} />
				<Column field="articolo" header="Articolo" style={{ width: '10%' }} />
				<Column field="descrizione" header="Descrizione" />
				<Column field="qta" header="Inevaso" style={{ width: '10%' }} body={qtaBodyTemplate} />
			</DataTable>
		</>
	);
};

TabBackorder.whyDidYouRender = true;