import { useLog } from '@hooks/useLog';

import { Recipient } from '@models/Recipient';
import { Column } from 'primereact/column';

import { DataTable, DataTableProps } from 'primereact/datatable';
import React, { ReactNode } from 'react';



type RecipientListProps = {
	recipients: Recipient[] | null,
	children?: ReactNode
}




export const TableRecipients: React.FC<RecipientListProps> = ({ recipients }) => {
	const log = useLog();

	//const { isLoading, recipients, totalRecords, ...queryInfo } = useRecipientsList(null);

	const dtParameters: DataTableProps<Recipient[]> = {
		scrollable: false,
		rowHover: true,
		stripedRows: true,
		paginator: false,

		// loading: isLoading,
		value: recipients ?? new Array<Recipient>(),

		// columnResizeMode: "fit",
		emptyMessage: "Nessun destinatario trovato",
		footer: "",
		dataKey: "codice",
	}


	return (
		<div className="card">
			<h6>Destinatari</h6>
			<DataTable className="p-datatable-sm" {...dtParameters}>
				<Column field="codice" header="Codice" style={{width: '3%'}} />
				<Column field="ragione_sociale_1" header="Ragione Sociale" />
				<Column field="indirizzo" header="indirizzo" style={{width: '30%'}} />
				<Column field="localita" header="Localita" style={{width: '30%'}} />
				<Column field="provincia" header="Provincia" style={{width: '3%'}} />
			</DataTable>
		</div>
	);
};
