import { AuthRepository } from '@core/repositories/AuthRepository';
import { LoginRequest } from '@dto/Requests/Auth/LoginRequest';

import { LoginResponse } from '@dto/Responses/Auth/LoginResponse';
import { ResponseOK } from '@dto/Responses/ResponseOK';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { LoggedUser } from '@models/LoggedUser';

import { AUTHENTICATION } from '@transport/ApiDefinitions';
import { GShopHttpEngine } from '@transport/GShopHttpEngine';
import { jwtDecode } from 'jwt-decode';



export class AuthService {
    private log: BaseLogger;
    private httpEngine: GShopHttpEngine;



    constructor() {
        this.log = new KzLogger("AuthService");
        this.httpEngine = new GShopHttpEngine();
    }



    public async login(username: string, password: string): Promise<boolean> {
        this.log.debug(`Try logging in: ${username}`);

        try {
            const loginParams: LoginRequest = new LoginRequest(username, password);
            const res: LoginResponse = await this.httpEngine.post<LoginResponse>(LoginResponse, AUTHENTICATION.LOGIN, undefined, loginParams);
            
            this.log.info(`User **${res.Username}** is logged in`, res);
            await AuthRepository.saveToken(res.Token);

            const t: string | undefined = await AuthRepository.getToken();
            this.log.info(`Saved token: ${t}`);
        }
        catch (ex) {
            this.log.logException(ex);

            return false;
        }

        return true;
    }



    public async logout(): Promise<boolean> {
        this.log.debug(`Loggin out`);
        
        try {
            await this.httpEngine.get(ResponseOK, AUTHENTICATION.LOGOUT);
        }
        catch (ex) {
            this.log.logException(ex);

            return false;
        }
        finally {
            // Comunque vada, cancello sempre il token nel momento in cui viene fatto un logout
            await AuthRepository.clearToken();
        }

        return true;
    }



    public async getLoggedUser(): Promise<LoggedUser | undefined> {
        let loggedUser: LoggedUser | undefined = undefined;
        const currentToken: string = await AuthRepository.getToken();

        if (currentToken != "") {
            loggedUser = jwtDecode(currentToken);

            this.log.debug('LoggedUser', loggedUser);
        }

        return (loggedUser);
    }
}