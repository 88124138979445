import React, { ReactNode } from 'react';
import { Divider } from 'primereact/divider';
import { Tag } from 'primereact/tag';
import { Order } from '@models/Order';


type CardNotesProps = {
	order?: Order | undefined,
	children?: ReactNode
}



export const CardNotes: React.FC<CardNotesProps> = ({ order, children }: CardNotesProps) => {
	return (
		<>
			<h5>Annotazioni</h5>

			<Divider align="left">
				<Tag className="mr-2" icon="pi pi-user" severity="success" value="Cliente" />

				{order?.annotazioni === "" ? "Nessuna annotazione" : order?.annotazioni}
			</Divider>


			<Divider align="left">
				<Tag className="mr-2" icon="pi pi-user" severity="info" value="Agente" />

				{order?.filler === "" ? "Nessuna annotazione" : order?.filler}
			</Divider>
		</>
	);
};