import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { PaymentsService } from '@services/PaymentsService';
import { GetPaymentsResponse } from '@dto/Responses/Payments/GetPaymentsResponse';


const DEFAULT_PAYMENTS_PER_PAGE = 50;


export type CustomerPaymentRequest = {
	Cliente?: number,
	Stato?: string,
}


export const defaultCustomerPaymentRequest: CustomerPaymentRequest = {};



export const usePaymentsList = (pr: CustomerPaymentRequest) => {
	const log = useLog();

	const paymentsService = useSingleton<PaymentsService>(() => new PaymentsService(), []);

	const queryInfo = useQuery<GetPaymentsResponse[]>({
		queryKey: [ 'list-payments', pr ],
		queryFn: async () => paymentsService.listPayments(pr.Cliente, pr.Stato)
	});

	return {
		...queryInfo,
		payments: queryInfo.data ?? new Array<GetPaymentsResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	};
};
