import React, { ReactNode } from 'react';

//import 'chart.js/auto';       <<-- Senza tree-shaking (non serve Chart.register)
import { Chart as ChartJS, LineController, BarController, CategoryScale, LinearScale, BarElement, LineElement, PointElement, ChartData, ChartOptions, Filler, Legend, Title, Tooltip } from 'chart.js';
//import { Chart } from 'react-chartjs-2';
import { Chart } from 'primereact/chart';

import { List } from 'immutable';

// Da capire se è meglio metterlo in onMount()
ChartJS.register(
	LineController,
	BarController,
	LinearScale,
	CategoryScale,
	BarElement,
	LineElement,
	PointElement,
	Filler,
	Title,
	Tooltip,
	Legend
);


type CardMonthSalesProps = {
	//ordersAmount: number[],
	//ordersCount: number[],
	ordersAmount: List<number>,
	ordersCount: List<number>,
	children?: ReactNode
}


const monthLabels = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];


const lightOptions: ChartOptions = {
	maintainAspectRatio: false,
	aspectRatio: .6,
	plugins: {
		legend: {
			position: 'bottom',
			labels: {
				color: '#495057'
			}
		}
	},
	scales: {
		x: {
			ticks: {
				color: '#495057'
			},
			grid: {
				color: '#ebedef'
			}
		},
		YAmounts: {
			ticks: {
				color: '#495057'
			},
			grid: {
				color: '#ebedef'
			},
			type: 'linear',
			display: true,
			position: 'left',
		},
		YOrders: {
			type: 'linear',
			display: true,
			position: 'right',
			grid: {
				drawOnChartArea: false,
			},
		},
	}
};



export const CardChartMonthSales: React.FC<CardMonthSalesProps> = ({ ordersAmount, ordersCount }: CardMonthSalesProps) => {
	const chartData: ChartData = {
		labels: monthLabels,
		datasets: [
			{
				yAxisID: 'YOrders',
				type: 'line' as const,
				label: '# Ordini',
				borderColor: '#42A5F5',
				borderWidth: 2,
				fill: false,
				tension: .4,
				data: ordersCount.toArray()
			},

			{
				yAxisID: 'YAmounts',
				type: 'bar' as const,
				label: 'Importo ordini',
				backgroundColor: '#66BB6A',
				borderColor: 'white',
				borderWidth: 2,
				data: ordersAmount.toArray()
			},
		]
	};


	return (
		<>
			<h5>Ordinato nell&apos;anno</h5>

			{/* Questa è con react chart normale ma scrolla brutalmente all'infinito. Quale incompatibilità con primereact, magari bisogna metterlo in un wrapper */}

			{/* Con flex risulta bassissimo*/}
			{/*<div className="flex justify-content-center">*/}
			{/*</div>*/}

			{/* Con la class p-chart risulta più basso (150px) rispetto ai 500 usando il componente Chart */}
			{/*<div className="p-chart">*/}
			{/*<Chart data={chartData} options={lightOptions} />*/}
			{/*</div>*/}

			{/* Per non menarmela troppo, uso quello di primereact, che è responsive. Non ho indagato su come mai è responsive, visto che la classe è uguale. */}
			<Chart type='bar' data={chartData} options={lightOptions} />
		</>
	);
};
