import { GetDashboardCustomerInfo } from '@dto/Responses/Dashboard/GetDashboardResponse';
import { DataFormatters } from '@guiutility/DataFormatters';
import { List } from 'immutable';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import React, { ReactNode } from 'react';



type TableCustomersRankProps = {
	customers?: List<GetDashboardCustomerInfo>,
	children?: ReactNode
}



const totalAmountBodyTemplate = (rowData: GetDashboardCustomerInfo) => {
	return DataFormatters.formatPrice(rowData.valore_ordini);
};




export const TableCustomersRank: React.FC<TableCustomersRankProps> = ({ customers }: TableCustomersRankProps) => {
	const dtParameters: DataTableProps<GetDashboardCustomerInfo[]> = {
		rowHover: false,
		stripedRows: true,
		paginator: false,

		lazy: false,
		loading: false,
		rows: 10,
		value: customers?.toArray(),

		// columnResizeMode: "fit",
		emptyMessage: 'Nessuno',
		footer: '',
		dataKey: 'codice_cliente',
	};


	const dtColumns: ColumnProps[] = [
		{ field: 'nominativo_cliente', header: 'Cliente', style: { width: '75%' } },
		{ field: 'numero_ordini', header: '# ordini', style: { width: '10%' } },
		{ field: 'valore_ordini', header: 'Importo Totale', body: totalAmountBodyTemplate, className: 'text-right', style: { width: '15%' } },
	];


	const colums = dtColumns.map((col, i) => {
		return <Column key={col.field} {...col} />;
	});



	return (
		<DataTable className="p-datatable-sm" {...dtParameters}>
			{ colums }
		</DataTable>
	);
};