import React, { ReactNode } from 'react';
import { useLog } from '@hooks/useLog';
import { useMount } from '@libs/KzHooks/useMount';
import { useUnmount } from '@libs/KzHooks/useUnmount';



type GlobalErrorsHandlerProps = {
	children: ReactNode
}



export const GlobalErrorsHandler: React.FC<GlobalErrorsHandlerProps> = ({children}: GlobalErrorsHandlerProps) => {
	const log = useLog();


	useMount(() => {
		// eslint-disable-next-line unicorn/prefer-add-event-listener
		window.onerror = onError;
		window.onunhandledrejection = onUnhandledException;
	});



	useUnmount(() => {
		// eslint-disable-next-line unicorn/prefer-add-event-listener, unicorn/no-null
		window.onerror = null;
		// eslint-disable-next-line unicorn/prefer-add-event-listener, unicorn/no-null
		window.onunhandledrejection = null;
	});



	const onError = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {   // OnErrorEventHandler
		const extra = colno ? ('\ncolumn: ' + colno  + !error ? '' : '\nerror: ' + error) : '';

		//StackTrace.fromError(error).then(callback).catch(errback);

		// eslint-disable-next-line @typescript-eslint/no-base-to-string
		log.warn(event + "\nurl: " + source + "\nline: " + lineno + extra);

		if (event instanceof Event)
			event.preventDefault();

		const suppressErrorAlert = false; // If you return true, then error alerts (like in older versions of Internet Explorer) will be suppressed.

		return suppressErrorAlert;
	};



	const onUnhandledException = (e: PromiseRejectionEvent) => {
		log.warn(e);

		e.preventDefault();

		throw new Error(e.reason.stack);
	};



	return (
		<>
			{children}
		</>
	)
}