import React, { ReactNode } from 'react';
import { Divider } from 'primereact/divider';
import { Order } from '@models/Order';
import { DataFormatters } from '@guiutility/DataFormatters';


type CardTotalsProps = {
	order: Order | undefined,

	children?: ReactNode
}



type InfoTotalsProps = {
	totaleImponibile: number | undefined,
	aliquota?: string | undefined,
	totaleImposta: number | undefined,

	children?: ReactNode
}



/**
 * Riga descrittiva del totale imponibile - aliquota - totale.
 * TODO: In futuro mi piacerebbe visualizzarlo come tabella, ma al momento il 99% dei clienti che lo userà avrà solo una SINGOLA aliquota, quindi inutile metterci una tabellina ora come ora
 * @param totaleImponibile
 * @param aliquota
 * @param totaleImposta
 * @returns {JSX.Element}
 * @constructor
 */
const InfoTotal: React.FC<InfoTotalsProps> = ({ totaleImponibile, aliquota, totaleImposta }) => {
	if ((totaleImponibile == undefined || totaleImponibile == 0) && (totaleImposta == undefined || totaleImposta == 0)) {
		return (<></>);
	}

	const totale = (totaleImponibile ?? 0) + (totaleImposta ?? 0);

	return (
		<>
			{/*<div className="grid">*/}
			{/*	<div className="col-fixed" style={{ "width" : "100px" }}>Imponibile</div>*/}
			{/*	<div className="col">{DataFormatters.formatPrice(totaleImponibile)}</div>*/}

			{/*	<div className="col-fixed" style={{ "width" : "100px" }}>Aliquota</div>*/}
			{/*	<div className="col">{aliquota}</div>*/}

			{/*	<div className="col-fixed" style={{ "width" : "100px" }}>Imposta</div>*/}
			{/*	<div className="col">{DataFormatters.formatPrice(totaleImposta)}</div>*/}

			{/*	<div className="col-fixed" style={{ "width" : "100px" }}>Totale</div>*/}
			{/*	<div className="col">{DataFormatters.formatPrice(totale)}</div>*/}
			{/*</div>*/}

			<div className="grid">
				<div className="col-3">
					<span className="font-bold mr-2">Imponibile</span>
					<span>{DataFormatters.formatPrice(totaleImponibile)}</span>
				</div>

				<div className="col-3">
					<span className="font-bold mr-2">{aliquota == undefined ? '' : 'Aliquota'}</span>
					<span>{aliquota}</span>
				</div>

				<div className="col-3">
					<span className="font-bold mr-2">Imposta</span>
					<span>{DataFormatters.formatPrice(totaleImposta)}</span>
				</div>

				<div className="col-3">
					<span className="font-bold mr-2">Totale</span>
					<span>{DataFormatters.formatPrice(totale)}</span>
				</div>
			</div>
		</>
	);
};



/**
 * Mostra il totale dei vari imponibili
 * @param order
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const BigTotals: React.FC<CardTotalsProps> = ({ order }) => {
	if (order?.imponibile_2 == 0 && order?.imponibile_3 == 0 && order?.imponibile_4 == 0 && order?.imponibile_5 == 0 && order?.imponibile_6 == 0)
		return (
			<></>
		)

	return (
		<>
			<Divider align="left" />

			<InfoTotal totaleImponibile={order?.totale_imponibile} totaleImposta={order?.totale_imposta} />
		</>
	)
}



/**
 * Visualizzo i totali delle aliquote (mi gusterebbe aggiustare il model convertendo aliquota 1,2,3,... in una lista di aliquote.
 * Vengono mostrate le varie aliquote, e qualora siano più di una, viene mostrato una riga con i totali di esse.
 * @param order Ordine
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const CardTotals: React.FC<CardTotalsProps> = ({ order }: CardTotalsProps) => {
	return (
		<>
			<h5>Totali</h5>
				<InfoTotal totaleImponibile={order?.imponibile_1} totaleImposta={order?.imposta_1} aliquota={order?.codice_iva_1} />
				<InfoTotal totaleImponibile={order?.imponibile_2} totaleImposta={order?.imposta_2} aliquota={order?.codice_iva_2} />
				<InfoTotal totaleImponibile={order?.imponibile_3} totaleImposta={order?.imposta_3} aliquota={order?.codice_iva_3} />

				<BigTotals order={order}/>
		</>
	);
};