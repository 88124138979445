import { useEffect } from 'react';


function isFunction(obj: any): obj is CallableFunction {
	return typeof obj === 'function';
}


/**
 * Run effect only when component is unmounted.
 *
 * @param effect Effector to run on unmount
 */
export function useUnmount(effect: CallableFunction): void {
	useEffect(
		() => () => {
			effect();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
}




/*
export const useUnmount2 = (fn: any) => {
	const fnPersist = usePersistFn(fn);

	useEffect(
		() => () => {
			if (isFunction(fnPersist)) {
				fnPersist();
			}
		},
		[],
	);
};
*/



/*
export const useUnmount = (fn: () => any): void => {
	const fnRef = useRef(fn);

	// update the ref each render so if it change the newest callback will be invoked
	fnRef.current = fn;

	useEffectOnce(() => () => fnRef.current());
};
*/