/* eslint-disable @typescript-eslint/unbound-method */
import { GetOrdersHeadersResponse } from '@dto/Responses/Orders/GetOrdersHeadersResponse';
import { ListBackOrdersResponse } from '@dto/Responses/Orders/ListBackOrdersResponse';

import { DataFormatters } from '@guiutility/DataFormatters';

import { DiscountsUtility } from '@guiutility/DiscountsUtility';

import { CartRow } from '@models/Cart';
import { OrderRow } from '@models/Order';
import { Tag } from 'primereact/tag';
import React from 'react';
import { Link } from 'react-router-dom';

import './DataTableTemplates.scss';



/**
 * formatRow coloro TUTTA la riga in base a quanti articoli ordinati
 * @param data - model dell'ordine riassunto
 */
export const formatRow = (data: OrderRow | GetOrdersHeadersResponse) => {
	if (data instanceof GetOrdersHeadersResponse)
		return {
			'nostock-row': data.articoli_ordinati <= 0,
		};


	return {
		'nostock-row': data.quantita_3 <= 0,
	};
};



/**
 * formatCartRow stile di TUTTA la riga
 * @param data - model della riga del carrello
 * @returns {{'special-row': boolean, 'discounted-row': boolean} | {'special-row': boolean}}
 */
export const formatCartRow = (data: CartRow | ListBackOrdersResponse) => {
	if (data instanceof CartRow)
		return {
			'special-row': data.SpecialRow,
			'discounted-row': data.Discount != data.CustomDiscount,
		};

	return { 'special-row': false };
};



//#region Lista Ordini
/**
 * dateBodyTemplate formattazione della data
 * @param rowData - model OrderHeaderInfo (riassuntivo dell'ordine) della riga
 */
export const dateBodyTemplate = (rowData: GetOrdersHeadersResponse) => {
	return DataFormatters.formatDate(rowData.data);
};



/**
 * priceTotaleBodyTemplate formattazione decimali e valuta del prezzo
 * @param rowData - model OrderHeaderInfo della riga
 */
export const priceTotaleBodyTemplate = (rowData: GetOrdersHeadersResponse) => {
	return DataFormatters.formatPrice(rowData.totale_da_pagare);
};



/**
 * priceTotaleIVABodyTemplate formattazione decimali e valuta del prezzo
 * @param rowData - model OrderHeaderInfo della riga
 */
export const priceTotaleIVABodyTemplate = (rowData: GetOrdersHeadersResponse) => {
	return DataFormatters.formatPrice(rowData.totale_imposta);
};



/**
 * priceTotaleImponibileBodyTemplate formattazione decimali e valuta del prezzo
 * @param rowData - model OrderHeaderInfo della riga
 */
export const priceTotaleImponibileBodyTemplate = (rowData: GetOrdersHeadersResponse) => {
	return DataFormatters.formatPrice(rowData.totale_imponibile);
};





/**
 * ordineIDBodyTemplate formattazione dell'ID dell'ordine come link
 * @param rowData - model OrderHeaderInfo della riga
 */
export const ordineIDBodyTemplate = (rowData: GetOrdersHeadersResponse) => {
	return <Link to={`/orders/${rowData.documento}/${rowData.numero}`}>{rowData.documento + rowData.numero}</Link>;
};


//#endregion



//#region Carrello Ordine
/**
 * priceTotalAmountBodyTemplate formattazione decimali e valuta dell'importo totale riga
 * @param rowData - model CartRow della riga del carrello/ordine
 */
export const priceTotalAmountBodyTemplate = (rowData: CartRow) => {
	return DataFormatters.formatPrice(rowData.TotalAmount);
};



/**
 * priceBodyTemplate formattazione decimali e valuta del prezzo
 * @param rowData - model CartRow della riga del carrello/ordine
 */
export const priceBodyTemplate = (rowData: CartRow) => {
	//#region Prezzo personalizzato dall'AGENTE

	// Caso 1: Cliente con prezzo da AGENTE => mostro prezzo originale + prezzo impostato da agente (evito tutto lo sbattimento degli sconti)
	if (rowData.Price !== rowData.OriginalPrice)
		return (
			<span>
				<span className="oldprice">
					{ DataFormatters.formatPrice(rowData.OriginalPrice) }
				</span>

				<span className="discountedprice">
					{ DataFormatters.formatPrice(rowData.Price) }
				</span>
			</span>
		);

	//#endregion


	//#region Sconto personalizzato o sconto aggiuntivo allo sconto esistente

	// Caso 2: Cliente con scontistiche (listini_clienti_corpo), no prezzi personalizzati, no sconti aggiuntivi => mostro prezzo listino + prezzo con sconto (listini_clienti_corpo)
	//  NO Prezzo personalizzato (listini_clienti_corpo)
	//  SI Sconto personalizzato (listini_clienti_corpo)
	//  NO Sconto agente (ovvero lo sconto è uguale a quello custom)
	if (rowData.CustomPrice === 0 && rowData.CustomDiscount !== "" && rowData.CustomDiscount === rowData.Discount)
		return (
			<span>
				<span className="oldprice">
					{ DataFormatters.formatPrice(rowData.OriginalPrice) }
				</span>

				<span className="discountedprice">
					{ DataFormatters.formatPrice(rowData.DiscountedPrice) }
				</span>
			</span>
		);


	// Caso 3: Cliente con scontistiche (listini_clienti_corpo), no prezzi personalizzati, e sconto extra agente => mostro prezzo listino + prezzo con sconto (listini_clienti_corpo) + prezzo con sconto aggiuntivo agente
	//  NO Prezzo personalizzato (listini_clienti_corpo)
	//  SI Sconto personalizzato (listini_clienti_corpo)
	//  SI Sconto agente
	if (rowData.CustomPrice === 0 && rowData.CustomDiscount !== "" && rowData.CustomDiscount !== rowData.Discount)
		return (
			<span>
				<span>
					{ DataFormatters.formatPrice(rowData.OriginalPrice) }
				</span>

				<span className="intermediateprice">
					{ DataFormatters.formatPrice(rowData.DiscountedPrice) }
				</span>

				<span className="discountedprice">
					{ DataFormatters.formatPrice(DiscountsUtility.calculateDiscount(rowData.Price, rowData.Discount)) }
				</span>
			</span>
		);

	//#endregion


	//#region Prezzo personalizzato (Listini_Clienti_Corpo) + Prezzo personalizzato con sconto (Listini_Clienti_Corpo + Sconto Agente)

	// Caso 4: Cliente (prezzo listino_clienti_corpo) => mostro il prezzo di listino + il prezzo a lui riservato
	//  SI Prezzo personalizzato (Listini_Clienti_Corpo)
	//  NO Sconto personalizzato (Listini_Clienti_Corpo)
	//  NO Sconto agente
	if (rowData.CustomPrice !== 0 && rowData.Discount === "")
		return (
			<span>
				<span>
					{ DataFormatters.formatPrice(rowData.OriginalPrice) }
				</span>

				<span className="discountedprice">
					{ DataFormatters.formatPrice(rowData.CustomPrice) }
				</span>
			</span>
		);


	// Caso 5: Cliente (Prezzo listino_clienti_corpo + sconto agente) => mostro prezzo listino + prezzo a lui riservato + sconto aggiuntivo
	//  SI Prezzo personalizzato (Listini_Clienti_Corpo)
	//  NO Sconto personalizzato (Listini_Clienti_Corpo)
	//  SI Sconto agente (Agente)
	if (rowData.CustomPrice !== 0 && rowData.Discount !== "")
		return (
			<span>
				<span>
					{ DataFormatters.formatPrice(rowData.OriginalPrice) }
				</span>

				<span className="intermediateprice">
					{ DataFormatters.formatPrice(rowData.CustomPrice) }
				</span>

				<span className="discountedprice">
					{ DataFormatters.formatPrice(DiscountsUtility.calculateDiscount(rowData.CustomPrice, rowData.Discount)) }
				</span>
			</span>
		);

	//#endregion


	//#region NO sconti/personalizzati SOLO Listino + sconto agente

	// Caso 6: Cliente con sconto da AGENTE (prezzo listino + sconto agente) => mostro prezzo listino + prezzo scontato dall'agente a lui
	//  NO Prezzo personalizzato (Listini_Clienti_Corpo)
	//  NO Sconto personalizzato (Listini_Clienti_Corpo)
	//  SI Sconto agente (ovvero lo sconto è uguale a quello custom)
	if (rowData.CustomPrice === 0 && rowData.CustomDiscount === "" && rowData.Discount !== "")
		return (
			<span>
				<span className="oldprice">
					{ DataFormatters.formatPrice(rowData.OriginalPrice) }
				</span>

				<span className="discountedprice">
					{ DataFormatters.formatPrice(DiscountsUtility.calculateDiscount(rowData.Price, rowData.Discount)) }
				</span>
			</span>
		);

	//#endregion


	// Prezzo ORIGINALE LISTINO e basta (le casistiche sullo sconto sono gestite sopra)
	return (
		<span>
			{ DataFormatters.formatPrice(rowData.OriginalPrice) }
		</span>
	);
};



/**
 * discountBodyTemplate sconto
 * @param rowData - model CartRow della riga del carrello/ordine
 */
export const discountBodyTemplate = (rowData: CartRow) => {
	const discount = (rowData.Discount === "") ? rowData.CustomDiscount : rowData.Discount;

	return (
		<span>
			{ discount !== "" && <Tag rounded severity="success" value={discount + " %"} /> }
		</span>
	)
}




/**
 * quantityBodyTemplate formattazione decimali per la quantità
 * @param rowData - model CartRow della riga del carrello/ordine
 */
export const quantityBodyTemplate = (rowData: CartRow) => {
	return DataFormatters.formatQuantity(rowData.Quantity);
};

//#endregion



//#region Dettaglio Ordine
/**
 * quantity1OrderDetailsBodyTemplate formattazione decimali della quantita 1
 * @param rowData - model OrderRow della riga dell'ordine
 */
export const quantity1OrderDetailsBodyTemplate = (rowData: OrderRow) => {
	return DataFormatters.formatQuantity(rowData.quantita_1);
};



/**
 * quantity2OrderDetailsBodyTemplate formattazione decimali della quantita 2
 * @param rowData - model OrderRow della riga dell'ordine
 */
export const quantity2OrderDetailsBodyTemplate = (rowData: OrderRow) => {
	return DataFormatters.formatQuantity(rowData.quantita_2);
};



/**
 * quantity3OrderDetailsBodyTemplate formattazione decimali della quantita 3
 * @param rowData - model OrderRow della riga dell'ordine
 */
export const quantity3OrderDetailsBodyTemplate = (rowData: OrderRow) => {
	return DataFormatters.formatQuantity(rowData.quantita_3);
};



/**
 * priceOrderDetails formattazione decimali del prezzo sulla riga
 * @param rowData - model OrderRow della riga dell'ordine
 */
export const priceOrderDetailsBodyTemplate = (rowData: OrderRow) => {
	return DataFormatters.formatPrice(rowData.prezzo);
};



/**
 * totalAmountOrderDetailsBodyTemplate formattazione decimali per l'importo della riga
 * @param rowData - model OrderRow della riga dell'ordine
 */
export const totalAmountOrderDetailsBodyTemplate = (rowData: OrderRow) => {
	return DataFormatters.formatPrice(rowData.importo);
};

//#endregion
