import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';
import { Recipient } from '@models/Recipient';
import { GetRecipientResponse } from '@dto/Responses/Recipients/GetRecipientResponse';



export class RecipientsMapper {
	public static toRecipientModel(SourceDto: GetRecipientResponse): Recipient {
		if (SourceDto == undefined)
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (SourceDto)} DTO`);

		const r: Recipient = new Recipient();

		r.codice = SourceDto.codice;
		r.ragione_sociale_1 = SourceDto.ragione_sociale_1;
		r.ragione_sociale_2 = SourceDto.ragione_sociale_2;
		r.indirizzo = SourceDto.indirizzo;
		r.localita = SourceDto.localita;
		r.cap = SourceDto.cap;
		r.provincia = SourceDto.provincia;
		r.stato = SourceDto.stato;
		r.partita_iva = SourceDto.partita_iva;
		r.codice_fiscale = SourceDto.codice_fiscale;
		r.telefoni = SourceDto.telefoni;
		r.telefax = SourceDto.telefax;
		r.cellulari = SourceDto.cellulari;

		return r;
	}
}