import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { useFirstMount } from '@libs/KzHooks/useFirstMount';


export const noop = (): void => { /**/};



/**
 * A custom useEffect hook that only triggers on updates, not on initial mount / first render
 * @param effect Effector to run on updates
 * @param deps Dependencies list, as for `useEffect` hook
 */
export const useUpdateButSkipMount: typeof useEffect = (effect, deps) => {
	const isMounted = useRef(false);

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true;
		}
		else {
			return effect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};



/**
 * Effect hook that ignores the first render (not invoked on mount).
 *
 * @param effect Effector to run on updates
 * @param deps Dependencies list, as for `useEffect` hook
 */
export function useUpdateButSkipMount2(effect: EffectCallback, deps?: DependencyList): void {
	const isFirstMount = useFirstMount();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(isFirstMount ? noop : effect, deps);
}