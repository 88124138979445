import { paginatorTemplate } from '@desktop/components/commons/DataTablePaginator';

import { formatRow, stockBodyTemplate } from '@desktop/components/products/DataTableTemplates';
import { GalleryProducts } from '@desktop/components/products/GalleryProducts';
import { GetArticleResponse } from '@dto/Responses/Articles/GetArticleResponse';
import { DataFormatters } from '@guiutility/DataFormatters';

import { useLog } from '@hooks/useLog';
import { useSettings } from '@hooks/useSettings';
import { orderSelectedArticlesAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderSelectedArticlesAtom';
import { useMount } from '@libs/KzHooks/useMount';
import { useAtom } from 'jotai';

import { Column } from 'primereact/column';
import { DataTable, DataTableProps, DataTableStateEvent } from 'primereact/datatable';
import { Galleria } from 'primereact/galleria';
import React, { ReactNode, useRef, useState } from 'react';

import './TableProducts.scss';



type TableProductsProps = {
	products: any[],
	pageSize: number,
	isLoading: boolean,
	totalRecords: number,
	onPageChangedHandler: (currentPage: number, rowsPerPage: number) => void,
	children?: ReactNode
}



/**
 * priceBodyTemplate formattazione decimali e valuta del prezzo
 * @param rowData - model articolo della riga
 * @param useMultiplyCoefficient - se c'è il coefficiente moltiplicativo, il prezzo è dato dal suo moltiplicatore
 */
const priceBodyTemplate = (rowData: GetArticleResponse, useMultiplyCoefficient: boolean) => {
	if (useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0)
		return DataFormatters.formatPrice(rowData.listino_1 * rowData.coefficente_moltiplicativo);

	return DataFormatters.formatPrice(rowData.listino_1);
};



export const TableProducts: React.FC<TableProductsProps> = ({ products, pageSize, isLoading, totalRecords, onPageChangedHandler }: TableProductsProps) => {
	const log = useLog();

	const { settingsState } = useSettings();

	const [ firstRowDisplayed, setFirst ] = useState<number>(0);
	const [ lastSelectedArticle, setLastSelectedArticle ] = useState<string>("");
	const [ selectedProducts, setSelectedProducts ] = useAtom(orderSelectedArticlesAtom);

	const refGallery = useRef<Galleria>(null);



	useMount(() => {
		log.info('Loading TableProducts...');
	});



	const onPaginator = (e: DataTableStateEvent): void => {
		onPageChangedHandler(e.page ?? 1, e.rows);

		setFirst(e.first);
	};



	/**
	 * Apro popup per la selezione di una foto
	 * @param e
	 * @param rowData
	 */
	const onPhotoRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, rowData: GetArticleResponse) => {
		log.info(rowData);

		setLastSelectedArticle(rowData.articolo);
	};



	const onViewPhotoRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, rowData: GetArticleResponse) => {
		setLastSelectedArticle(rowData.articolo);

		refGallery.current?.show();
	};



	/**
	 * photoBodyTemplate mostro un popup per l'aggiunta di una foto
	 * @param rowData - model articolo della riga
	 */
	const photoBodyTemplate = (rowData: GetArticleResponse) => {
		if (rowData.photoscount > 0)
			return <i className="pi pi-images" style={ { textAlign: 'center', color: 'var(--cyan-600)', fontWeight: 'bold' } } onClick={ (e) => onViewPhotoRowClick(e, rowData) } />;

		return <i className="pi pi-camera" style={ { textAlign: 'center', color: 'var(--bluegray-500)', fontWeight: 'bold' } } onClick={ (e) => onPhotoRowClick(e, rowData) } />;
	};



	const dtParameters: DataTableProps<any[]> = {
		scrollable       : true,
		scrollHeight     : '750px',
		rowHover         : true,
		stripedRows      : true,
		paginator        : true,
		paginatorTemplate: paginatorTemplate,

		lazy        : true,
		loading     : isLoading,
		totalRecords: totalRecords,         // Attenzione che il pager fa triggerare onPage se totalRecords è INFERIORE al rows (righe per pagina)
		rows        : pageSize,
		value       : products,

		first : firstRowDisplayed,           // In base al numero del record, il paginator segna in quale pagina è attualmente posizionato  1 2 > 3 < 4 5 6
		onPage: onPaginator,

		columnResizeMode: 'fit',
		emptyMessage    : 'Nessun articolo inserito',
		footer          : '',
		dataKey         : 'articolo',
		rowClassName    : formatRow,
	};



	return (
		<>
			<DataTable className="editable-cells-table p-datatable-sm" { ...dtParameters }>
				<Column field="photo" header="" style={ { width: '1%' } } body={ photoBodyTemplate } />
				<Column field="articolo" header="Cod.Art." style={ { width: '8%' } } sortable />
				<Column field="descrizione" header="Descrizione" style={ { width: '25%' } } sortable />

				<Column field="descrizione_categoria" header="Categoria" style={ { width: '10%' } } />
				{/*<Column field="descrizione_sottocategoria" header="Sottocategoria" style={{width:'10%'}} />*/ }
				{/*<Column field="descrizione_sottogruppo" header="Sottogruppo" style={{width:'10%'}} />*/ }

				{/*<Column field="pezzi_per_confezione" header="Pz Conf." style={{width:'3%'}} />*/ }
				{/*<Column field="caricato_tot" header="Qta Caricato" style={{width:'3%'}} />*/ }
				{/*<Column field="scaricato_tot" header="Qta Scaricato" style={{width:'3%'}} />*/ }

				<Column field="unita_di_misura" header="UM" style={ { width: '2%' } } />

				<Column field="esistente_tot" header="Qta Esistente" style={ { width: '4%' } } body={ stockBodyTemplate } />
				<Column field="listino_1" header="Pr. Listino" style={ { width: '4%' } } body={ (rowData) => priceBodyTemplate(rowData, settingsState.useMultiplyCoefficient) } />
			</DataTable>

			<GalleryProducts ref={refGallery} lastSelectedArticle={lastSelectedArticle} />
		</>
	);
};
