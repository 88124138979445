import { GShopRequest } from '@dto/Requests/GShopRequest';
import { t, classToPlain } from '@deepkit/type';




export class GetPaymentsListRequest implements GShopRequest {
    @t  public Stato?: string;



    public constructor(stato?: string) {
        this.Stato = stato;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(GetPaymentsListRequest, this);

        return js;
    }

}
