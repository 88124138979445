import { dataBodyTemplate, formatRow, importoBodyTemplate, scadenzaBodyTemplate, statoBodyTemplate, statusTemplate } from '@desktop/components/payments/DataTableTemplates';
import { GetPaymentsResponse } from '@dto/Responses/Payments/GetPaymentsResponse';

import { CustomerPaymentRequest, defaultCustomerPaymentRequest, usePaymentsList } from '@hooks/payments/usePaymentsList';

import { useLog } from '@hooks/useLog';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';

import { DataTable, DataTableProps } from 'primereact/datatable';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React, { useRef } from 'react';
import { useImmer } from 'use-immer';

import './PaymentsList.scss';



const statuses = [
	'I',
	'N',
	'L',
	'P'
];


const statusValueTemplate = (flag: string, props: any) => {
	if (flag == undefined)
		return <span>Filtra uno stato</span>;

	return statusTemplate(flag);
}


const PaymentsList: React.FC = () => {
	const log = useLog();

	const dt = useRef<DataTable<GetPaymentsResponse[]>>(null);

	const [ reqParams, setRequest ] = useImmer<CustomerPaymentRequest>(defaultCustomerPaymentRequest);
	const { isLoading, payments } = usePaymentsList(reqParams);

	const [ selectedStatus, setSelectedStatus ] = useImmer<string | undefined>(undefined);
	const [ globalFilter, setGlobalFilter ] = useImmer<string>("");


	const onStatusFilterChange = (e: DropdownChangeEvent) => {
		dt.current?.filter(e.value, 'stato_pagamento', 'equals');

		setSelectedStatus(e.value);
	}


	const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusFilterChange} itemTemplate={statusTemplate} valueTemplate={statusValueTemplate} placeholder="Filtra uno stato" className="p-column-filter" showClear />;


	const dtParameters: DataTableProps<GetPaymentsResponse[]> = {
		rowHover: true,
		stripedRows: true,
		paginator: true,

		lazy: false,
		loading: false,
		rows: 100,
		value: payments,

		// columnResizeMode: "fit",
		emptyMessage: "Nessuna scadenza trovata",
		footer: "",
		globalFilter: globalFilter,

		rowClassName: formatRow,
	}


	return (
		<>
			<div className="content-section introduction">
				<h1>Scadenziario clienti</h1>
				<p>Qui puoi analizzare lo stato contabile dei clienti</p>
			</div>

			<div className="content-section implementation">
				<div className="card">
					<DataTable ref={dt} className="p-datatable-sm" {...dtParameters}>
						<Column field="stato_pagamento" header="Stato" sortable filter body={statoBodyTemplate} style={{width: '10%'}} filterElement={statusFilter}/>
						<Column field="ragione_sociale_1" header="Ragione Sociale" sortable filter filterPlaceholder="Cerca un cliente" filterMatchMode={FilterMatchMode.CONTAINS} />
						<Column field="descrizione_pagamento" header="Pagamento" style={{width: '15%'}} />
						<Column field="scadenza" header="Scadenza" sortable body={scadenzaBodyTemplate} style={{width: '7%'}} />
						<Column field="data_documento" header="Data Fattura" sortable body={dataBodyTemplate} style={{width: '7%'}} />
						<Column field="importo" header="Importo" sortable body={importoBodyTemplate} style={{width: '7%'}} />
					</DataTable>
				</div>
			</div>
		</>
	);
};


export { PaymentsList };
