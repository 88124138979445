/// <reference types="@welldone-software/why-did-you-render" />
import './wdyr';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import React from 'react';

import 'reflect-metadata';
import { createRoot } from 'react-dom/client';

import App from './gui/App';

import '@desktop/styles/normalize.scss';
import '@desktop/styles/primereact.scss';



// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const mountPoint = document.querySelector('#root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(mountPoint!);

const useStrictMode = false;


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			staleTime: 0, // Infinity,
			refetchInterval: false,
			refetchIntervalInBackground: false,
		},
	},
});


// Produzione
if (import.meta.env.PROD) {
	root.render(
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>);
}


// DEV + NO Strict
if (import.meta.env.DEV && !useStrictMode) {
	root.render(
		<QueryClientProvider client={queryClient}>
			<App />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>);
}


// DEV + STRICT!!! (refresh 2x times)
if (import.meta.env.DEV && useStrictMode) {
	root.render(
		<React.StrictMode>
			<QueryClientProvider client={queryClient}>
				<App />
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</React.StrictMode>);
}
