/* eslint-disable @typescript-eslint/unbound-method */

import classNames from 'classnames';
import { GetArticleResponse } from '@dto/Responses/Articles/GetArticleResponse';
import React from 'react';
import { DataFormatters } from '@guiutility/DataFormatters';



/**
 * formatRow coloro TUTTA la riga in base all'esistenza articolo
 * @param data - model dell'articolo
 */
export const formatRow = (data: GetArticleResponse) => {
	return {
		'nostock-row': data.esistente_tot <= 0,
	};
}



/**
 * stockBodyTemplate coloro la cella in base all'esistenza articolo
 * @param rowData - model articolo della riga
 */
export const stockBodyTemplate = (rowData: GetArticleResponse) => {
	const stockClassesColors = classNames({
		'outofstock': rowData.esistente_tot <= 0,
		'lowstock': rowData.esistente_tot > 0 && rowData.esistente_tot < 10,
		'instock': rowData.esistente_tot > 10
	});

	return (
		<div className={stockClassesColors}>
			{DataFormatters.formatQuantity(rowData.esistente_tot)}
		</div>
	);
}