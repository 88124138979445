import { ObjectUtility } from '@libs/utility/ObjectUtility';
import StackTrace, { StackFrame, StackTraceOptions } from 'stacktrace-js';



export enum LogLevelEnum {
	TRACE = 'trace',
	DEBUG = 'debug',
	INFO = 'info',
	WARN = 'warn',
	ERROR = 'error',
	FATAL = 'fatal'
}


export type MODE = 'native' | 'browser';



export class LogMessage {
	public Level: LogLevelEnum;
	public Callee: StackFrame | undefined;
	public Messages: string[];
	public Objects?: unknown[];


	constructor() {
		this.Level = LogLevelEnum.DEBUG;
		this.Callee = undefined;
		this.Messages = [];
	}
}



export abstract class BaseLogger {
	public static readonly FORCED_DEBUG = 'FORCED_DEBUG';
	public static readonly APP_MODE = 'APP_MODE';
	private static readonly SKIP_FRAMES = 1;                // Frames da skippare sullo stacktrace (1 perchè è la chiamante, a me interessa la precedente
	private readonly showStacktrace: boolean;



	constructor(showStacktrace: boolean) {
		this.showStacktrace = showStacktrace;
	}


	// Offline:
	//      TRUE
	//       - non faccio il fetch delle sourcemaps
	//       - ho i numeri di riga sbagliati (non transpilati)
	//       - le funzioni lambda/anonymous risultan CORRETTAMENTE senza NOME
	//
	//      FALSE
	//       - faccio il fetch delle sourcemaps ed ho i numeri di riga CORRETTI (transpilati)
	//       - NOMI funzioni lambda/anonymous ERRATI (risulta il nome di una funzione a caso, PENSO l'ultima hoisted)
	private static readonly STACK_OPTIONS = { offline: JSON.parse(import.meta.env.VITE_LOGGER_DISABLE_FETCH_SOURCEMAP)  } as StackTraceOptions;        // Boolean() oppure !!variable


	/**
	 * Formatta il Log che arriva in base al suo livello ed allo stack. E' qua dentro che faccio il templating nelle subclasses (es: KzLogger)
	 * @param eventDetails
	 */
	protected abstract formatMessage(eventDetails: LogMessage): void;

	public abstract logException(ex: unknown): void;



	/**
	 * Ricevo es: log.debug("ciao1", "pippo2", "pluto3", jsonOBJ). Le prime 3 sono stringhe e vanno scritte sulla stessa riga! (quindi van concatenate). Poi gli oggetti successivi, anche se misto, li metto su ogni riga
	 * Qui le stringhe le concateno con gli spazi e scarto (elimino) dall'array (.shift()) il primo elemento (visto che è stringa e non deve risultare poi negli .Object) da stampare
	 * @param messages
	 * @returns {LogMessage}
	 */
	protected static separateStringFromObjects(...messages: unknown[]): LogMessage {
		const entries = ObjectUtility.cloneArray(messages);
		const m = new LogMessage();

		for (const e of messages) {
			if (ObjectUtility.isString(e)) {
				m.Messages.push(e ?? '');

				entries.shift();
			}
			else {
				break;
			}
		}

		m.Objects = entries;

		return m;
	}



	/**
	 * Se passo un messaggio VUOTO, ma c'è un oggetto, mostro il log. Non so se ha ancora senso usarla, mi sa che l'avevo pensato diversamente anni fa
	 * @param mex
	 * @returns {boolean}
	 */
	protected canPrintLog(mex: LogMessage): boolean {
		return (mex.Messages.length > 0 ||
				((mex.Objects?.length ?? 0) > 0));
	}



	/**
	 * Controllo se ho possibilità di mostrare i log colorati (attualmente se sono in NATIVE, no perchè la console di debug per android fa schifo (siamo nel 2023))
	 * @returns {boolean}
	 */
	protected static canColorLog(): boolean {
		return !BaseLogger.isRunningOnNative();
	}



	/**
	 * Verifico se sono in production, eventualmente posso fare l'override tramite una variabile in localStorage (FORCED_DEBUG)
	 * @returns {boolean}
	 */
	protected static isProduction(): boolean {
		const debugSetting: string = localStorage.getItem(BaseLogger.FORCED_DEBUG) ?? 'false';
		const forcedDebug: boolean = (debugSetting.toLowerCase() === 'true');

		return (process.env.NODE_ENV === 'production' && !forcedDebug);
	}



	/**
	 * In Android Native, nella debug di console, i log NON sono colorati, tramite questa flag che setto allo startup dell'app, deciderò se colorare o no i log
	 * @returns {string}
	 */
	protected static isRunningOnNative(): boolean {
		const nativeFlag: string = localStorage.getItem(BaseLogger.APP_MODE) ?? 'BROWSER';

		return (nativeFlag === 'NATIVE');
	}



	/**
	 * BaseLogger quando logga qualcosa, genera uno stacktrace (async) e come callback ottengo i dettagli del callee
	 * @param sf
	 * @param logLevel
	 * @param message
	 * @returns {boolean}
	 */
	private addMetaAndFormat = (sf: StackFrame[], logLevel: LogLevelEnum, ...message: unknown[]): boolean => {
		if (sf.length === 0 && this.showStacktrace) {
			console.error("Cannot get callee function name");
			return false;
		}

		// Recupero lo stacktrace della funzione chiamante
		const callee = (this.showStacktrace) ? sf.slice(BaseLogger.SKIP_FRAMES, BaseLogger.SKIP_FRAMES +1)[0] : undefined;

		const eventDetails = BaseLogger.separateStringFromObjects(...message);
		eventDetails.Callee = callee;
		eventDetails.Level = logLevel;

		this.formatMessage(eventDetails);

		return true;
	};



	/**
	 * Clear della console ?!? Lol
	 */
	public clear(): void {
		console.clear();
	}



	public trace(...message: unknown[]): void {
		// In production NO Log
		if (BaseLogger.isProduction())
			return;

		if (this.showStacktrace)
			void StackTrace.get(BaseLogger.STACK_OPTIONS).then((sf) => {
				return this.addMetaAndFormat(sf, LogLevelEnum.TRACE, ...message);
			});
		else
			this.addMetaAndFormat([], LogLevelEnum.TRACE, ...message);
	}



	public debug(...message: unknown[]): void {
		// In production NO Log
		if (BaseLogger.isProduction())
			return;

		if (this.showStacktrace)
			void StackTrace.get(BaseLogger.STACK_OPTIONS).then((sf) => {
				return this.addMetaAndFormat(sf, LogLevelEnum.DEBUG, ...message);
			});
		else
			this.addMetaAndFormat([], LogLevelEnum.DEBUG, ...message);
	}



	public info(...message: unknown[]): void {
		// In production NO Log
		if (BaseLogger.isProduction())
			return;

		if (this.showStacktrace)
			void StackTrace.get(BaseLogger.STACK_OPTIONS).then((sf) => {
				return this.addMetaAndFormat(sf, LogLevelEnum.INFO, ...message);
			});
		else
			this.addMetaAndFormat([], LogLevelEnum.INFO, ...message);
	}



	public warn(...message: unknown[]): void {
		// In production NO Log
		if (BaseLogger.isProduction())
			return;

		if (this.showStacktrace)
			void StackTrace.get(BaseLogger.STACK_OPTIONS).then((sf) => {
				return this.addMetaAndFormat(sf, LogLevelEnum.WARN, ...message);
			});
		else
			this.addMetaAndFormat([], LogLevelEnum.WARN, ...message);
	}



	public error(...message: unknown[]): void {
		// In production NO Log
		if (BaseLogger.isProduction())
			return;

		if (this.showStacktrace)
			void StackTrace.get(BaseLogger.STACK_OPTIONS).then((sf) => {
				return this.addMetaAndFormat(sf, LogLevelEnum.ERROR, ...message);
			});
		else
			this.addMetaAndFormat([], LogLevelEnum.ERROR, ...message);
	}



	public fatal(...message: unknown[]): void {
		// In production NO Log
		if (BaseLogger.isProduction())
			return;

		if (this.showStacktrace)
			void StackTrace.get(BaseLogger.STACK_OPTIONS).then((sf) => {
				return this.addMetaAndFormat(sf, LogLevelEnum.FATAL, ...message);
			});
		else
			this.addMetaAndFormat([], LogLevelEnum.FATAL, ...message);
	}
}
