import { useEffect } from 'react';


/**
 * useMountEffect (semplice wrapper di onEffect con dipendenze[] per usarlo solo al mount). NON usare con metodi async (esiste l'hook apposito)
 * @param mountFn - funzione da lanciare al mount del componente
 */
export const useMount = (mountFn: () => void) => {
	useEffect(() => {
		mountFn();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);     // eslint-disable-next-line react-hooks/exhaustive-deps
};


/*
export function useMountEffect2(effect: CallableFunction): void {
	useEffect(() => {
		effect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
*/