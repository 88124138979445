import { GetArticleExistencesResponse } from '@dto/Responses/Articles/GetArticleExistencesResponse';
import { useArticlesExistencesList } from '@hooks/articles/useArticlesExistencesList';

import { useLog } from '@hooks/useLog';
import useBoolean from '@libs/KzHooks/useBoolean';

import { Column } from 'primereact/column';

import { DataTable, DataTableProps } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { forwardRef, ReactNode } from 'react';



type OverlayExistencesArticlesProps = {
	lastSelectedArticle: string,
	children?: ReactNode
}

/** Test vari per forwardRef
 *
type MyComponentProps = {
	something: string;
}

type RefProps = {
	something: string;
}

const MyComponent: ForwardRefRenderFunction<RefProps, MyComponentProps> = (props, ref) => {
	return <div>Works</div>;
}


//const OverlayExistencesArticles = React.forwardRef<OverlayPanel>(function OverlayExistencesArticles({ refPanel, deposits, isLoading, children }: OverlayExistencesArticlesProps, ref) {
//const OverlayExistencesArticles: ForwardRefRenderFunction<OverlayExistencesArticlesProps> = ({ refPanel, deposits, isLoading, children }: OverlayExistencesArticlesProps, ref: React.Ref<OverlayPanel>) => {

type TestAbc = {
	props: any,
	children?: ReactNode
}

const InputAAA = forwardRef<TestAbc>((props, ref) => {
	return <input {...props} ref={ref} />;
});


const forwardedRefOverlayExistencesArticles  = InputAAA;



/* Alternativa funzionante

export interface SearchProps {
	className?: string;
	size?: number;
	width?: string;
	value?: string;
	//onChange?: ChangeEventHandler<HTMLInputElement>;
	placeholder?: string;
}

const Search: React.ForwardRefRenderFunction<HTMLDivElement, SearchProps> = ({width, className}, ref) => {
	/*const {
		size = 'default',
		className,
		value,
		placeholder,
		width = '100%',
	} = props;   // Son commentati sennò sono doppi

	return (
		<div
			ref={ref}
			className={className}
			>

			Gino
		</div>
	);
}

const pippo = forwardRef<HTMLDivElement, SearchProps>(Search);
export { pippo } ;
 */



//const OverlayExistencesArticles: React.FC<OverlayExistencesArticlesProps> = forwardRef(({ refPanel, deposits, isLoading, children }: OverlayExistencesArticlesProps, ref: React.Ref<OverlayPanel>) => {
const OverlayExistencesArticles = forwardRef<OverlayPanel, OverlayExistencesArticlesProps>(({ lastSelectedArticle, children }: OverlayExistencesArticlesProps, ref: React.Ref<OverlayPanel>) => {
	const log = useLog();

	const [ canFetch, setCanFetch ]  = useBoolean(false);
	const { isLoading, existences: articlesExistences } = useArticlesExistencesList(lastSelectedArticle, canFetch);


	const onHideOverlay = () => {
		setCanFetch.setFalse();
	}



	const onShowOverlay = () => {
		setCanFetch.setTrue();
	}



	const dtParams: DataTableProps<GetArticleExistencesResponse[]> = {
		value: articlesExistences,
		dataKey: 'deposito_codice',

		scrollable: false,
		showGridlines: true,
		paginator: false,
		lazy: false,
		loading: isLoading,
		rows: 25,
		emptyMessage: 'Nessuna giacenza a magazzino',
		footer: '',

		resizableColumns: true,
		columnResizeMode: 'fit',
	}


	return (
		<OverlayPanel ref={ ref } style={ { width: '350px' } } showCloseIcon dismissable={ false } onHide={onHideOverlay} onShow={onShowOverlay}>
			<h6>Giacenze per deposito ({lastSelectedArticle})</h6>
			<DataTable { ...dtParams }>
				<Column field="deposito_codice" header="#" style={ { width: '10%' } } />
				<Column field="deposito_descrizione" header="Deposito" />
				{/*<Column field="caricato" header="Caricato" />*/ }
				{/*<Column field="scaricato" header="Scaricato" />*/ }
				<Column field="esistente" header="Esistente" style={ { width: '30%' } } />
			</DataTable>
		</OverlayPanel>
	);
});
OverlayExistencesArticles.displayName = "OverlayExistencesArticles";


export { OverlayExistencesArticles };
