import { useDepositList } from '@hooks/orders/useDepositList';

import { useLog } from '@hooks/useLog';

import { OrderExtra, orderExtraAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderExtraAtom';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { useAtom } from 'jotai';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { ReactNode } from 'react';



type TabExtraProps = {
	depositID: number | undefined,
	sendEmail: boolean | undefined,
	emailCustomer: string | undefined,
	children?: ReactNode
}



export const TabExtra: React.FC<TabExtraProps> = ({ depositID, sendEmail, emailCustomer }: TabExtraProps) => {
	const log = useLog();

	const { isLoading, depositList } = useDepositList();

	const [ extraInfo, setExtraInfo ] = useAtom(orderExtraAtom);


	useUpdate(() => {
		setExtraInfo((draft: OrderExtra) => {
			draft.EmailCliente = emailCustomer ?? "";
			draft.Deposito = depositID ?? -1;
			draft.SendEmail = sendEmail ?? false;
		});
	}, [ depositID, sendEmail, emailCustomer ]);



	const onEmailClienteTextChanged = (e: React.FormEvent<HTMLInputElement>, validatePattern: boolean) => {
		setExtraInfo((draft: OrderExtra) => {
			draft.EmailCliente = e.currentTarget.value;
		});
	}



	const onSendEmailChecked = (e: CheckboxChangeEvent) => {
		setExtraInfo((draft: OrderExtra) => {
			draft.SendEmail = e.checked ?? false;
		});
	}



	const onDepositoChanged = (e: DropdownChangeEvent) => {
		setExtraInfo((draft: OrderExtra) => {
			draft.Deposito = e.value;
		});
	}



	// PrimeReact 6: Analogo a InputTextArea, non riesco a passar un object di properties (InputTextProps): TS2769: No overload matches this call.
	const itEmailClienteProperties = {
		//value: extraInfo.EmailCliente,
		value: extraInfo.EmailCliente,
		placeholder: "eMail cliente",
		onInput: onEmailClienteTextChanged
	}


	const ddDepositoProperties: DropdownProps = {
		//value: extraInfo.Deposito,
		value: extraInfo.Deposito,
		options: depositList,
		optionValue: "codice",
		optionLabel: "descrizione",
		placeholder: "Seleziona la filiale",
		onChange: onDepositoChanged
	}


	return (
		<>
			<h6>Invia email di riepilogo al cliente</h6>
			<div className="p-inputgroup" style={ { width: "30%" } }>
                <span className="p-inputgroup-addon">
                    <Checkbox inputId="binary" checked={extraInfo.SendEmail} onChange={onSendEmailChecked} />
                </span>

				<InputText {...itEmailClienteProperties} />
			</div>

			<Divider />

			<h6>Sede di ricezione ordine</h6>
			<Dropdown {...ddDepositoProperties} style={ { width: "30%" } } />
		</>
	);
};

//TabNotes.whyDidYouRender = true

// <div className="field-checkbox">
// 	<Checkbox inputId="binary" checked={extraInfo.SendEmail} onChange={onSendEmailChecked} />
// 	<label htmlFor="binary">Invia email di riepilogo al cliente</label>
// </div>