import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { Cart, CartRow } from '@models/Cart';
import { List } from 'immutable';
import localForage from 'localforage';



export class CartDtoPersistent {
    public ID!: string;

    public CustomerID!: number;
    public RecipientID!: number;

    public DepositID!: number;
    public EmailCustomer!: string;
    public isSendEmail!: boolean;

    public NoteCliente!: string;
    public NoteAgente!: string;

    public Rows: CartRowDtoPersistent[];


    constructor() {
        this.ID = "";
        this.CustomerID = 0;
        this.RecipientID = 0;
        this.NoteCliente = "";
        this.NoteAgente = "";
        this.DepositID = -1;
        this.EmailCustomer = "";
        this.isSendEmail = true;

        this.Rows = new Array<CartRowDtoPersistent>();
    }
}



export class CartRowDtoPersistent {
    public ID!: number;
    public RowID!: number;
    public Product!: string;
    public Description!: string;
    public UM!: string;
    public VAT!: string;
    public SpecialRow!: boolean;
    public Notes!: string;

    public Price!: number;
    public Quantity!: number;
    public Pieces_per_Pack!: number;
    public Packs!: number;

    public Coefficient_Multiply!: number;

    public Discount!: string;
    public OriginalPrice!: number;
    public DiscountedPrice!: number;
    public CustomPrice!: number;
    public CustomDiscount!: string;
    public MaxDiscount!: string;
    public NotDiscountable!: boolean;

    public TotalAmount!: number;
}



export class CartRepository {
    private log: BaseLogger;

    private KEY_CART = "CART_";


    constructor() {
        this.log = new KzLogger("CartRepository")
    }



    private createCartKey(customerID: number): string {
        return `${this.KEY_CART}${customerID}`;
    }



    public async saveCart(cart: Cart): Promise<void> {
        try {
            const cartDTOPersistent: CartDtoPersistent | undefined = this.mapCartToCartPersistent(cart);
            if (cartDTOPersistent != undefined) {
                const keyCart = this.createCartKey(cart.CustomerID);
                await localForage.setItem(keyCart, cartDTOPersistent);
            }
        }
        catch (ex) {
            this.log.error(`Error saving default cart`, ex);
        }
    }



    public async clearCart(customerID: number): Promise<void> {
        const keyCart = this.createCartKey(customerID);

        await localForage.removeItem(keyCart);
    }



    public async loadCarts(): Promise<Cart[]> {
        this.log.debug(`Loading carts from localstorage repository`);

        const carts: Cart[] = new Array<Cart>();

        try {
            const keys = await localForage.keys();
            const cartKeys = keys.filter(k => k.startsWith(this.KEY_CART));

            for (const k of cartKeys) {
                const cartDtoPersistent: CartDtoPersistent | null = await localForage.getItem(k);
                const cart: Cart | undefined = (cartDtoPersistent == undefined) ? undefined : this.mapCartPersistentToCart(cartDtoPersistent);

                if (cart != undefined) {
                    carts.push(cart);
                }
            }
        }
        catch (ex) {
            this.log.error(`Error loading carts`, ex);
        }

        return carts;
    }



    public async loadCart(customerID: number): Promise<Cart | undefined> {
        let cart: Cart | undefined = undefined;
        let cartDtoPersistent: CartDtoPersistent | undefined = undefined;

        try {
            const keyCart = this.createCartKey(customerID);
            cartDtoPersistent = await localForage.getItem(keyCart) ?? undefined;

            cart = (cartDtoPersistent == undefined) ? undefined : this.mapCartPersistentToCart(cartDtoPersistent);
        }
        catch (ex) {
            this.log.error(`Error loading default cart`, ex);
        }

        return cart;
    }



    public async existsCart(customerID: number): Promise<boolean> {
        try {
            const keyCart = this.createCartKey(customerID);

            const keys = await localForage.keys();
            const existentCartKey = keys.filter(k => k === keyCart);

            return (existentCartKey.length > 0);
        }
        catch (ex) {
            this.log.error(`Error loading default cart`, ex);

            return false;
        }
    }



    /**
     * Mappatura da Cart ad un model compatiblie per la serializzazione. Il problema è che Cart contiene List() e log che NON sono serializzabili, e serializzarli/deserializzarli a mano è na rogna.
     * Creo quindi questo model più semplice per esser salvato in localstorage. IDEM poi a caricarlo
     * @param cart model Cart
     */
    private mapCartToCartPersistent(cart: Cart): CartDtoPersistent | undefined {
        const cdp: CartDtoPersistent = new CartDtoPersistent();

        try {
            cdp.ID = cart.ID;
            cdp.CustomerID = cart.CustomerID;
            cdp.RecipientID = cart.RecipientID;
            cdp.NoteCliente = cart.NoteCliente;
            cdp.NoteAgente = cart.NoteAgente;
            cdp.DepositID = cart.DepositID;
            cdp.EmailCustomer = cart.EmailCustomer;
            cdp.isSendEmail = cart.isSendEmail;

            cdp.Rows = cart.Rows.toArray();     // Trasformo da Immutable a Array

            cdp.Rows = cart.Rows.map((cr) => {
                const r: CartRowDtoPersistent = {
                    ID: cr.ID,
                    RowID: cr.RowID,
                    Product: cr.Product,
                    Description: cr.Description,
                    Coefficient_Multiply: cr.Coefficient_Multiply,
                    Price : cr.Price,
                    Pieces_per_Pack : cr.Pieces_per_Pack,
                    Packs : cr.Packs,
                    OriginalPrice : cr.OriginalPrice,
                    DiscountedPrice : cr.DiscountedPrice,
                    Discount : cr.Discount,
                    CustomPrice : cr.CustomPrice,
                    CustomDiscount : cr.CustomDiscount,
                    MaxDiscount : cr.MaxDiscount,
                    Quantity : cr.Quantity,
                    UM : cr.UM,
                    VAT : cr.VAT,
                    TotalAmount : cr.TotalAmount,
                    SpecialRow: cr.SpecialRow,
                    NotDiscountable: cr.NotDiscountable,
                    Notes: cr.Notes,
                }

                return r;
            }).toArray();
        }
        catch (ex) {
            this.log.error(ex);

            return undefined;
        }

        return cdp;
    }



    /**
     * Come serializzo da Cart a CartDtoPersistent, faccio viceversa
     * @param cdp model caricato da localstorage (completamente serializzabile)
     * @returns {Cart}
     */
    private mapCartPersistentToCart(cdp: CartDtoPersistent): Cart | undefined {
        const c: Cart = new Cart();

        try {
            c.ID = cdp.ID;
            c.CustomerID = cdp.CustomerID;
            c.RecipientID = cdp.RecipientID;
            c.NoteCliente = cdp.NoteCliente;
            c.NoteAgente = cdp.NoteAgente;
            c.DepositID = cdp.DepositID;
            c.EmailCustomer = cdp.EmailCustomer;
            c.isSendEmail = cdp.isSendEmail;

            c.Rows = List(cdp.Rows.map((crdp) => {
                const r: CartRow = {
                    ID : crdp.ID,
                    RowID : crdp.RowID,
                    Product : crdp.Product,
                    Description : crdp.Description,
                    Coefficient_Multiply: crdp.Coefficient_Multiply,
                    Price : crdp.Price,
                    Pieces_per_Pack : crdp.Pieces_per_Pack,
                    Packs : crdp.Packs,
                    Discount : crdp.Discount,
                    OriginalPrice : crdp.OriginalPrice,
                    DiscountedPrice : crdp.DiscountedPrice,
                    CustomPrice : crdp.CustomPrice,
                    CustomDiscount : crdp.CustomDiscount,
                    MaxDiscount : crdp.MaxDiscount,
                    Quantity : crdp.Quantity,
                    UM : crdp.UM,
                    VAT : crdp.VAT,
                    TotalAmount : crdp.TotalAmount,
                    SpecialRow: crdp.SpecialRow,
                    NotDiscountable: crdp.NotDiscountable,
                    Notes: crdp.Notes,
                }

                return r;
            }));
        }
        catch (ex) {
            this.log.error(ex);

            return undefined;
        }

        //c.Rows = List(cdp.Rows);            // Da Array a Immutable (riutilizzo CartRow, tanto è uguale e evito di ricreare un object ridondante)
        //c.Rows = List.of<CartRow>(...cdp.Rows);
        //List.of("a", "b", "c"); // ["a","b","c"]
        //List(["a","b","c"]); // ["a","b","c"]

        return c;
    }
}
