import React from 'react';

import { useLog } from '@hooks/useLog';
import { useMount } from '@libs/KzHooks/useMount';
import { useImmer } from 'use-immer';

import { CustomerSelection } from '@desktop/components/customers/CustomerSelection';
import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';

import { CardVOCTable } from '@layouts/desktop/pages/private/statistics/voc/CardVOCTable';
import { CardVOCChart } from '@layouts/desktop/pages/private/statistics/voc/CardVOCChart';
import { defaultVocRequest, useVOCInfo, VocRequest } from '@hooks/statistics/voc/useVOCInfo';

import './VOCStatistics.scss';



export const VOCStatistics: React.FC = () => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<VocRequest>(defaultVocRequest);
	const { isLoading, vocInfo, totalRecords } = useVOCInfo(reqParams);


	useMount(() => {
		log.clear();
	});



	//#region Eventi CardCustomer: Selezione del cliente dal TreeSelect

	const onSelectedCustomer = (customer: CustomerWithDetails, recipient: Recipient | undefined) => {
		setRequest((draft) => {
			draft.CustomerID = customer.codice;
		});
	};

	//#endregion



	return (
		<>
			<div className="content-section introduction">
				<h1>Statistiche VOC</h1>
				<p>Analisi VOC prodotti venduti</p>
			</div>

			<div className="content-section implementation">
				<div className="card">
					<CustomerSelection showOnlyActive={true} onCustomerSelectedHandler={onSelectedCustomer} />
				</div>

				{reqParams.CustomerID != 0 &&
                    <div className="card">
                        <CardVOCTable vocInfo={vocInfo} isLoading={isLoading} />
                    </div>
				}

				{(reqParams.CustomerID != 0 && vocInfo.length > 0) &&
					<div className="card">
						<CardVOCChart vocInfo={vocInfo} isLoading={isLoading} />
					</div>
				}
			</div>
		</>
	);
};
