import { t, classToPlain } from '@deepkit/type';
import { GShopSortedPagedRequest } from '@dto/Requests/GShopSortedPagedRequest';




export class GetArticlesRequest extends GShopSortedPagedRequest {
    @t public Articolo?: string;
    @t public Descrizione?: string;
    @t.array(String) public Categorie?: string[];

    @t public OnlyNew?: boolean;
    @t public OnlyObsolete?: boolean;
    @t public OnlySlowRotating?: boolean;


    public constructor(page: number, pageSize?: number, sortfield?: string, sortmode?: number, articolo?: string, descrizione?: string, categories?: string[], onlyNew?: boolean, onlyObsolete?: boolean, onlySlowRotating?: boolean) {
        super();

        this.Articolo = (articolo === null) ? undefined : articolo;
        this.Descrizione = (descrizione === null) ? undefined : descrizione;
        this.Categorie = (categories?.length == 0) ? [] : categories;
        this.OnlyNew = onlyNew;
        this.OnlyObsolete = onlyObsolete;
        this.OnlySlowRotating = onlySlowRotating;

        this.page = page;
        this.pagesize = pageSize;

        this.sortmode = sortmode;
        this.sortfield = sortfield;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(GetArticlesRequest, this);

        return js;
    }
}
