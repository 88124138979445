const API_PATH               = '/api';

// Tramite eslint : string non serve metterlo se è una const ed è dichiarata
//const API_PATH : string      = '/api';Type string trivially inferred from a string literal, remove type annotation 

// Authentication
export class AUTHENTICATION {
    private static readonly AUTH_PATH       = API_PATH + "/auth";

    public static readonly LOGIN            = AUTHENTICATION.AUTH_PATH + "/login";
    public static readonly LOGOUT           = AUTHENTICATION.AUTH_PATH + "/logout";
}



// Agents
export class AGENT {
    private static readonly AGENT_PATH       = API_PATH + "/agents";

    public static readonly INFO              = AGENT.AGENT_PATH + "/info";
}



// Articles
export class ARTICLES {
    private static readonly ARTICLES_PATH    = API_PATH + "/articles";

    public static readonly LIST                = ARTICLES.ARTICLES_PATH + "/list";
    public static readonly LIST_CATEGORIES     = ARTICLES.ARTICLES_PATH + "/categories/list";
    public static readonly LIST_FAV_CATEGORIES = ARTICLES.ARTICLES_PATH + "/favorites/{customerID}/categories";
    public static readonly LIST_FAVORITES      = ARTICLES.ARTICLES_PATH + "/favorites/{customerID}/{year}";
    public static readonly LIST_DISCOUNTS      = ARTICLES.ARTICLES_PATH + "/discounts/{customerID}";
    public static readonly LIST_TINTE          = ARTICLES.ARTICLES_PATH + "/tinte/{customerID}";
    public static readonly LIST_IVECO          = ARTICLES.ARTICLES_PATH + "/iveco/{customerID}";
    public static readonly GET_EXISTENCES      = ARTICLES.ARTICLES_PATH + "/existences/{article}";
    public static readonly GET_IMAGES          = ARTICLES.ARTICLES_PATH + "/images/{article}";
}



// Customers (Clienti)
export class CUSTOMERS {
    private static readonly CUSTOMERS_PATH    = API_PATH + "/customers";

    public static readonly LIST               = CUSTOMERS.CUSTOMERS_PATH + "/list";
    public static readonly GET                = CUSTOMERS.CUSTOMERS_PATH + "/list/{customerID}";
    public static readonly LIST_WITH_RECIPIENTS= CUSTOMERS.CUSTOMERS_PATH + "/list-with-recipients";
    public static readonly LIST_ACTIVE        = CUSTOMERS.CUSTOMERS_PATH + "/list-active";
}



// Recipient (Destinatari)
export class RECIPIENTS {
    private static readonly RECIPIENTS_PATH    = API_PATH + "/recipients";

    public static readonly LIST              = RECIPIENTS.RECIPIENTS_PATH + "/list";
    public static readonly GET               = RECIPIENTS.RECIPIENTS_PATH + "/list/{recipientID}";
}



// Payments (Clienti)
export class PAYMENTS {
    private static readonly CUSTOMERS_PATH    = API_PATH + "/payments/customers";

    public static readonly LIST_PAYMENTS               = PAYMENTS.CUSTOMERS_PATH + "/list";
    public static readonly LIST_CUSTOMER_PAYMENTS      = PAYMENTS.CUSTOMERS_PATH + "/list/{customerID}";
    public static readonly LIST_CUSTOMER_PAYMENT_BY_STATE= PAYMENTS.CUSTOMERS_PATH + "/list/{customerID}/{state}";
}



// Orders (Ordini)
export class ORDERS {
    private static readonly ORDERS_PATH         = API_PATH + "/orders";

    public static readonly GET_DETAILS          = ORDERS.ORDERS_PATH + "/list/{orderCode}/{orderNumber}";
    public static readonly GET_BACKORDERS       = ORDERS.ORDERS_PATH + "/list/backorders";

    public static readonly LIST_ORDER_BY_HEADERS= ORDERS.ORDERS_PATH + "/headers/list";

    public static readonly LIST_DEPOSIT         = ORDERS.ORDERS_PATH + "/deposit/list";

    public static readonly NEW_ORDER            = ORDERS.ORDERS_PATH + "/new";
}



// Statistics
export class STATISTICS {
    private static readonly STATISTICS_PATH    = API_PATH + "/statistics";

    public static readonly DASHBOARD                    = STATISTICS.STATISTICS_PATH + "/dashboard";
    public static readonly COUNT_REGISTRY               = STATISTICS.STATISTICS_PATH + "/count-registry";
	public static readonly VOC                          = STATISTICS.STATISTICS_PATH + "/voc";
    public static readonly CUSTOM_SALES_BY_CATEGORY     = STATISTICS.STATISTICS_PATH + "/custom/sales-by-category";             // CTAmonn
    public static readonly CUSTOM_SALES_BY_SUBCATEGORY  = STATISTICS.STATISTICS_PATH + "/custom/sales-by-subcategory";          // CTAmonn
    public static readonly CUSTOM_SALES_BY_ARTICLE      = STATISTICS.STATISTICS_PATH + "/custom/sales-by-article";          // CTAmonn
    public static readonly CUSTOM_SALES_FORECAST        = STATISTICS.STATISTICS_PATH + "/custom/forecast-sales-by-category";    // CTAmonn
}



// Settings
export class SETTINGS {
    private static readonly SETTINGS_PATH    = API_PATH + "/settings";

    public static readonly LIST              = SETTINGS.SETTINGS_PATH + "/list";
}




// Utility
export class UTILITY {
    private static readonly UTILITY_PATH       = API_PATH + "/utility";

    public static readonly CLEANDB             = UTILITY.UTILITY_PATH + "/cleandb";
}