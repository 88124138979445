import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { StatisticsService } from '@services/StatisticsService';
import { GetSalesByArticleResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesByArticleResponse';


export type SalesByArticleRequest = {
	CustomerID?: number | undefined,
}


export const defaultSalesByArticleRequest: SalesByArticleRequest = {
	CustomerID: undefined,
}



export const useSalesByArticleStats = (sar: SalesByArticleRequest) => {
	const log = useLog();

	const statisticsService = useSingleton<StatisticsService>(() => new StatisticsService(), []);

	const queryInfo = useQuery<GetSalesByArticleResponse[]>({
		queryKey: [ 'sar', sar ],
		queryFn: async () => statisticsService.getCustomStat_SalesByArticle(sar.CustomerID)
	});


	return {
		...queryInfo,
		salesByArticleInfo: queryInfo.data ?? new Array<GetSalesByArticleResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
