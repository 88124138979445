import React, { ErrorInfo } from 'react';
import { FallbackProps } from 'react-error-boundary';



export const ErrorHandler = (error: Error, info: ErrorInfo) => {
	//console.info(error);
	//console.info(info);
};



export const GlobalErrorBoundaryFallback = ({error, resetErrorBoundary}: FallbackProps) => {
	return (
		<>
			<div role="alert" style={{ textAlign: 'center' }}>
				<h1>
					Errore caricamento APP
				</h1>
			</div>

			<br />
			<br />
			<br />
			<hr />

			<div>
				<h5>
					Errors inside FC components
					<br />
					<br />
					Details: <br />
					{error.message}
				</h5>

				<br />
				<button onClick={ resetErrorBoundary }>Riprova</button>
			</div>

		</>
	)
}