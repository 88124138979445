import { GetArticleExistencesResponse } from '@dto/Responses/Articles/GetArticleExistencesResponse';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { ArticlesService } from '@services/ArticlesService';
import { useQuery } from '@tanstack/react-query';



export const useArticlesExistencesList = (article: string, enabled: boolean) => {
	const log = useLog();

	const articlesService = useSingleton<ArticlesService>(() => new ArticlesService(), []);

	const queryInfo = useQuery<GetArticleExistencesResponse[]>({
		queryKey: ['list-existences', article],
		queryFn: async () => articlesService.getArticlesExistences(article),
		enabled: article !== '' && enabled
	});

	return {
		...queryInfo,
		existences: queryInfo.data ?? new Array<GetArticleExistencesResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
