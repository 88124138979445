import { formatRow, priceOrderDetailsBodyTemplate, quantity3OrderDetailsBodyTemplate, totalAmountOrderDetailsBodyTemplate } from '@desktop/components/orders/DataTableTemplates';

import { OrderRow } from '@models/Order';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import React, { ReactNode } from 'react';



type CardOrderRowsProps = {
	orderRows?: OrderRow[],
	children?: ReactNode
}



export const CardOrderRows: React.FC<CardOrderRowsProps> = ({ orderRows }: CardOrderRowsProps) => {
	const dtParameters: DataTableProps<OrderRow[]> = {
		rowHover: true,
		stripedRows: true,
		paginator: false,

		lazy: false,
		loading: false,
		rows: 50,
		value: orderRows,

		// columnResizeMode: "fit",
		emptyMessage: 'Ordine vuoto',
		footer: '',
		dataKey: 'articolo',
		rowClassName: formatRow,
	};


	const dtColumns: ColumnProps[] = [
		{ field: 'articolo', header: 'Cod.Art.', style: { width: '10%' } },
		{ field: 'descrizione', header: 'Descrizione', style: { width: '60%' } },
		{ field: 'unita_di_misura', header: 'UM', style: { width: '6%' } },
		// { field: 'quantita_1', header: 'Conf.', body: quantity1OrderDetailsBodyTemplate, style: { width: '6%' } },
		// { field: 'quantita_2', header: 'Pz.Conf.', body: quantity2OrderDetailsBodyTemplate, style: { width: '6%' } },
		{ field: 'quantita_3', header: 'Qta', body: quantity3OrderDetailsBodyTemplate, style: { width: '6%' } },
		{ field: 'prezzo', header: 'Prezzo', body: priceOrderDetailsBodyTemplate, style: { width: '6%' } },
		{ field: 'sconti', header: 'Sconti', style: { width: '6%' } },
		{ field: 'codice_iva', header: 'IVA', style: { width: '6%' } },
		{ field: 'importo', header: 'Importo', body: totalAmountOrderDetailsBodyTemplate, style: { width: '6%' } },
	];


	const colums = dtColumns.map((col, i) => {
		return <Column key={col.field} {...col} />;
	});



	return (
		<DataTable className="p-datatable-sm" {...dtParameters}>
			{ colums }
		</DataTable>
	);
};