import { useCallback, useReducer} from 'react';


/**
 * Hooks for forcing a component to re-render
 * @returns {() => void}
 */
export const useForceRender = () => {
	// const [value, setValue] = useState(0); // integer state
	// return () => setValue(value => value + 1);
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);

	const memoizedforceUpdate = useCallback((): void => {
			forceUpdate();
		},
		[ forceUpdate ],
	);

	return memoizedforceUpdate;     // forceUpdate
};



/*
export default function useForceUpdate(): () => void {
  const [ , dispatch ] = useState<{}>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback(
    (): void => {
      dispatch(Object.create(null));
    },
    [ dispatch ],
  );
  return memoizedDispatch;
}
* */