import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';



export class GetOrderRowDetailsResponse {
	@t public articolo!: string;
	@t public descrizione!: string;
	@t public unita_di_misura!: string;
	@t public quantita_1!: number;
	@t public quantita_2!: number;
	@t public quantita_3!: number;
	@t public prezzo!: number;
	@t public sconti!: string;
	@t public importo!: number;
	@t public codice_iva!: string;
	@t public id!: string;
}



export class GetOrderDetailsResponse extends GShopResponse {
	@t public documento!: string;
	@t public flag!: string;
	@t public stato!: string;
	@t public numero!: number;
	@t public data!: string;
	@t public codice!: number;
	@t public pagamento!: string;
	@t public banca!: number;
	@t public agente!: number;
	@t public destinatario!: number;
	@t public spedizioniere!: number;
	@t public listino!: number;
	@t public sigla!: string;
	@t public imponibile_1!: number;
	@t public codice_iva_1!: string;
	@t public imposta_1!: number;
	@t public imponibile_2!: number;
	@t public codice_iva_2!: string;
	@t public imposta_2!: number;
	@t public imponibile_3!: number;
	@t public codice_iva_3!: string;
	@t public imposta_3!: number;
	@t public imponibile_4!: number;
	@t public codice_iva_4!: string;
	@t public imposta_4!: number;
	@t public imponibile_5!: number;
	@t public codice_iva_5!: string;
	@t public imposta_5!: number;
	@t public imponibile_6!: number;
	@t public codice_iva_6!: string;
	@t public imposta_6!: number;
	@t public totale_merce!: number;
	@t public percentuale_sconto!: number;
	@t public importo_sconto!: number;
	@t public netto_merce!: number;
	@t public spese_trasporto!: number;
	@t public spese_imballo!: number;
	@t public spese_varie!: number;
	@t public spese_incasso!: number;
	@t public spese_bolli!: number;
	@t public totale_imponibile!: number;
	@t public totale_imposta!: number;
	@t public totale_documento!: number;
	@t public totale_in_valuta!: number;
	@t public totale_da_pagare!: number;
	@t public data_inizio!: string;
	@t public ora_inizio!: string;
	@t public colli!: string;
	@t public cartoni!: string;
	@t public peso!: string;
	@t public peso_lordo!: string;
	@t public volume!: string;
	@t public palette!: string;
	@t public trasporto_a_mezzo!: string;
	@t public porto!: string;
	@t public causale_trasporto!: string;
	@t public aspetto_beni!: string;
	@t public annotazioni!: string;
	@t public filler!: string;
	@t public workstation!: string;
	@t public cig_cup!: string;
	@t public id_sdi!: string;

	@t.array(GetOrderRowDetailsResponse) public righe_documento: GetOrderRowDetailsResponse[] = [];
}
