import { atomWithImmer } from 'jotai/immer'
import { Toast } from 'primereact/toast';


const defaultToast: Toast | undefined | null = undefined;


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const toastAtom = atomWithImmer<Toast | null>(defaultToast);
