import { GShopRequest } from '@dto/Requests/GShopRequest';
import { t } from '@deepkit/type';


export abstract class GShopSortedPagedRequest extends GShopRequest {
	public abstract override toJson(): unknown;

	@t public page!: number;
	@t public pagesize?: number;

	@t public sortfield?: string;
	@t public sortmode?: number;
}
