import { WebException } from '@libs/KzHttpEngine/Exceptions/WebException';

export class ApiException extends Error {
    public Date: Date;
    public ErrorCode: number;
    public InnerException?: WebException;
    public StackTrace?: string[];



    constructor(errorCode: number, message: string, innerException?: WebException, stackTrace?: string[]) {
        super();     // Pass remaining arguments (including vendor specific ones) to parent constructor
        
        if (Error.captureStackTrace) {    // Maintains proper stack trace for where our error was thrown (only available on V8)
            Error.captureStackTrace(this, ApiException);
        }

        // Custom debugging information
        this.Date = new Date();
        this.ErrorCode = errorCode;
        this.message = message;
        this.StackTrace = stackTrace;
        this.InnerException = innerException;
    }
}
