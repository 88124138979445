import React, { ReactNode } from 'react';
import { Avatar } from 'primereact/avatar';
import { Recipient } from '@models/Recipient';


type CardDestinatarioProps = {
	recipient?: Recipient,
	children?: ReactNode
}



const EmptyCardRecipient = () => {
	return (
		<>
			<div className="text-900 font-normal text-base mb-2">&nbsp;</div>
			<div className="text-900 font-normal text-base mb-2">&nbsp;</div>
			<div className="flex align-items-center text-900 font-medium text-xl mb-2">Stesso</div>
			<div className="text-900 font-normal text-base mb-2">&nbsp;</div>
			<div className="text-900 font-normal text-base mb-2">&nbsp;</div>
		</>
	)
}


const RecipientDetails: React.FC<CardDestinatarioProps> = ({ recipient }: CardDestinatarioProps) => {
	return (
		<>
			false
			<div className="text-900 font-medium text-xl mb-2">{recipient?.ragione_sociale_1} {recipient?.ragione_sociale_2}</div>
			<div className="text-900 font-normal text-base mb-2">{recipient?.indirizzo}</div>
			<div className="text-900 font-normal text-base mb-2">{recipient?.localita} - {recipient?.cap} ({recipient?.provincia})</div>
			<div className="text-900 font-normal text-base mb-2">P.Iva {recipient?.partita_iva}</div>
			<div className="text-900 font-normal text-base mb-2">Tel. {recipient?.telefoni} {recipient?.cellulari}</div>
		</>
	)
}



export const CardRecipient: React.FC<CardDestinatarioProps> = ({ recipient }: CardDestinatarioProps) => {
	return (
		<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round header-cards">
			<div className="flex justify-content-between mb-3">
				<div>
					<span className="block text-500 font-medium mb-3">Destinatario</span>
					{
						(recipient == undefined)
							?
								<EmptyCardRecipient />
							:
								<RecipientDetails recipient={recipient} />
					}
				</div>

				<div className="flex justify-content-center">
					<Avatar icon="pi pi-home" className="mr-2" size="large" style={{ backgroundColor: '#2196F3', color: '#FFFFFF' }} shape="circle" />
				</div>
			</div>
		</div>
	);
};