import React, { ReactNode } from 'react';

import { useLog } from '@hooks/useLog';

import { InputText } from 'primereact/inputtext';
import { useImmer } from 'use-immer';
import { useUpdate } from '@libs/KzHooks/useUpdate';



type InputDiscountProps = {
	firstValue: string,
	size: number,
	cbOnValidated: (discount: string) => void,
	children?: ReactNode
}



export const InputDiscount: React.FC<InputDiscountProps> = ({ firstValue, cbOnValidated, size }: InputDiscountProps) => {
	const log = useLog();
	const [ value, setValue ] = useImmer<string>("");

	const rxAllowNumbersAndMinus = /[\d+]+$/;



	useUpdate(() => {
		setValue(firstValue);

	}, [ firstValue ]);



	const onValueChange = (e: string) => {
		setValue(e);
	}


	const onValidateDiscountEditor = () => {
		cbOnValidated(value);
	}



	return (
		<InputText size={size} keyfilter={rxAllowNumbersAndMinus} value={value} onBlur={onValidateDiscountEditor} onChange={(e) => onValueChange(e.target.value) } />
	);
};