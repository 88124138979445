import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';


export class GetSalesByArticleResponse extends GShopResponse {
    @t  public articolo!: string;
    @t  public descrizione!: string;
    @t  public quantita_venduto!: number;
    @t  public importo_venduto!: number;
    @t  public ultima_vendita!: string;
}
