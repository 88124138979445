/* eslint-disable @typescript-eslint/unbound-method */

import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { PaginatorCurrentPageReportOptions, PaginatorFirstPageLinkOptions, PaginatorJumpToPageInputOptions, PaginatorLastPageLinkOptions, PaginatorNextPageLinkOptions, PaginatorPageLinksOptions, PaginatorPrevPageLinkOptions, PaginatorRowsPerPageDropdownOptions, PaginatorTemplateOptions } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import React from 'react';



/**
 * paginatorTemplate formattazione paginator
 */
export const paginatorTemplate: PaginatorTemplateOptions = {
	layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',

	JumpToPageInput: function jumpToPageInput(options: PaginatorJumpToPageInputOptions) {
		// <InputNumber className={options.className} value={options.value} onChange={options.onChange} disabled={options.disabled} />;
		return (<></>)
	},


	FirstPageLink: function firstPageLinkRender(options: PaginatorFirstPageLinkOptions) {
		return (
			<button type="button" className={ options.className } onClick={ options.onClick } disabled={ options.disabled }>
				<span className="pi pi-angle-double-left"></span>
				<Ripple />
			</button>
		);
	},


	LastPageLink: function lastPageLinkRender(options: PaginatorLastPageLinkOptions) {
		return (
			<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
				<span className="pi pi-angle-double-right"></span>
				<Ripple />
			</button>
		);
	},


	PrevPageLink: function prevPageLinkTemplate(options: PaginatorPrevPageLinkOptions) {
		return (
			<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
				<span className="pi pi-angle-left"></span>
				<Ripple />
			</button>
		)
	},


	NextPageLink: function nextPageLinkTemplate(options: PaginatorNextPageLinkOptions) {
		return (
			<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
				<span className="pi pi-angle-right"></span>
				<Ripple />
			</button>
		)
	},


	PageLinks: function pageLinksTemplate(options: PaginatorPageLinksOptions) {
		if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
			const className = classNames(options.className, { 'p-disabled': true });

			return <span className={className} style={{ userSelect: 'none' }}>...</span>;
		}

		return (
			<button type="button" className={options.className} onClick={options.onClick}>
				{options.page + 1}
				<Ripple />
			</button>
		)
	},


	RowsPerPageDropdown: function rowPerPageDropdownTemplate(options: PaginatorRowsPerPageDropdownOptions) {
		const dropdownOptions = [
			{ label: 15, value: 15 },
			{ label: 50, value: 50 },
			{ label: 100, value: 100 },
			{ label: 250, value: 250 },
			{ label: 'Tutto', value: 500 },
		];

		/*
		const dropdownOptions = [
			{ label: 25, value: 25 },
			{ label: 50, value: 50 },
			{ label: 100, value: 100 },
			{ label: 'Tutto', value: options.totalRecords },
		];*/

		return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
	},


	CurrentPageReport: function currentPageReportTemplate(options: PaginatorCurrentPageReportOptions) {
		return (
			<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
		{options.first} - {options.last} di {options.totalRecords}
		</span>
		);
	}
};
