/* eslint-disable @typescript-eslint/no-explicit-any, unicorn/no-null */

export type MaybeAlsoNull<T> = T | null | undefined;
export type Maybe<T> = T | undefined;


export class ObjectUtility {
    /**
     * Controllo se è una stringa
     * @param s
     * @returns {boolean}
     */
    public static isString(s: unknown): s is string {
        return typeof(s) === 'string' || s instanceof String;
    }



    /**
     * Controllo se la variabile è un numero
     * @param value
     * @returns {boolean}
     */
    public static isNumber(value?: string | number): boolean {
        return ((value != null) &&
                (value !== '') &&
                !Number.isNaN(Number(value.toString())));
    }



    /**
     * Controllo se è un numero (metodo alternativo)
     * @param numStr
     * @returns {boolean}
     */
    public static isNumber_Alternative(numStr: string) {
        return !Number.isNaN(Number.parseFloat(numStr)) && !Number.isNaN(+numStr)
    }



    /**
     * Controllo se una stringa è vuota / null / undefined
     * @param s
     * @returns {boolean}
     */
    public static isEmptyString(s: unknown): s is null | undefined {
        return (!s);
        //return (s == null || s == '');
        //return (s === undefined || s === null);
        //return typeof(s) === 'string' || s instanceof String;
    }



    /**
     * Converto un oggetto in number usando un defaultValue qualora non sia un valore valido
     * @param s
     * @param defaultValue
     * @returns {number}
     */
    public static toNumber(s: unknown, defaultValue = 0): number {
        let code: number | undefined = Number.parseInt(s as string ?? '');
        code = this.isNumber(s as string) ? defaultValue : code;

        return code;
    }



    /**
     * Helper per aver la garanzia che il tipo non sia null
     * @param value
     * @returns {boolean}
     */
    public static isNotNull<T>(value: T): value is NonNullable<T> {
        return value !== null && value !== undefined
    }



    /**
     * Controllo se un oggetto è {} o null o undefined
     * @param obj
     * @returns {boolean}
     */
    public static isEmpty(obj: any): obj is null | undefined {
        return (typeof obj !== 'number' && (!obj || Object.keys(obj).length === 0));
    }



    /**
     * Clonazione deep di un object
     * @param myObject da effettuare una deep copy
     * @returns {any}
     */
    public static cloneObject(myObject: any): any {
        return Object.assign({}, myObject);
    }



    /**
     * Clonazione di un array
     * @param myArray da clonare
     * @returns {any}
     */
    public static cloneArray<TType>(myArray: TType): TType {
        return Object.assign([], myArray);
    }



    public static cloneArrayObject(_: any): any {
        /*const myClonedArray = [];
        
        myArray.forEach(val => myClonedArray.push(Object.assign({}, val)));

        return myArray;*/
        //const plansCopy = myArray.map(obj => ({...obj}));
        return undefined;
    }



    /**
     * Conversione di un object in un array di keyvaluepair (ho 3 metodi interni alternativi)
     * @param obj da convertire
     * @returns {Record<string, any>[]} array mappato come key/value delle varie property dell'oggetto (compresi valori null/undefinited)
     */
    public static toKeyValue(obj: any): Record<string, any>[] {
        let entries: Record<string, any>[] = new Array<Record<string, any>>();
        // Uso metodo 1 invece che 2, perchè quando vado a crea UrlSearchParam, evito di fare due loop
        // Un loop sull'array [{ key : value }, { key2 : value2 }], e uno sulle keys (in realtà una e basta) dell'oggetto [ key ]

        if (obj == undefined)
            return entries;

        // Metodo 1  object => keyvaluepair [{ key: property, value: value }, { key: property2, value: value2 }]
        // Un loop [{ key : nomekey, value: valorevalue }, { key : nomekey2, value: valorevalue2 }]
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        entries = Object.entries(obj)
            .filter( ([k, _]) => Object.hasOwnProperty.call(obj, k))
            .map( ([k, v]) => (
                    {
                        key: k,
                        value: v
                    }
                )
            );

        // Metodo 2: object => keyvaluepair (Record<string, any>)        [ { property : value }, { property2: value2 } ]
        // Due loop per iterarlo: Un loop sull'array [{ key : value }, { key2 : value2 }], e uno sulle keys (in realtà una e basta) dell'oggetto [ key ]
        /*
        * obj.forEach((o) => {
            Object.keys(o).forEach(key => {
                params.append(key, o[key]);
            });
        });
        *
        * */
        /*const entries: Record<string, any>[] = Object.entries(obj)
            .filter( ([k, v]) => Object.hasOwnProperty.call(obj, k))
            .map( ([k, v]) => {
                    const t: Record<string, any> = {};
                    t[k] = v;

                    return t;
                }
            );
        */

        /** Metodo 3: object => keyvaluepair [{ key: property, value: value }, { key: property2, value: value2 }] (senza filtrare le properties)
        const result = Object.keys(obj)
                                                 .map(k => (
                                                         { key: k,
                                                           value: obj[k]
                                                         }
                                                     )
                                                 );*/

        /** Metodo 4: object => keyvaluepair [{ name: property, value: value }, { name: property2, value: value2 }] (senza filtrare le properties)
        const entries = obj.map((o: any) => {
            const key = Object.keys(o)[0];

            return {
                name: key,
                value: o[key]
            };
        });
         */

        return entries;
    }
}