import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';


export class GetArticleExistencesResponse extends GShopResponse {
	@t public deposito_codice!: number;
	@t public deposito_descrizione!: string;
	@t public caricato!: number;
	@t public scaricato!: number;
	@t public ordinato_clienti!: number;
	@t public impegnato!: number;
	@t public esistente!: number;
}
