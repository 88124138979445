import { useMemo } from 'react';
import { KzLogger } from '@libs/loggers/KzLogger';


/**
 * useLog (return always same instance of a logger (KzLogger, based on name)
 * @param logName - nome istanza del logger
 * @param printObjectOnNewLine
 */
export const useLog = (logName?: string, printObjectOnNewLine?: boolean): KzLogger => {
	return useMemo<KzLogger>(() => new KzLogger(logName, printObjectOnNewLine), [ logName, printObjectOnNewLine ]);
}
