import { atomWithImmer } from 'jotai/immer';
import { ArticleRow } from '@services/CartService';


const defaultArticle: Array<ArticleRow> = new Array<ArticleRow>();


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const orderSelectedArticlesAtom = atomWithImmer<ArticleRow[]>(defaultArticle);
