import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PublicLayoutContent } from '@layouts/desktop/pages/public/PublicLayoutContent';


type PublicRouteProps = {
	component: ReactNode,
	path: string,
	isAuth?: boolean,
	exact?: boolean,
	redirectpath?: string,
}


// Snippet per implementazione di route privata. In questo caso non la uso, faccio a monte un controllo dell'autenticazione dell'utente, e se è autenticato allora va nelle rotte private, diversamente in quelle pubbliche
export const PublicRoute: React.FC<PublicRouteProps> = ({component, path, exact, isAuth, redirectpath}: PublicRouteProps) => {
	if (isAuth && redirectpath)
		return (<Redirect to={redirectpath} />);

	return (
		<Route path={path} exact={exact}>
			<PublicLayoutContent>
				{ component }
			</PublicLayoutContent>
		</Route>
	)
};
