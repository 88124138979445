import { ModulesType, settingsAtom } from '@atoms/SettingsAtom';
import { PageIvecoArticles } from '@desktop/pages/private/orders/OrdersNew/DialogArticles/custom/PageIvecoArticles';
import { useLog } from '@hooks/useLog';

import { orderSelectedArticlesAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderSelectedArticlesAtom';

import { PageTinteArticles } from '@desktop/pages/private/orders/OrdersNew/DialogArticles/custom/PageTinteArticles';
import { PageAllArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/PageAllArticles';
import { PageFavoritesArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/PageFavoritesArticles';
import { PageNewArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/PageNewArticles';
import { PageObsoleteArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/PageObsoleteArticles';

import { PageSlowRotatingArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/PageSlowRotatingArticles';
import { useMount } from '@libs/KzHooks/useMount';
import { useUpdateButSkipMount } from '@libs/KzHooks/UseUpdateButSkipMount';
import dayjs from 'dayjs';

import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { Button } from 'primereact/button';

import { Dialog, DialogProps } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { ReactElement, ReactNode, useMemo } from 'react';

import './DialogSelectArticles.scss';



type DialogArticoliProps = {
	customerID: number,
	cbHideDialog: (bConfirmedProducts: boolean) => void,
	isVisible: boolean,
	children?: ReactNode
}


export const DialogSelectArticles: React.FC<DialogArticoliProps> = ({ customerID, cbHideDialog, isVisible }: DialogArticoliProps) => {
	const log = useLog();
	const [ selectedProducts, setSelectedProducts ] = useAtom(orderSelectedArticlesAtom);
	const settingAtom = useAtomValue(settingsAtom);



	useMount(() => {
		log.info('Loading products list...');
	});



	useUpdateButSkipMount(() => {
		// All'apertura del dialog, svuoto la lista degli articoli selezionati
		if (isVisible) {
			setSelectedProducts([]);
		}
	}, [ isVisible ]);



	const onBtnAddClickHandler = () => {
		// Toggle di "visible" e inserimento dei prodotti selezionati in quanto confermati con il tasto "Aggiungi"
		cbHideDialog(true);
	};



	const onHiddenDialog = () => {
		// Attualmente onHide è inutile in quanto viene lanciato solo se clicco sulla X o tasto ESC. Se faccio lo switch del toggle visible, non viene generato nessun evento.
		// Qui ho chiuso il dialog SENZA fare aggiungi (tramite ESC o click sulla X, quindi faccio lo switch del visible, senza aggiungere i prodotti)
		cbHideDialog(false);
	};



	const renderFooter = () => {
		return (
			<div>
				<Button label="Aggiungi" icon="pi pi-check" onClick={ onBtnAddClickHandler } autoFocus disabled={ selectedProducts.length === 0 } />
			</div>
		);
	};



	const renderFavorites = (mergeFavorites: boolean) => {
		const tabs: ReactElement[] = [];

		const currentYear: number = dayjs().year();
		const previousYear: number = dayjs().year() - 1;

		const favoritesHeaderCurrent = `Preferiti (${ currentYear })`;
		const favoritesHeaderPrev = `Preferiti (${ previousYear })`;
		
		// Solo TAB dei preferiti raggruppati
		if (mergeFavorites) {
			tabs.push(
				<TabPanel header={ `Preferiti (${ currentYear - Math.abs(settingAtom.favoritesMaxYears) }-${ currentYear })` } leftIcon="pi pi-star" >
					<PageFavoritesArticles customerID={ customerID } year={ -1 } />
				</TabPanel>
			);
		}
		else {
			{/* WARNING: Non posso restituire due elementi <><TabPanel /><TabPanel/></> perchè, PENSO, vengono considerdati su due livelli differenti e quindi non renderizzati. Mah */ }
			tabs.push(
		<TabPanel header={ favoritesHeaderCurrent } leftIcon="pi pi-star">
					<PageFavoritesArticles customerID={ customerID } year={ currentYear } />
				</TabPanel>,

				<TabPanel header={ favoritesHeaderPrev } leftIcon="pi pi-replay">
					<PageFavoritesArticles customerID={ customerID } year={ previousYear } />
				</TabPanel>
			);

			// Forse ho risolto con SELECTED_TAB_INDEX perchè i tab vengono generati da una function invece che mettere delle condizioni dentro il rendering (es: setting.merge && <Tabxxx>)
			// Se sono nei primi 2 mesi dell'anno inverto i tabs (questo perchè selectedIndex è buggato e non mi permette di evidenziare un tab diverso dal primo. BOH)
			//if (dayjs().month() <= 2)
			//	tabs.reverse();
		}

		return tabs;
	};



	const dialogParameters: DialogProps = {
		header       : 'Aggiungi articoli...',
		visible      : isVisible,
		closable     : true,
		closeOnEscape: false,
		draggable    : false,
		maximizable  : false,
		modal        : true,
		focusOnShow  : true,
		footer       : renderFooter,
		onHide       : onHiddenDialog,
		style        : { width: '95%', height: '100%' },
	};

	// Per i primi 3 mesi mostro il tab preferiti ANNO_PRECEDENTE, altrimenti ANNO_SUCCESSIVO (0 INDEX quindi GEN-FEB-MAR)
	// oppure il primo tab "Preferiti" nel caso sia stato fatto il merge dei tabs
	const SELECTED_TAB_INDEX = ( dayjs().month() <= 2 && !settingAtom.mergeFavorites ) ? 1 : 0;

	return (
		<Dialog { ...dialogParameters } >
			<TabView className="tabview-header-icon tabview-select-articles" activeIndex={SELECTED_TAB_INDEX} >

				{ renderFavorites(settingAtom.mergeFavorites) }

				<TabPanel header="Tinte" leftIcon="pi pi-palette" visible={ settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_TINTE) }>
					<PageTinteArticles customerID={ customerID } />
				</TabPanel>

				<TabPanel header="IVECO" leftIcon="pi pi-truck" visible={ settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_TINTE) }>
					<PageIvecoArticles customerID={ customerID } />
				</TabPanel>

				<TabPanel header="Obsoleti" leftIcon="pi pi-history" visible={ settingAtom.showObsolete }>
					<PageObsoleteArticles customerID={ customerID } />
				</TabPanel>

				<TabPanel header="Slow Rotating" leftIcon="pi pi-sliders-h" visible={ settingAtom.showObsolete }>
					<PageSlowRotatingArticles customerID={ customerID } />
				</TabPanel>

				<TabPanel header="Nuovi" leftIcon="pi pi-angle-double-up" visible={ settingAtom.showNewArticles }>
					<PageNewArticles customerID={ customerID } />
				</TabPanel>

				<TabPanel header="Tutti" leftIcon="pi pi-box" visible={ true }>
					<PageAllArticles customerID={ customerID } />
				</TabPanel>
			</TabView>
		</Dialog>
	);
};