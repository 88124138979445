import React from 'react';

import { useLog } from '@hooks/useLog';

import { CardChartMonthSales } from '@layouts/desktop/pages/private/dashboard/CardChartMonthSales';
import { useDashboardInfo } from '@hooks/dashboard/useDashboardInfo';

import { useImmer } from 'use-immer';
import { TableCustomersRank } from '@layouts/desktop/pages/private/dashboard/TableCustomersRank';
import { GetDashboardCustomerInfo } from '@dto/Responses/Dashboard/GetDashboardResponse';
import { CardHeaderStats } from '@layouts/desktop/pages/private/dashboard/CardHeaderStats';

import { List } from 'immutable';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { useMount } from '@libs/KzHooks/useMount';

import './Dashboard.scss';



export const Dashboard: React.FC = () => {
    const log = useLog();

    const { isLoading, dashboardInfo } = useDashboardInfo();

    const [ ordersAmounts, setOrdersAmounts ] = useImmer<List<number>>(List());
    const [ ordersCounts, setOrdersCounts ] = useImmer<List<number>>(List());

    const [ bestCustomers, setBestCustomers ] = useImmer<List<GetDashboardCustomerInfo>>(List());
    const [ worstCustomers, setWorstCustomers ] = useImmer<List<GetDashboardCustomerInfo>>(List());



    useMount(() => {
        log.clear();
        log.info("Fetching dashboard info...");
    });



    useUpdate(() => {
        if (isLoading)
            return;

        setOrdersAmounts(List(dashboardInfo?.vendite_mensili?.map(x => x.valore_ordini) ?? []));

        setOrdersCounts(List(dashboardInfo?.vendite_mensili?.map(x => x.numero_ordini) ?? []));

        setBestCustomers(List(dashboardInfo?.top_clienti ?? []));

        setWorstCustomers(List(dashboardInfo?.worst_clienti ?? []));
    }, [ dashboardInfo, isLoading ]);



    return (
        <>
            <div className="dashboard content-section implementation">
                <CardHeaderStats ordersAmount={ordersAmounts} ordersCount={ordersCounts}/>

                <div className="card">
                    <CardChartMonthSales ordersAmount={ordersAmounts} ordersCount={ordersCounts} />
                </div>

                <div className="card">
                    <div className="grid">
                        <div className="col">
                            <h5>Top 10 clienti</h5>
                            <TableCustomersRank customers={bestCustomers} />
                        </div>

                        <div className="col">
                            <h5>Bottom 10 clienti</h5>
                            <TableCustomersRank customers={worstCustomers} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
