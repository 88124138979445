import { ArticlesService } from '@services/ArticlesService';
import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { GetArticleResponse } from '@dto/Responses/Articles/GetArticleResponse';
import { PagedData } from '@models/PagedData';


const DEFAULT_ARTICLES_PER_PAGE = 25;


//const articlesService = new ArticlesService();    // Se inizializzo qua, viene caricato una volta, all'avvio dell'app anche se non ho richiamato sta funzione (ad esempio nel login)


export type ArticleRequest = {
	PageNumber: number,
	PageSize: number,
	Article?: string,
}


export const defaultArticleRequest: ArticleRequest = {
	PageNumber : 1,
	PageSize : DEFAULT_ARTICLES_PER_PAGE,
}



export const useArticlesList = (ar: ArticleRequest) => {
	const log = useLog();

	const articlesService = useSingleton<ArticlesService>(() => new ArticlesService(), []);

	const queryInfo = useQuery<PagedData<GetArticleResponse>>({
		queryKey: ['list-articles', ar],
		queryFn: async () => articlesService.listArticles(ar.PageNumber, ar.PageSize),
		keepPreviousData: true
	});


	return {
		...queryInfo,
		products: queryInfo.data?.data ?? new Array<GetArticleResponse>(),
		totalRecords: queryInfo.data?.TotalRecords ?? 0,
	}
};
