/* eslint-disable unicorn/switch-case-braces */

import { BaseLogger, LogLevelEnum, LogMessage } from './BaseLogger';
//import * as colors from './yoctocolors';  // Super fast alternativa a Chalk, sempre di sindresorhus
import pc from './picocolors/picocolors.browser';


type ConsoleMethods = 'log' | 'debug' | 'info' | 'error' | 'warn';



// LogMessage deve esser diverso da "" altrimenti non viene stampato nulla
export class KzLogger extends BaseLogger {
    private readonly loggerName: string;
    private readonly printObjectOnNewLine: boolean;


    
    constructor(loggerName?: string, printObjectOnNewLine = false, showStacktrace = true) {
        super(showStacktrace);

        this.loggerName = loggerName ?? '';
        this.printObjectOnNewLine = printObjectOnNewLine;
    }



    private prepareHeader(eventDetails: LogMessage): string {
        //console.log('%cThis text is red!', 'color: red; border-width:1px; border-style:solid; border-color:#FF0000;');

        const functionName = eventDetails.Callee?.getFunctionName() ?? "anonymous()";

        let header = '';
        let logHeader = '';

        switch (eventDetails.Level) {
            case LogLevelEnum.TRACE:
                logHeader += BaseLogger.canColorLog() ? pc.bold(pc.bgMagenta(pc.white('[T]'))) : '[T]';
                break;

            case LogLevelEnum.DEBUG:
                logHeader += BaseLogger.canColorLog() ? pc.bold(pc.bgBlack(pc.white('[D]'))) : '[D]';
                break;

            case LogLevelEnum.INFO:
                logHeader += BaseLogger.canColorLog() ? pc.bold(pc.bgBlue(pc.white('[I]'))) : '[I]';
                break;

            case LogLevelEnum.WARN:
                logHeader += BaseLogger.canColorLog() ? pc.bold(pc.bgYellow(pc.black('[W]'))) : '[W]';
                break;

            case LogLevelEnum.ERROR:
                logHeader += BaseLogger.canColorLog() ? pc.bold(pc.red('[E]')) : '[E]';
                break;

            case LogLevelEnum.FATAL:
                logHeader += BaseLogger.canColorLog() ? pc.bold(pc.white(pc.bgRed('[F]'))) : '[F]';
                break;

            default:
                break;
        }

        // Logger senza nome => NomeFile.tsx::onFunctionName
        if (this.loggerName === "") {
            const filename = eventDetails.Callee?.fileName?.slice(eventDetails.Callee?.fileName?.lastIndexOf('/') +1) ?? '';

            const lastDot = filename.lastIndexOf('.');
            const name = filename.slice(0, lastDot);
            const ext = filename.slice(lastDot + 1).toUpperCase();

            if (BaseLogger.canColorLog())
                header = (ext === "TSX") ? `[${pc.green(name)}.${pc.bold(functionName)}]` : `[${pc.red(name)}.${pc.bold(functionName)}]`;
            else
                header = `[${name}.${functionName}]`;
        }
        else {
            header += BaseLogger.canColorLog() ? `[${pc.blue(this.loggerName)}] ${pc.bold(functionName)}` : `[${this.loggerName}] ${functionName}`;
        }

        const PADDING_SIZE = BaseLogger.canColorLog() ? 80 : 30;

        return `${logHeader} ${header}`.padEnd(PADDING_SIZE, ' ');
    }



    protected formatMessage(eventDetails: LogMessage): void {
        // Righe vuote? NO log (da metter come flag?!)
        if (!super.canPrintLog(eventDetails))
            return;

        const header = this.prepareHeader(eventDetails);
        let fullMessage = '';

        switch (eventDetails.Level) {
            case LogLevelEnum.DEBUG: {
                fullMessage = BaseLogger.canColorLog() ? `${header} :: ${pc.gray(eventDetails.Messages.join(" "))}` : `${header} :: ${eventDetails.Messages.join(" ")}`;
                this.printLog('debug', fullMessage, eventDetails.Objects);

                break;
            }

            case LogLevelEnum.INFO: {
                fullMessage = BaseLogger.canColorLog() ? `${header} :: ${pc.blue(eventDetails.Messages.join(" "))}` : `${header} :: ${eventDetails.Messages.join(" ")}`;
                this.printLog('info', fullMessage, eventDetails.Objects);

                break;
            }

            case LogLevelEnum.WARN: {
                fullMessage = BaseLogger.canColorLog() ? `${header} :: ${pc.red(eventDetails.Messages.join(" "))}` : `${header} :: ${eventDetails.Messages.join(" ")}`;
                this.printLog('warn', fullMessage, eventDetails.Objects);

                break;
            }

            case LogLevelEnum.ERROR: {
                fullMessage = BaseLogger.canColorLog() ? `${header} :: ${pc.red(eventDetails.Messages.join(" "))}` : `${header} :: ${eventDetails.Messages.join(" ")}`;
                this.printLog('error', fullMessage, eventDetails.Objects);

                break;
            }

            case LogLevelEnum.FATAL: {
                fullMessage = BaseLogger.canColorLog() ? `${header} :: ${pc.bgRed(pc.white(eventDetails.Messages.join(" ")))}` : `${header} :: ${eventDetails.Messages.join(" ")}`;
                this.printLog('error', fullMessage, eventDetails.Objects);

                break;
            }

            default: {
                console.log("No log level defined!");
            }
        }
    }



    protected printLog(logLevel: ConsoleMethods, fullMessage: string, objects?: unknown[]): void {
        if (this.printObjectOnNewLine) {
            console[logLevel](fullMessage);

            for (const o of objects ?? [])
                console[logLevel](BaseLogger.canColorLog() ? o : JSON.stringify(o));
        }
        else {
            if (objects)
                console[logLevel](fullMessage, ...objects );
            else
                console[logLevel](fullMessage);
        }
    }

    

    public logException(ex: Error): void {
        if (BaseLogger.isProduction())
            return;

        switch (true) {
            default: {
                console.info("---------------------------------------------------------------------------------------------");
                console.error(`[UNKNOWN EX] ${ex.stack ?? "Unknown exception"}`);
                console.info("---------------------------------------------------------------------------------------------");

                break;
            }
        }


        /**
         *  Metodo alternativa per verificare l'istanza dell'exception
        switch (ex.constructor) {
            case ApiException:
                break;

            case WebException:
                break;

            default:
                break;
        }
        */
    }
}