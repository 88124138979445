import { GShopPagedResponse } from '@dto/Responses/GShopPagedResponse';


export class PagedData<T> {
	public data: T[];

	public CurrentPage!: number;
	public PageSize!: number;
	public TotalPages!: number;
	public TotalRecords!: number;


	public constructor(data: T[], pagedResponse: GShopPagedResponse<T>) {
		this.data = data;

		this.CurrentPage = pagedResponse.currentpage;
		this.PageSize = pagedResponse.pagesize;
		this.TotalPages = pagedResponse.totalpages;
		this.TotalRecords = pagedResponse.totalrecords;
	}
}