import { RecipientsMapper } from '@core/mappers/RecipientsMapper';
import { GetRecipientResponse } from '@dto/Responses/Recipients/GetRecipientResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';
import { Recipient } from '@models/Recipient';
import { RECIPIENTS } from '@transport/ApiDefinitions';

import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class RecipientsService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('RecipientsService');
		this.httpEngine = new GShopHttpEngine();
	}



	public async listRecipients(): Promise<Recipient[]> {
		this.log.debug(`Fetching Recipients`);

		try {
			const responseDTO: GetRecipientResponse[] = await this.httpEngine.getAsArray<GetRecipientResponse>(GetRecipientResponse, RECIPIENTS.LIST);

			const Recipients = responseDTO.map(gcr => RecipientsMapper.toRecipientModel(gcr));

			return Recipients;
		}
		catch (ex) {
			this.log.logException(ex);

			throw ex;
		}
	}



	public async getRecipientInfo(recipientID: number): Promise<Recipient | undefined> {
		this.log.debug(`Fetching recipient #${recipientID} info`);

		const url = RECIPIENTS.GET.replace('{recipientID}', recipientID.toString());

		const responseDTO: GetRecipientResponse[] = await this.httpEngine.getAsArray<GetRecipientResponse>(GetRecipientResponse, url);

		const recipients = responseDTO.map(gcr => RecipientsMapper.toRecipientModel(gcr));

		const recipient: Recipient | undefined = recipients.shift();

		return recipient;
	}
}