import { GetCountRegistryResponse } from '@dto/Responses/Dashboard/GetCountRegistryResponse';
import { GetDashboardResponse } from '@dto/Responses/Dashboard/GetDashboardResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';

import { STATISTICS } from '@transport/ApiDefinitions';

import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class DashboardService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('DashboardService');

		this.httpEngine = new GShopHttpEngine();
	}



	public async getDashboardData(): Promise<GetDashboardResponse> {
		this.log.debug(`Getting dashboard info`);

		const responseDTO: GetDashboardResponse = await this.httpEngine.get<GetDashboardResponse>(GetDashboardResponse, STATISTICS.DASHBOARD);

		// Anche qui, analogamento al backend, se ho un DTO che non mi serve niente nel DDD del frontend, è inutile che vada a mapparlo. Uso diretto il responseDTO

		return responseDTO;
	}



	public async getCountRegistry(): Promise<GetCountRegistryResponse> {
		this.log.debug(`Getting registry count (customers / articles)`);

		const responseDTO: GetCountRegistryResponse = await this.httpEngine.get<GetCountRegistryResponse>(GetCountRegistryResponse, STATISTICS.COUNT_REGISTRY);

		return responseDTO;
	}
}