import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';

import './About.scss';
import React from 'react';



const About: React.FC = () => {
	return (
		<div className="about-panel">
			<div className="card">
				<h5>GShop by Claudio - { dayjs(__BUILD_DATE__).format('YYYY-MM-DD @ HH.mm') }</h5>

				<Accordion activeIndex={ 0 }>
					<AccordionTab header="Origine">
						<b>WebApp per GeminiXP .NET</b>
						<p>La comodità e la praticità di un eCommerce su misura nel 2022</p>
						<p>GShop v2 nasce da uno studio sull utilizzo del gestionale appositamente eseguito sugli agenti</p>
						<br />
						<p>Non è un errore, è volutamente lasciata scritta questo commento :)</p>
						<p>La cosa divertente è che questa sezione della WebApp non verrà letta da nessun utente, come le condizioni che si accettano quando si installano i programmi</p>
						<p>Ogni tanto i programmi per non risultare troppo freddi e distaccati devono dare un tocco di umorismo, ed è quello che vuol esser questo paragrafo</p>
						<p>Probabilmente potrei inserire in futuro qualche foto di una tetta per verificare se questa pagina è stata almeno aperta una volta :)</p>
					</AccordionTab>

					<AccordionTab header="Utilizzo">
						<p>A complicare le cose son capaci tutti, la vera abilità sta nel semplificarle e renderle accessibili a tutti</p>
						<p>Etichette autoesplicative :)</p>
						<p>GShop v2 fornisce poche essenziali funzionalità che permettono di padroneggiare in tempo zero GeminiXP .NET</p>
						<p>I menù sono comodamente accessibili dalla sidebar a sinistra ed il core si concentra tutto nella parte più interessante, ovvero la creazione degli ordini per i clienti</p>
						<p>In caso di dubbi contattare claudio@gigashop.it</p>
					</AccordionTab>

					<AccordionTab header="Licenza">
						<p>Componente strettamente dipendente dal modulo GShop assieme a GeminiXP .NET</p>
						<p>Per l utilizzo di esso è necessario avere una licenza di GeminiXP .NET con l abilitazione all eCommerce</p>
						<p>E vietata la riproduzione anche parziale delle funzionalità presentate, senza un consenso dalla Gigashop SRL</p>
					</AccordionTab>

					<AccordionTab header="Changelog">
						<p>[2024-07-19] v0.996 version</p>
						<ul>
							<li>[ADD] Possibilita di nascondere #acquisti</li>
							<li>[ADD] Possibilita di nascondere # acquisti passati</li>
						</ul>


						<p>[2024-07-16] v0.994 version</p>
						<ul>
							<li>[ADD] Preferiti degli anni in UNICO tab</li>
							<li>[MOD] Rimossa colonna Giacenza su Tinte</li>
							<li>[BUG] Filtro per ricerca descrizione o articolo vuoti</li>
						</ul>


						<p>[2024-07-11] v0.993 version</p>
						<ul>
							<li>[ADD] Possibilita di nascondere articoli Slowrotating</li>
							<li>[ADD] Possibilita di nascondere articoli Obsoleti</li>
							<li>[ADD] Possibilita di nascondere articoli NUOVI</li>
						</ul>

						<p>[2024-05-11] v0.992 version</p>
						<ul>
							<li>[ADD] Data ultimo acquisto sui preferiti</li>
							<li>[ADD] Tinte Colori</li>
						</ul>

						<p>[2023-12-19] v0.987 version</p>
						<ul>
							<li>[ADD] Elenco ordini inevasi su creazione nuovo ordine</li>
							<li>Aggiornata tutta la toolchain di sviluppo</li>
						</ul>

						<p>[2022-12-20] v0.962 version</p>
						<ul>
							<li>[MOD] Lo sconto massimo ha la priorit&agrave; su tutto, anche sullo sconto cliente</li>
						</ul>

						<p>[2022-11-07] v0.96 version</p>
						<ul>
							<li>[FIX] Modifica sconti multipli (es. 8+5) su carrello</li>
						</ul>

						<p>[2022-10-28] v0.95 version</p>
						<ul>
							<li>[FIX] Caricamento submenu sidebar</li>
						</ul>

						<p>[2022-07-06] v0.94 version</p>
						<ul>
							<li>[ADD] Sidebar nascosta</li>
						</ul>

						<p>[2022-05-11] v0.93 version</p>
						<ul>
							<li>[FIX] Layout carrello</li>
							<li>[ADD] Sconto inferiore a quello di listino</li>
						</ul>

						<p>[2022-04-07] v0.92 public version</p>
						<ul>
							<li>[FIX] Problema scontistica listini clienti</li>
							<li>[FIX] Problema NON scontabile listini clienti</li>
						</ul>

						<p>[2022-01-07] v0.9 Beta version</p>
						<ul>
							<li>Upgrade toolkit</li>
							<li>Pronto al rilascio di una beta</li>
							<li>Scrittura deployment</li>
						</ul>

						<p>[2021-12-31] v0.8 Alpha version</p>
						<ul>
							<li>Frontend:</li>
							<li>Statistiche base</li>
							<li>Statistiche custom</li>
						</ul>

						<p>[2021-12-20] v0.7 Alpha version</p>
						<ul>
							<li>Frontend:</li>
							<li>Dashboard</li>
							<li>Creazione Ordini</li>
							<li>Ricerca articoli preferiti</li>
						</ul>

						<p>[2021-11-27] v0.6 Alpha version</p>
						<ul>
							<li>Backend:</li>
							<li>Endpoint listini</li>
							<li>Endpoint articoli preferiti</li>
							<li>Endpoint dashboard</li>
						</ul>

						<p>[2021-11-15] v0.5 Alpha version</p>
						<ul>
							<li>Chiusura sviluppo backend</li>
							<li>Endpoint clienti</li>
							<li>Endpoint agenti</li>
							<li>Endpoint lista ordini</li>
						</ul>

						<p>[2021-10-02] v0.4b Alpha version</p>
						<ul>
							<li>Autenticazione</li>
						</ul>

						<p>[2021-09-23] v0.4 Alpha version</p>
						<ul>
							<li>Restyling grafico anagrafiche</li>
							<li>Migrazione toolchain di sviluppo</li>
							<li>Design dashboard</li>
						</ul>

						<p>[2021-08-30] v0.3 Alpha version</p>
						<ul>
							<li>Implementazione backend ecommerce</li>
						</ul>

						<p>[2021-07-12] v0.2 Alpha version</p>
						<ul>
							<li>Anagrafica Clienti</li>
							<li>Anagrafica Articoli</li>
							<li>Studio Consultazione ordini</li>
						</ul>

						<p>[2021-06-23] v0.1 Alpha version</p>
						<ul>
							<li>Struttura web</li>
						</ul>

						<p>[2021-06-22] Prototipo WEB</p>
						<ul>
							<li>Selezione colori</li>
						</ul>
					</AccordionTab>
				</Accordion>
			</div>
		</div>
	);
};


export { About };
