import { GShopRequest } from '@dto/Requests/GShopRequest';
import { classToPlain, t } from '@deepkit/type';



export class GetSalesBySubcategoryRequest implements GShopRequest {
    @t public codicecliente?: number;


    public constructor(customerID?: number) {
        this.codicecliente = customerID;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(GetSalesBySubcategoryRequest, this);

        return js;
    }
}
