import { t } from '@deepkit/type';
import { GShopResponse } from '@dto/Responses/GShopResponse';



export class GetSettingsResponse extends GShopResponse {
    @t public multiple_quantity!: boolean;
    @t public discounts_editables!: boolean;
    @t public allow_under_discounts!: boolean;
    @t public prices_editables!: boolean;
    @t public voc_grouped_mode!: string;
    @t public mark_special_row!: boolean;
    @t public type_description!: string;
    @t public show_categories!: boolean;
    @t public show_um!: boolean;
    @t public use_multiply_coefficient!: boolean;
    @t public split_column_price_discount!: boolean;
    @t public view_existence_by_deposit!: boolean;
    @t public days_article_is_obsolete!: number;
    @t public days_article_is_new!: number;
    @t public merge_favorites!: boolean;
    @t public favorites_max_year!: number;
    @t public show_new_articles!: boolean;
    @t public show_obsolete!: boolean;
    @t public show_column_freq_acq!: boolean;
    @t public sidebar_vertical!: boolean;

    @t.array(String)  public modules_available!: string[];
}
