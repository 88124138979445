import { ArticlesService } from '@services/ArticlesService';
import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { GetCategoriesResponse } from '@dto/Responses/Articles/GetCategoriesResponse';



export const useFavoritesCategoriesList = (customerID: number) => {
	const log = useLog();

	const articlesService = useSingleton<ArticlesService>(() => new ArticlesService(), []);

	const queryInfo = useQuery<GetCategoriesResponse[]>({
		queryKey: ['list-favorites-categories', customerID],
		queryFn: async () => articlesService.listCustomerFavoritesCategories(customerID)
	});

	return {
		...queryInfo,
		categories: queryInfo.data ?? new Array<GetCategoriesResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
