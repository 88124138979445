import React, { ReactNode } from 'react';
import { List } from 'immutable';
import { DataFormatters } from '@guiutility/DataFormatters';


type CardHeaderStatsProps = {
	ordersAmount: List<number>,
	ordersCount: List<number>,
	children?: ReactNode
}



export const CardHeaderStats: React.FC<CardHeaderStatsProps> = ({ ordersAmount, ordersCount }: CardHeaderStatsProps) => {

	const totalOrdersAmount: number = ordersAmount.reduce((totalSum, current) => totalSum + current, 0);
	const totalOrdersCount: number = ordersCount.reduce((totalCount, current) => totalCount + current, 0);


	return (
		<div className="grid dashboard-header">
			<div className="col">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Carrelli in attesa</span>
							<div className="text-900 font-medium text-xl">0</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '3rem', height: '3rem' }}>
							<i className="pi pi-shopping-cart text-blue-500 text-xl"/>
						</div>
					</div>
					{/*<span className="text-green-500 font-medium">+3 nuovi </span>*/}
					{/*<span className="text-500">rispetto alla settimana scorsa</span>*/}
				</div>
			</div>
			<div className="col">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Ordini totali</span>
							<div className="text-900 font-medium text-xl">{totalOrdersCount}</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '3rem', height: '3rem' }}>
							<i className="pi pi-inbox text-orange-500 text-xl"/>
						</div>
					</div>
					{/*<span className="text-green-500 font-medium">+25% </span>*/}
					{/*<span className="text-500">rispetto alla settimana scorsa</span>*/}
				</div>
			</div>
			<div className="col">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Totale ordinato annuale</span>
							<div className="text-900 font-medium text-xl">{DataFormatters.formatPrice(totalOrdersAmount)}</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '3rem', height: '3rem' }}>
							<i className="pi pi-euro text-cyan-500 text-xl"/>
						</div>
					</div>
					{/*<span className="text-500">&nbsp;</span>*/}
				</div>
			</div>
		</div>
	);
};