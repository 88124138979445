/* eslint-disable unicorn/consistent-function-scoping */

import { DescriptionType } from '@atoms/SettingsAtom';
import { ArticlesMapper } from '@core/mappers/ArticlesMapper';
import { paginatorTemplate } from '@desktop/components/commons/DataTablePaginator';

import { stockBodyTemplate } from '@desktop/components/products/DataTableTemplates';
import { GalleryProducts } from '@desktop/components/products/GalleryProducts';
import { GetArticleResponse } from '@dto/Responses/Articles/GetArticleResponse';
import { GetArticleWithDiscounts } from '@dto/Responses/Articles/GetArticleWithDiscountsResponse';
import { GetFavoritesArticleWithDiscounts } from '@dto/Responses/Articles/GetFavoritesArticleWithDiscountsResponse';
import { GetIvecoArticleWithDiscountsResponse } from '@dto/Responses/Articles/GetIvecoArticleWithDiscountsResponse';
import { DataFormatters } from '@guiutility/DataFormatters';

import { useLog } from '@hooks/useLog';
import { useSettings } from '@hooks/useSettings';

import { orderSelectedArticlesAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderSelectedArticlesAtom';
import { CategoryItem } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/CategoryItem';
import { CustomFilters } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/CustomFilters';
import { FilterType } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/FilterType';
import { OverlayExistencesArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/OverlayExistencesArticles';
import { TableType } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/TableType';
import { useMount } from '@libs/KzHooks/useMount';
import { ArticleRow } from '@services/CartService';
import dayjs from 'dayjs';
import { List } from 'immutable';
import { useAtom } from 'jotai';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable, DataTableFilterEvent, DataTableFilterMeta, DataTableFilterMetaData, DataTablePageEvent, DataTableProps, DataTableSelectionMultipleChangeEvent, DataTableSortEvent } from 'primereact/datatable';
import { Galleria } from 'primereact/galleria';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tag } from 'primereact/tag';
import React, { ReactNode, useRef, useState } from 'react';
import { useImmer } from 'use-immer';



type TIPO_VALORI_ARRAY = GetArticleWithDiscounts[] | GetArticleResponse[] | ArticleRow[];
type TIPO_VALORI = GetArticleWithDiscounts | GetArticleResponse | ArticleRow;



type TableSelectArticlesProps = {
	products: TIPO_VALORI_ARRAY,
	categories: CategoryItem[],
	pageSize: number,
	isLoading: boolean,
	tableType: TableType,
	totalRecords: number,
	onPageChangedHandler: (currentPage: number, rowsPerPage: number) => void,
	onSortHandler: (fieldName: string, sortMode: number) => void,
	onFilterHandler: (filters: FilterType[]) => void,
	onCustomFilterHandler: (filterName: CustomFilters, filterValue: string[]) => void,
	children?: ReactNode
}



/**
 * Template per la colonna prezzo che include anche lo sconto (nel caso usi il layout con colonne unite)
 * @param rowData
 * @param useMultiplyCoefficient
 * @param showPrezzoMedio
 * @returns {ReactNode}
 */
const customPriceBodyTemplate = (rowData: GetArticleWithDiscounts, useMultiplyCoefficient: boolean, showPrezzoMedio: boolean = false): ReactNode => {
	// Se voglio mostrare il prezzo medio
	if (showPrezzoMedio)
		return (
			<span>
				~ { DataFormatters.formatPrice(rowData.prezzo_medio) }
			</span>
		);

	// Se il cliente non ha scontistiche o prezzi personalizzati, mostro il suo prezzo di listino
	if (rowData.prezzo_personalizzato === 0 && rowData.sconto_personalizzato === '') {
		return (
			<span>
				{ ( useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0 ) ? DataFormatters.formatPrice(rowData.prezzo_listino * rowData.coefficente_moltiplicativo) : DataFormatters.formatPrice(rowData.prezzo_listino) }
			</span>
		);
	}

	return (
		<span>
			{ (rowData.prezzo_personalizzato === 0 && rowData.sconto_personalizzato !== '') && <Tag rounded severity="success" value={ rowData.sconto_personalizzato + '%' } /> }

			<span className="outofstock">
				{ ( useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0 ) ? DataFormatters.formatPrice(rowData.prezzo_listino * rowData.coefficente_moltiplicativo) : DataFormatters.formatPrice(rowData.prezzo_listino) }
			</span>

			<span className="instock">
				{ ( useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0 ) ? DataFormatters.formatPrice(rowData.prezzo_scontato * rowData.coefficente_moltiplicativo) : DataFormatters.formatPrice(rowData.prezzo_scontato) }
			</span>
		</span>
	);
};



/**
 * Template per la colonna sconto (qualora scelga di splittare prezzo + sconto)
 * @param rowData
 * @returns {ReactNode}
 */
const customDiscountBodyTemplate = (rowData: GetArticleWithDiscounts): ReactNode => {
	// Se il cliente NON HA prezzi personalizzati (aka fissi) mostro lo sconto personalizzato

	return (
		<span>
			{ (rowData.prezzo_personalizzato === 0 && rowData.sconto_personalizzato !== '') && <Tag rounded severity="success" value={ rowData.sconto_personalizzato + '%' } /> }
		</span>
	);
};



/**
 * Template per la colonna prezzo (qualora scelga di splittare prezzo + sconto)
 * @param rowData
 * @param useMultiplyCoefficient
 * @param showPrezzoMedio
 * @returns {ReactNode}
 */
const customOnlyPriceBodyTemplate = (rowData: GetArticleWithDiscounts, useMultiplyCoefficient: boolean, showPrezzoMedio: boolean = false): ReactNode => {
	// Se voglio mostrare il prezzo medio
	if (showPrezzoMedio)
		return (
			<span>
				~ { DataFormatters.formatPrice(rowData.prezzo_medio) }
			</span>
		);

	// Se il cliente non ha scontistiche o prezzi mostro il prezzo di listino
	if (rowData.prezzo_personalizzato === 0 && rowData.sconto_personalizzato === '') {
		return (
			<span>
			{ ( useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0 ) ? DataFormatters.formatPrice(rowData.prezzo_listino * rowData.coefficente_moltiplicativo) : DataFormatters.formatPrice(rowData.prezzo_listino) }
			</span>
		);
	}

	// Se il cliente HA prezzo fisso
	if (rowData.prezzo_personalizzato !== 0) {
		return (
			<span className="discountedprice">
			{ ( useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0 ) ? DataFormatters.formatPrice(rowData.prezzo_personalizzato * rowData.coefficente_moltiplicativo) : DataFormatters.formatPrice(rowData.prezzo_personalizzato) }
			</span>
		);
	}

	// Se ha prezzi scontati, e mostro le colonne splittate (sconto + prezzo) mostro solo il prezzo scontato enfatizzato con uno stile
	return (
		<span className="discountedprice">
			{ ( useMultiplyCoefficient && rowData.coefficente_moltiplicativo !== 0 ) ? DataFormatters.formatPrice(rowData.prezzo_scontato * rowData.coefficente_moltiplicativo) : DataFormatters.formatPrice(rowData.prezzo_scontato) }
		</span>
	);
};



/**
 * Template colonna descrizione
 * @param rowData
 * @returns {ReactNode}
 */
const categoriesBodyTemplate = (rowData: GetArticleWithDiscounts): ReactNode => {
	return (
		<span>
			{ rowData.descrizione_categoria ?? 'Nessuna' }
		</span>
	);
};



/**
 * Template per il filtro dropdown per la selezione della categoria
 * @param item
 * @returns {ReactNode}
 */
const categoryItemTemplate = (item: CategoryItem): ReactNode => {
	return (
		<span>
			{ `[${ item.categoryCode }] ${ item.categoryDescription }` }
		</span>
	);
};



const defaultSort: DataTableSortEvent = {
	sortField    : '',
	sortOrder    : 0,
	multiSortMeta: undefined,
};


const defaultFilter: DataTableFilterMeta = {
	articolo   : { value: '', matchMode: 'contains' },
	descrizione: { value: '', matchMode: 'contains' },
	categoria  : { value: '', matchMode: 'contains' },
};


export const TableSelectArticles: React.FC<TableSelectArticlesProps> = ({
																			products, categories, pageSize, isLoading, tableType, totalRecords, onPageChangedHandler, onSortHandler, onFilterHandler,
																			onCustomFilterHandler,
																		}: TableSelectArticlesProps) => {
	const log = useLog();

	const [ firstRowDisplayed, setFirst ] = useState<number>(0);
	const [ selectedProducts, setSelectedProducts ] = useAtom(orderSelectedArticlesAtom);

	const { settingsState } = useSettings();

	const dt = useRef<DataTable<TIPO_VALORI_ARRAY>>(null);

	const [ sortParams, setSortParams ] = useImmer<DataTableSortEvent>(defaultSort);
	const [ filterParams, setFilterParams ] = useImmer<DataTableFilterMeta>(defaultFilter);

	const [ selectedCategories, setSelectedCategories ] = useImmer<Array<CategoryItem>>([]);

	const [ lastSelectedArticle, setLastSelectedArticle ] = useImmer<string>('');

	const refOverlayExistencesArticles = useRef<OverlayPanel>(null);
	const refGallery = useRef<Galleria>(null);


	//#region SIDE-Effects (useEffect)

	useMount(() => {
		log.info('Loading tableSelectArticles...');
	});

	//#endregion



	//#region EVENTS HANDLERS


	/**
	 * Selezione categoria => raise dell'evento onCustomFilter()
	 * @param event
	 */
	const onCategoryFilterChange = (event: MultiSelectChangeEvent) => {
		dt?.current?.filter(event.value, 'categoryCode', 'equals');

		setSelectedCategories(event.value);

		const cats = ( event.value.length > 0 ) ? event.value.map((x: any) => x) : [];

		onCustomFilterHandler(CustomFilters.CATEGORIA, cats);
	};



	//#region Eventi griglia (onPage, onSort, onFilter)
	const onPaginator = (e: DataTablePageEvent): void => {
		onPageChangedHandler(e.page ?? 1, e.rows);

		setFirst(e.first);
	};



	const onSort = (e: DataTableSortEvent): void => {
		onSortHandler(e.sortField, e.sortOrder ?? 0);

		setSortParams((draft: any) => {
			draft.sortField = e.sortField;
			draft.sortOrder = e.sortOrder;
		});
	};



	const onFilter = (e: DataTableFilterEvent): void => {
		const filters: FilterType[] = new Array<FilterType>();

		for (const f of Object.entries(e.filters)) {
			const fieldName = f[0];
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			const fieldValue = ( f[1] as DataTableFilterMetaData ).value;

			filters.push({
							 field: fieldName,
							 value: fieldValue,
						 });
		}

		// Filtri che verranno usati per fare la query sull'endpoint
		onFilterHandler(filters);

		// Object che viene usato sulla PrimeReact DataTable per avere coerenza del testo nel filtro, l'icona del filtro attivo, ecc ecc
		setFilterParams(e.filters);
	};
	//#endregion



	/**
	 * Le righe selezionate NON corrispondono al model delle righe della griglia, ma sono INTEGRATE con le quantità personalizzate dall'utente.
	 * Quindi vanno gestite in modo differente, ovvero ho una List<SelectedRow> apposita diversa di GetArticleWithDiscounts[] che ha qta/pzconf/ecc personalizzabili dall'agente
	 * @param e lista di righe attualmente selezionate
	 */
	const onSelectionChange = (e: DataTableSelectionMultipleChangeEvent<TIPO_VALORI_ARRAY>) => {
		//const selectedArticles = e.value.map((a: GetArticleWithDiscounts) => ArticlesMapper.toSelectedArticles(a));
		const currentRowSelected = e.value as unknown as GetArticleWithDiscounts[];
		let selectedArticles = List<ArticleRow>();

		// Per ogni riga selezionata, vado a vedere se esiste una riga editabile (ovvero con quantità inserite). Se non c'è la creo nuova, diversamente nella nuova selezione vado a prender la sua quantità
		for (const gawd of currentRowSelected) {
			const existentEntry = selectedProducts.find(a => a.articolo === gawd.articolo);

			const entry = existentEntry ?? ArticlesMapper.toSelectedArticleRow(gawd, settingsState.useMultiplyCoefficient);

			selectedArticles = selectedArticles.push(entry);
		}

		// e.value passa tutte le righe selezionate. Come faccio a capire qual è l'ultima selezionata?
		// Perchè io quando seleziono una riga, la trasformo in ARticleRow invece che GetArticleWithDiscounts. Quindi per capire quale è stata selezionata, verifico quale delle righe selezionate NON è ArticleRow
		let lastRowSelected: GetArticleWithDiscounts | undefined = undefined;
		for (const t of currentRowSelected) {
			if (!( t instanceof ArticleRow ))
				lastRowSelected = t;
		}

		setSelectedProducts(selectedArticles.toArray());

		if (lastRowSelected != undefined) {
			setLastSelectedArticle(lastRowSelected.articolo);
		}
	};



	/**
	 * Cambio di quantita sulla riga.
	 * Quando cambio la quantita sulla riga, vado innanzitutto a selezionare la riga, in quanto se è variata, vuol dire che è selezionata
	 * @param rowData model GetArticleWithDiscounts della riga
	 * @param qta quantita settata
	 */
	const onRowValueChange = (rowData: GetArticleWithDiscounts, qta: number | undefined | null) => {
		// Per fare tutto l'editing mi appoggio a immutable.js (List)
		// Non posso impostarlo direttamente sull'atom perchè atomWithImmer si aspetta un plain object, non una List[] che non è [Immerable]
		let sp = List(selectedProducts);

		// Cerco se la riga è già presente nell'array delle righe selezionate
		const existentEntry = sp.findEntry(a => a.articolo === rowData.articolo);

		// Se non è presente, vuol dire che non è stata selezionata, quindi la mappo al model adatto alla DataTable per esser selezionato
		const selectedArticle = ( existentEntry == undefined ) ? ArticlesMapper.toSelectedArticleRow(rowData, settingsState.useMultiplyCoefficient) : existentEntry[1];

		selectedArticle.quantita_3 = qta ?? 0;

		// Qualora esista la riga e sia selezionata, aggiorno la quantita di quell'esatta SelectedRow (poi List() si occuperà di creare un nuovo object modificato che viene refreshato da React :) )
		if (existentEntry) {
			// eslint-disable-next-line unicorn/prefer-ternary
			if (qta && qta > 0) {
				sp = sp.set(existentEntry[0], selectedArticle);
			}
			else {
				// Se non ho impostato la quantità o l'ho cancellata deseleziono la riga (aka cancello dalle righe che verrebbero selezionate)
				sp = sp.delete(existentEntry[0]);
			}
		}

		if (!existentEntry && qta) {
			sp = sp.push(selectedArticle);
		}

		setSelectedProducts(sp.toArray());
	};



	/**
	 * Visualizzo l'overlay con le info sul deposito
	 * @param e
	 * @param rowData
	 */
	const onDepositRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, rowData: GetArticleWithDiscounts) => {
		if (!settingsState.viewExistenceByDeposit)
			return;

		// Mostro l'overlay con le info deposito sull'articolo selezionato
		refOverlayExistencesArticles.current?.show(e, e.currentTarget);
	};



	/**
	 * Visualizzo le foto associate all'articolo
	 * @param e
	 * @param rowData
	 */
	const onGalleryRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, rowData: GetArticleWithDiscounts) => {
		log.info(rowData);

		refGallery.current?.show();
	};

	//#endregion



	//#region METHODS


	/**
	 * Formattazione DESCRIZIONE (es: Descrizione, Descrizione + Aggiuntiva, Aggiuntiva)
	 * @param rowData
	 * @returns {string}
	 */
	const descrizioneBodyTemplate = (rowData: GetArticleWithDiscounts) => {
		switch (settingsState.descriptionType) {
			case DescriptionType.DESCRIPTION:
				return rowData.descrizione;

			case DescriptionType.DESCRIPTION_EXTRA:
				return rowData.descrizione_aggiuntiva;

			case DescriptionType.DESCRIPTION_AND_DESCRIPTION_EXTRA: {
				return ( rowData.descrizione_aggiuntiva === '' ) ? rowData.descrizione : `${ rowData.descrizione } - ${ rowData.descrizione_aggiuntiva }`;
			}
		}
	};



	const tintaCodeBodyTemplate = (rowData: GetArticleWithDiscounts) => {
		const [ articleID, variante] = rowData.articolo.split('__');

		return (
			<span>
			{ articleID }
			{ variante && <Tag value={variante} />}
			</span>
		);
	}



	/**
	 * Formattazione data ultima vendita AL cliente (dal lato cliente sarebbe l'ultima volta che l'ha acquistato)
	 * @param rowData
	 * @returns {string}
	 */
	const ultimaVenditaBodyTemplate = (rowData: GetFavoritesArticleWithDiscounts) => {
		return DataFormatters.formatDate(rowData.data_last_acquisto);
	};



	const scadenzaBodyTemplate = (rowData: GetIvecoArticleWithDiscountsResponse) => {
		return (rowData.data_scadenza === "0001-01-01 00:00:00") ? "" : DataFormatters.formatDate(rowData.data_scadenza);
	};



	/**
	 * Icona "I" informazioni sul deposito
	 * @param {GetArticleWithDiscounts} rowData
	 * @returns {React.JSX.Element}
	 */
	const infoDepositBodyTemplate = (rowData: GetArticleWithDiscounts) => {
		return <i className="pi pi-info-circle" style={ { textAlign: 'center', color: 'var(--green-600)', fontWeight: 'bold' } } onClick={ (e) => onDepositRowClick(e, rowData) } />;
	};



	/**
	 * Icona per visualizzare la galleria foto dell'articolo
	 * @param {GetArticleWithDiscounts} rowData
	 * @returns {React.JSX.Element}
	 */
	const photoBodyTemplate = (rowData: GetArticleWithDiscounts) => {
		if (rowData.photoscount > 0)
			return <i className="pi pi-camera" style={ { textAlign: 'center', color: 'var(--cyan-500)', fontWeight: 'bold' } } onClick={ (e) => onGalleryRowClick(e, rowData) } />;

		return <i className="pi pi-camera" style={ { textAlign: 'center', color: 'var(--bluegray-100)', fontWeight: 'bold' } } />;
	};



	/**
	 * Formattazione Quantita (InputNumber con + e - comprensivo di textbox)
	 * @param rowData
	 * @returns {React.JSX.Element}
	 */
	const quantityBodyTemplate = (rowData: GetArticleWithDiscounts) => {
		// Cerco la quantità impostata per quell'articolo (devo fare la ricerca nella mia selezione di righe (SelectedArticle[]), non nel model della griglia (GetArticleWithDiscounts), perchè è in sola lettura e non ha questi dati)
		const selectedArticle = selectedProducts.find(a => a.articolo === rowData.articolo);

		return <InputNumber
			value={ selectedArticle?.quantita_3 ?? undefined }
			onValueChange={ (e) => onRowValueChange(rowData, e.value) }
			showButtons={ true }

			buttonLayout="horizontal"

			min={ 0 }
			step={ 1 }

			incrementButtonIcon="pi pi-plus"
			incrementButtonClassName="p-button-success"

			decrementButtonIcon="pi pi-minus"
			decrementButtonClassName="p-button-danger"

			mode="decimal"

			size={ 1 }

			inputStyle={ { textAlign: 'center' } }
		/>;
	};



	/**
	 * Rendering del Dropdown per il filtro su categoria
	 * @returns {React.JSX.Element}
	 */
	const renderFilterCategories = () => {
		return (
			<MultiSelect className="p-column-filter" value={ selectedCategories } options={ categories } onChange={ onCategoryFilterChange } itemTemplate={ categoryItemTemplate } display="chip" placeholder="Tutte" optionLabel="categoryDescription" optionValue="categoryCode" />
		);
	};



	/**
	 * Formattazione colore riga (new, obsoleto, slowrotating, ecc)
	 * @param data
	 * @returns {{'nostock-row': boolean, 'slowrotating-row': boolean, 'obsolete-articles-row': boolean, 'new-articles-row': boolean} | {}}
	 */
	const formatRow = (data: TIPO_VALORI) => {
		if (!( data instanceof GetArticleResponse ))
			return {};

		return {
			'nostock-row'          : data.esistente_tot <= 0,
			'new-articles-row'     : ( data.data_creazione != undefined ) && ( dayjs().diff(data.data_creazione, 'day') < settingsState.daysArticleNew ),
			'obsolete-articles-row': ( data.data_ultima_vendita != undefined ) && ( dayjs().diff(data.data_ultima_vendita, 'day') > settingsState.daysArticleObsolete ),
			'slowrotating-row'     : data.status == 'O',
		};
	};

	//#endregion



	const categoriesFilterElement = renderFilterCategories();



	const dtParameters: DataTableProps<TIPO_VALORI_ARRAY> = {
		scrollable       : false,
		showGridlines    : true,
		paginator        : true,
		paginatorTemplate: paginatorTemplate,
		lazy             : true,
		totalRecords     : totalRecords,
		loading          : isLoading,
		first            : firstRowDisplayed,           // In base al numero del record, il paginator segna in quale pagina è attualmente posizionato  1 2 > 3 < 4 5 6
		rows             : pageSize,
		emptyMessage     : 'Nessun articolo inserito',
		footer           : '',
		selectionMode    : 'multiple',  // 'multiple' => selezione su tutta la riga | checkbox => selezione solo sulla checkbox. Se metto "checkbox" però non vengono triggerati gli eventi onCell
		selection        : selectedProducts,
		dataKey          : 'articolo',
		rowClassName     : formatRow,
		filters          : filterParams,
		filterDisplay    : 'row',
		sortField        : sortParams?.sortField,
		sortOrder        : sortParams?.sortOrder,
		resizableColumns : true,
		columnResizeMode : 'fit',
		cellSelection    : false,		// Warning su questo

		// DataTable Events
		onSelectionChange: onSelectionChange,
		onPage           : onPaginator,
		onSort           : onSort,
		onFilter         : onFilter,
	};


	// Ho due collection differenti, perchè cambiando il numero di colonne, cambio anche la dimensione di esse
	const dtColumnsFavoritesArticles: ColumnProps[] = [
		{ columnKey: 'selectionRow', field: '', selectionMode: 'multiple', headerStyle: { width: '3rem' } },
		{ columnKey: 'infoDeposit', field: '', headerStyle: { width: '3rem' }, body: infoDepositBodyTemplate, bodyStyle: { textAlign: 'center' } },
		{ columnKey: 'photo', field: '', headerStyle: { width: '3rem' }, body: photoBodyTemplate, bodyStyle: { textAlign: 'center' } },

		{ field: 'articolo', header: 'Articolo', style: { width: '10%' }, filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per codice' },
		{ field: 'descrizione', header: 'Descrizione', filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per descrizione', body: descrizioneBodyTemplate },
		{ field: 'categoria', header: 'Categoria', style: { width: '10%' }, sortable: true, filter: true, sortField: 'descrizione_categoria', filterField: 'descrizione_categoria', body : categoriesBodyTemplate, filterElement: categoriesFilterElement },
		{ field: 'unita_di_misura', header: 'UM', style: { width: '5%' }, headerStyle: { width: '5rem' }, className: 'columns-um' },

		{ columnKey: 'qta', field: '', header: 'Qta', headerStyle: { width: '10%' }, className: 'columns-um', body: quantityBodyTemplate },

		{ field: 'frequenza_acquistati', hidden: !settingsState.showColumnFreqAcq, header: '# acquisti', style: { width: '5%' }, sortable: true, className: 'columns-quantity' },
		{ field: 'totale_acquistati', hidden: !settingsState.showColumnFreqAcq, header: 'Acq.Passati', style: { width: '5%' }, sortable: true, className: 'columns-quantity' },
		{ field: 'data_last_acquisto', header: 'Ultimo Acq.', style: { width: '5%' }, sortable: true, className: 'columns-quantity', body: ultimaVenditaBodyTemplate },

		{ field: 'esistente_tot', header: 'Giacenza', style: { width: '5%' }, className: 'columns-quantity', body: stockBodyTemplate },

		// Le colonne (prezzo e sconto) sono ridondanti apparentemente, ma vengono abilitate in base ad un UserSetting (split colonna prezzo/discount)
		{ columnKey: 'split_sconto', field: '', header: 'Sconto', style: { width: '5%' }, className: 'columns-discount', body: customDiscountBodyTemplate },
		{ columnKey: 'split_prezzo', field: '', header: 'Prezzo', style: { width: '5%' }, className: 'columns-price', body: (rowData) => customOnlyPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient) },

		{ columnKey: 'no_split_prezzo', field: '', header: 'Prezzo', style: { width: '12%' }, className: 'columns-price', body: (rowData) => customPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient) },
	];


	const dtColumnsTinteArticles: ColumnProps[] = [
		{ columnKey: 'selectionRow', field: '', selectionMode: 'multiple', headerStyle: { width: '3rem' } },
		{ columnKey: 'infoDeposit', field: '', headerStyle: { width: '3rem' }, body: infoDepositBodyTemplate, bodyStyle: { textAlign: 'center' } },
		{ columnKey: 'photo', field: '', headerStyle: { width: '3rem' }, body: photoBodyTemplate, bodyStyle: { textAlign: 'center' } },

		{ field: 'articolo', header: 'Articolo', style: { width: '10%' }, filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per codice', body: tintaCodeBodyTemplate },
		{ field: 'descrizione', header: 'Descrizione', filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per descrizione', body: descrizioneBodyTemplate },
		{ field: 'riferimenti', header: 'Tinta', filter: false, showFilterMenu: false, sortable: true },

		{ field: 'data_last_acquisto', header: 'Ultimo Acq.', style: { width: '5%' }, sortable: true, className: 'columns-quantity', body: ultimaVenditaBodyTemplate },

		{ columnKey: 'qta', field: '', header: 'Qta', headerStyle: { width: '10%' }, className: 'columns-um', body: quantityBodyTemplate },

		// Le colonne (prezzo e sconto) sono ridondanti apparentemente, ma vengono abilitate in base ad un UserSetting (split colonna prezzo/discount)
		{ columnKey: 'split_sconto', field: '', header: 'Sconto', style: { width: '5%' }, className: 'columns-discount', body: customDiscountBodyTemplate },
		{ columnKey: 'split_prezzo', field: '', header: 'Prezzo', style: { width: '5%' }, className: 'columns-price', body: (rowData) => customOnlyPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient, true) },
		{ columnKey: 'no_split_prezzo', field: '', header: 'Prezzo', style: { width: '12%' }, className: 'columns-price', body: (rowData) => customPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient, true) },
	];


	const dtColumnsIvecoArticles: ColumnProps[] = [
		{ columnKey: 'selectionRow', field: '', selectionMode: 'multiple', headerStyle: { width: '3rem' } },
		{ columnKey: 'infoDeposit', field: '', headerStyle: { width: '3rem' }, body: infoDepositBodyTemplate, bodyStyle: { textAlign: 'center' } },
		{ columnKey: 'photo', field: '', headerStyle: { width: '3rem' }, body: photoBodyTemplate, bodyStyle: { textAlign: 'center' } },

		{ field: 'articolo', header: 'Articolo', style: { width: '10%' }, filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per codice', body: tintaCodeBodyTemplate },
		{ field: 'descrizione', header: 'Descrizione', filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per descrizione', body: descrizioneBodyTemplate },
		{ field: 'lotto', header: 'Lotto', filter: false, showFilterMenu: false, sortable: true },

		{ field: 'data_scadenza', header: 'Scadenza', style: { width: '5%' }, sortable: true, className: 'columns-quantity', body: scadenzaBodyTemplate },

		{ field: 'data_last_acquisto', header: 'Ultimo Acq.', style: { width: '5%' }, sortable: true, className: 'columns-quantity', body: ultimaVenditaBodyTemplate },

		{ columnKey: 'qta', field: '', header: 'Qta', headerStyle: { width: '10%' }, className: 'columns-um', body: quantityBodyTemplate },

		// Le colonne (prezzo e sconto) sono ridondanti apparentemente, ma vengono abilitate in base ad un UserSetting (split colonna prezzo/discount)
		{ columnKey: 'split_sconto', field: '', header: 'Sconto', style: { width: '5%' }, className: 'columns-discount', body: customDiscountBodyTemplate },
		{ columnKey: 'split_prezzo', field: '', header: 'Prezzo', style: { width: '5%' }, className: 'columns-price', body: (rowData) => customOnlyPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient, true) },
		{ columnKey: 'no_split_prezzo', field: '', header: 'Prezzo', style: { width: '12%' }, className: 'columns-price', body: (rowData) => customPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient, true) },
	];


	const dtColumnsAllArticles: ColumnProps[] = [
		{ columnKey: 'selectionRow', field: '', selectionMode: 'multiple', headerStyle: { width: '3rem' } },
		{ columnKey: 'infoDeposit', field: '', headerStyle: { width: '3rem' }, body: infoDepositBodyTemplate, bodyStyle: { textAlign: 'center' } },
		{ columnKey: 'photo', field: '', headerStyle: { width: '3rem' }, body: photoBodyTemplate, bodyStyle: { textAlign: 'center' } },

		{ field: 'articolo', header: 'Articolo', style: { width: '10%' }, filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per codice' },
		{ field: 'descrizione', header: 'Descrizione', filter: true, showFilterMenu: false, sortable: true, filterPlaceholder: 'Cerca per descrizione', body: descrizioneBodyTemplate },
		{ field: 'categoria', header: 'Categoria', style: { width: '11%' }, sortable: true, filter: true, sortField: 'descrizione_categoria', filterField: 'descrizione_categoria', body : categoriesBodyTemplate, filterElement: categoriesFilterElement },
		{ field: 'unita_di_misura', header: 'UM', style: { width: '5%' }, headerStyle: { width: '5rem' }, className: 'columns-um' },

		{ columnKey: 'qta', field: '', header: 'Qta', headerStyle: { width: '10%' }, className: 'columns-um', body: quantityBodyTemplate },

		{ field: 'esistente_tot', header: 'Giacenza', style: { width: '5%' }, className: 'columns-quantity', body: stockBodyTemplate },

		// Le colonne (prezzo e sconto) sono ridondanti apparentemente, ma vengono abilitate in base ad un UserSetting (split colonna prezzo/discount)
		{ columnKey: 'split_sconto', field: '', header: 'Sconto', style: { width: '5%' }, className: 'columns-discount', body: customDiscountBodyTemplate },
		{ columnKey: 'split_prezzo', field: '', header: 'Prezzo', style: { width: '5%' }, className: 'columns-price', body: (rowData) => customOnlyPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient) },

		{ columnKey: 'no_split_prezzo', field: '', header: 'Prezzo', style: { width: '12%' }, className: 'columns-price', body: (rowData) => customPriceBodyTemplate(rowData, settingsState.useMultiplyCoefficient)	},
	];


	let dtColumns = [];
	switch (tableType) {
		case TableType.TINTE_ARTICLES:
			dtColumns = dtColumnsTinteArticles;
			break;

		case TableType.IVECO_ARTICLES:
			dtColumns = dtColumnsIvecoArticles;
			break;

		case TableType.FAVORITES_ARTICLES:
			dtColumns = dtColumnsFavoritesArticles;
			break;

		default:
			dtColumns = dtColumnsAllArticles;
	}

	const showedColumns = dtColumns.map((col, i) => {
		if (col.field === 'categoria' && !settingsState.showCategories)
			return undefined;

		if (col.field === 'unita_di_misura' && !settingsState.showUM)
			return undefined;

		if (col.columnKey === 'split_sconto' && !settingsState.splitColumnPriceDiscount)
			return undefined;

		if (col.columnKey === 'split_prezzo' && !settingsState.splitColumnPriceDiscount)
			return undefined;

		if (col.columnKey === 'no_split_prezzo' && settingsState.splitColumnPriceDiscount)
			return undefined;

		const columnReactKey = col.columnKey == 'selectionRow' ? col.columnKey : col.field;

		return <Column key={ columnReactKey } { ...col } />;
	});


	return (
		<>
			<DataTable { ...dtParameters } ref={ dt } value={ products } selection={ selectedProducts } className="p-datatable-sm table-select-articles">
				{ showedColumns }
			</DataTable>

			<GalleryProducts ref={ refGallery } lastSelectedArticle={ lastSelectedArticle } />

			<OverlayExistencesArticles ref={ refOverlayExistencesArticles } lastSelectedArticle={ lastSelectedArticle } />
		</>
	);
};
