import { CustomerSelection } from '@desktop/components/customers/CustomerSelection';
import { defaultSalesByArticleRequest, SalesByArticleRequest, useSalesByArticleStats } from '@hooks/statistics/custom/ctamonn/useSalesByArticleStats';

import { useLog } from '@hooks/useLog';
import { CardChartSalesByArticle } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesByArticle/CardChartSalesByArticle';

import { CardTableSalesByArticle } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesByArticle/CardTableSalesByArticle';

import { useMount } from '@libs/KzHooks/useMount';

import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';
import React from 'react';
import { useImmer } from 'use-immer';



export const SalesByArticle: React.FC = () => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<SalesByArticleRequest>(defaultSalesByArticleRequest);
	const { isLoading, salesByArticleInfo, totalRecords } = useSalesByArticleStats(reqParams);


	useMount(() => {
		log.clear();
	});



	//#region Eventi CardCustomer: Selezione del cliente dal TreeSelect

	const onSelectedCustomer = (customer: CustomerWithDetails, recipient: Recipient | undefined) => {
		setRequest((draft) => {
			draft.CustomerID = customer.codice;
		});
	};

	//#endregion



	return (
		<>
			<div className="content-section introduction">
				<h1>Statistiche Venduto (per articolo)</h1>
				<p>Analisi prodotti venduti per articolo</p>
			</div>

			<div className="content-section implementation">
				<div className="card">
					<CustomerSelection showOnlyActive={true} onCustomerSelectedHandler={onSelectedCustomer} />
				</div>

				{(salesByArticleInfo.length > 0) &&
                    <div className="card">
                        <CardTableSalesByArticle salesInfo={salesByArticleInfo} isLoading={isLoading} />
                    </div>
				}

				{(salesByArticleInfo.length > 0) &&
                    <div className="card">
                        <CardChartSalesByArticle salesInfo={salesByArticleInfo} isLoading={isLoading} />
                    </div>
				}
			</div>
		</>
	);
};
