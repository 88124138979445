/* eslint-disable unicorn/consistent-function-scoping */
import { useLog } from '@hooks/useLog';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';
import { TreeNode } from 'primereact/treenode';
import { TreeSelect, TreeSelectChangeEvent, TreeSelectEventNodeEvent, TreeSelectProps, TreeSelectSelectionKeysType } from 'primereact/treeselect';
import React, { ReactNode } from 'react';
import { useImmer } from 'use-immer';



class CustomerTreeKey {
	public cliente!: CustomerWithDetails;
	public recipient!: Recipient | undefined;


	constructor(customer: CustomerWithDetails, recipient: Recipient | undefined) {
		this.cliente = customer;
		this.recipient = recipient;
	}
}


type FormatCustomerFunction = (customer: CustomerWithDetails, recipient: (Recipient | undefined)) => string;
type FormatCustomerStyleFunction = (customer: CustomerWithDetails, recipient: (Recipient | undefined)) => Promise<string>;


//#region TreeSelect helpers (createNodes, createTreeKey, createTreeData)
/**
 * createNodes: Trasforma il model CustomerWithDetails in un TreeNode per il <Tree>
 * @returns {TreeNode[]}: model del <Tree>
 * @param customers
 * @param formatCustomer
 * @param formatCustomerStyle
 */
const createNodes = async (customers: CustomerWithDetails[], formatCustomer: FormatCustomerFunction | undefined, formatCustomerStyle: FormatCustomerStyleFunction | undefined): Promise<TreeNode[]> => {
	const ns: TreeNode[] = [];

	for (const c of customers) {
		const customerNode: TreeNode = {};

		customerNode.key = createTreeKey(c, undefined);
		customerNode.label = (formatCustomer) ? formatCustomer(c, undefined) : `[${c.codice}] ${c.ragione_sociale_1}`;
		customerNode.data = createTreeData(c, undefined);
		customerNode.icon = (c.fido == 0) ? "pi pi-user" : "pi pi-times-circle";
		customerNode.className = (formatCustomerStyle) ? await formatCustomerStyle(c, undefined) : "";

		if (c.recipients.length > 0) {
			customerNode.children = [];

			for (const r of c.recipients) {
				const recipientNode: TreeNode = {};
				recipientNode.key = createTreeKey(c, r);
				recipientNode.label = (formatCustomer) ? formatCustomer(c, r) : r.ragione_sociale_1;
				recipientNode.data = createTreeData(c, r);
				recipientNode.icon = "pi pi-home";
				recipientNode.className = (formatCustomerStyle) ? await formatCustomerStyle(c, r) : "";

				customerNode.children.push(recipientNode);
			}
		}

		ns.push(customerNode);
	}

	return ns;
}



/**
 * createTreeKey: Generazione nodeKey
 * @returns {string}: key per il nodo
 * @param customer
 * @param recipient
 */
const createTreeKey = (customer: CustomerWithDetails, recipient: Recipient | undefined): string => {
	const key = (recipient == undefined) ? `${customer.codice}` : `${customer.codice}|${recipient.codice}`;

	return key;
}



/**
 * createTreeData: Generazione del nodeData
 * @param customer - : CustomerWithDetails
 * @param recipient - : Recipient
 * @returns: nodeData del nodo {CustomerTreeKey}
 */
const createTreeData = (customer: CustomerWithDetails, recipient: Recipient | undefined): CustomerTreeKey => {
	const data = new CustomerTreeKey(customer, recipient);

	return data;
}

//#endregion


type CustomerTreeProps = {
	customers: any[],
	customerID?: number,
	recipientID?: number,
	isLoading: boolean,
	onCustomerSelectedHandler: (customer: CustomerWithDetails | undefined, recipient: Recipient | undefined) => void,
	formatCustomer?: (customer: CustomerWithDetails, recipient: Recipient | undefined) => string,
	formatCustomerStyle?: (customer: CustomerWithDetails, recipient: Recipient | undefined) => Promise<string>,
	children?: ReactNode
}


export const CustomerTreeSelect: React.FC<CustomerTreeProps> = ( { isLoading, customers, customerID, recipientID, onCustomerSelectedHandler, formatCustomer, formatCustomerStyle }) => {
	const log = useLog();

	const [ nodes, setNodes ] = useImmer<TreeNode[]>(new Array<TreeNode>());
	const [ selectedCustomerNode, setCustomerNode ] = useImmer<string | TreeSelectSelectionKeysType | TreeSelectSelectionKeysType[] | null | undefined>("");


	// Gestione lista clienti (caricamento e selezione predefinita da un carrello salvato)
	useUpdate(() => {
		if (isLoading)
			return;

		const loadNodes = async () => {
			// Converto il model CustomerwithDetails nel TreeModel (TreeNode[])
			const ns: TreeNode[] = await createNodes(customers, formatCustomer, formatCustomerStyle);

			// Visualizzo il cliente del carrello attuale
			if (customerID != undefined && customerID != 0 && selectedCustomerNode === undefined) {
				let selectedNodeKey: string = customerID.toString();

				if (recipientID != undefined && recipientID != 0)
					selectedNodeKey += "|" + recipientID.toString();

				// Imposto la key del nodo da visualizzare nel TreeSelect
				setCustomerNode(selectedNodeKey);

				// Recupero l'object CustomerWithDetails, che uso per mostrare le sue info. Sembra ridondante, ma questo step è necessario al PRIMO render del componente, dove ho solo l'ID del customer e non il model completo
				// Questo perchè CartService mi può fornire solo l'ID e non il model completo (dato che lo serializzo solo parzialmente)
				const customerInfo = customers.find(x => x.codice == customerID) ?? undefined;
				if (customerInfo != undefined)
					onCustomerSelectedHandler(customerInfo.cliente, customerInfo.recipient);
			}

			// Se non ho niente da mostrare (ad esempio quando elimino il carrello), deseleziono tutto quello che avevo
			if (!customerID && selectedCustomerNode != undefined) {
				setCustomerNode(undefined);
				onCustomerSelectedHandler(undefined, undefined);
			}

			// Imposto i nodi creati dal model CustomerWithDetails
			setNodes(ns);
		};

		void loadNodes();
	}, [ customers, isLoading, customerID, recipientID ]);



	//#region onSelect Customer (TreeSelect)
	const onSelectCustomer = (e: TreeSelectChangeEvent) => {
		// Nel momento in cui seleziono un nodo, aggiorno lo stato collegato, in questo modo nei successivi rendering, il nodo selezionato è sempre corretto
		setCustomerNode(e.value);

		// Qui non ho possbilità di accedere alla CustomerTreeKey, così uso la funzione onNodeSelect qui sotto
	}



	const onNodeSelect = (e: TreeSelectEventNodeEvent) => {
		const node: TreeNode = e.node;

		if (node.data instanceof CustomerTreeKey) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			const nodeData: CustomerTreeKey = node.data as CustomerTreeKey;
			log.info(nodeData);

			onCustomerSelectedHandler(nodeData.cliente, nodeData.recipient);
		}
	}
	//#endregion



	// Parametri TreeSelect (Lista selezione cliente/destinatario)
	const customerTreeParams: TreeSelectProps = {
		options: nodes,
		filter: true,
		selectionMode: 'single',
		placeholder: (isLoading) ? "Caricamento clienti..." : "Seleziona un cliente",
		emptyMessage: "Nessun cliente disponibile",
		value: selectedCustomerNode,
		resetFilterOnHide: true,
		filterInputAutoFocus: true,
		disabled: isLoading,

		onChange: onSelectCustomer,
		onNodeSelect: onNodeSelect,
	}



	return (
		<TreeSelect className="customer-tree" {...customerTreeParams} />
	);
}
