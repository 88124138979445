import { GetSettingsResponse } from '@dto/Responses/Settings/GetSettingsResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';

import { SETTINGS } from '@transport/ApiDefinitions';

import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class SettingsService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('SettingsService');

		this.httpEngine = new GShopHttpEngine();
	}



	public async getSettings(): Promise<GetSettingsResponse> {
		this.log.debug(`Getting GShop settings`);

		const responseDTO: GetSettingsResponse = await this.httpEngine.get<GetSettingsResponse>(GetSettingsResponse, SETTINGS.LIST);

		return responseDTO;
	}

}