import { t } from '@deepkit/type';
import { GetArticleWithDiscounts } from '@dto/Responses/Articles/GetArticleWithDiscountsResponse';
import { GShopPagedResponse } from '@dto/Responses/GShopPagedResponse';



export class GetFavoritesArticleWithDiscounts extends GetArticleWithDiscounts {
	@t public totale_acquistati!: number;
	@t public frequenza_acquistati!: number;
	@t public data_last_acquisto!: string;
}



export class GetFavoritesArticleWithDiscountsPagedResponse extends GShopPagedResponse<GetFavoritesArticleWithDiscounts> {
	@t.array(GetFavoritesArticleWithDiscounts) public data: GetFavoritesArticleWithDiscounts[] = [];
}
