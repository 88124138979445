import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';


export class GetDashboardMonthlySales {
    @t  public mese!: number;
    @t  public valore_ordini!: number;
    @t  public numero_ordini!: number;
}



export class GetDashboardCustomerInfo {
    @t  public codice_cliente!: number;
    @t  public nominativo_cliente!: string;
    @t  public valore_ordini!: number;
    @t  public numero_ordini!: number;
}



export class GetDashboardResponse extends GShopResponse {
    @t.array(GetDashboardMonthlySales) public vendite_mensili: GetDashboardMonthlySales[] = [];
    @t.array(GetDashboardCustomerInfo) public top_clienti: GetDashboardCustomerInfo[] = [];
    @t.array(GetDashboardCustomerInfo) public worst_clienti: GetDashboardCustomerInfo[] = [];
}
