/* eslint-disable @typescript-eslint/no-misused-promises */

import { useAuthentication } from '@hooks/useAuthentication';
import { useLog } from '@hooks/useLog';
import { ToastSeverityEnum, useToast } from '@hooks/useToast';

import GShopLogo from '@images/store.png';
import classNames from 'classnames';

import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';

import './Login.scss';



type IFormData = {
	username: string;
	password: string;
}


const defaultValues = {
	username: '',
	password: '',
};



export const Login: React.FC = () => {
	const history = useHistory();
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const { control, formState: { errors }, handleSubmit, reset } = useForm<IFormData>({ defaultValues });
	const { showToast, clearToast } = useToast();
	const { login } = useAuthentication();

	const log = useLog();


	const getFormErrorMessage = (fieldName: keyof IFormData) => {
		return errors[fieldName] && <small className="p-error">{ errors[fieldName]?.message }</small>;
	};


	const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
		setIsSubmitting(true);

		const bLoggedIn = await login(data.username, data.password);

		if (bLoggedIn) {
			clearToast();

			history.push('/dashboard');
			return;
		}
		else {
			showToast(ToastSeverityEnum.ERROR, 'Login fallito', 'Impossibile accedere. Credenziali errate');

			setIsSubmitting(false);
		}

		//reset();
	};



	return (
		<div className="content-section implementation form-login flex align-items-center justify-content-center">
			<div className="surface-card p-5 shadow-2 border-round lg:w-3">
				<div className="text-center mb-5">
					<img src={ GShopLogo } alt="GShop" height={ 50 } className="mb-3" />
					<div className="text-900 text-3xl font-medium mb-3">Login</div>
				</div>

				<form onSubmit={ handleSubmit(onSubmit) } className="p-fluid">
					<div className="field">
						<label htmlFor="email" className="block text-900 font-medium">Nome utente</label>
						<Controller name="username" control={ control } rules={ { required: 'Nome utente obbligatorio!' } } render={ ({ field, fieldState }) => (
							<IconField iconPosition="left">
								<InputIcon className="pi pi-user"> </InputIcon>
								<InputText id={ field.name } { ...field } autoFocus disabled={ isSubmitting } className={ classNames('w-full', { 'p-invalid': fieldState.invalid }) } />
							</IconField>
						) }
						/>
						{ getFormErrorMessage('username') }

						<label htmlFor="password" className="block text-900 font-medium mt-2">Password</label>
						<Controller name="password" control={ control } rules={ { required: 'Password obbligatoria' } } render={ ({ field, fieldState }) => (
							<IconField iconPosition="left" >
								<Password id={ field.name } { ...field } disabled={ isSubmitting } feedback={ false } className={ classNames('w-full', { 'p-invalid': fieldState.invalid }) } />
							</IconField>
						) } />
						{ getFormErrorMessage('password') }

						<Button type="submit" label="Accedi" loading={ isSubmitting } className="w-full mt-4" />
					</div>
				</form>
			</div>
		</div>
	);

	/**

	 Login GUI v1
			<div className="content-section implementation form-demo">
			<div className="flex justify-content-center">
			<div className="card">
			<div className="text-center mb-5">
			<img src={GShopLogo} alt="GShop" height={50} className="mb-3" />
			<div className="text-900 text-3xl font-medium mb-3">Login</div>
	</div>

		<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
			<div className="p-field">
	                                <span className="p-float-label p-input-icon-right"><i className="pi pi-user" />
	                                    <Controller name="username" control={control} rules={{ required: 'Username obbligatorio!' }} render={({ field, fieldState }) => (
		                                    <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
	                                    )}
	                                    />
	                                    <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>Nome utente</label>
	                                </span>
				{
					getFormErrorMessage('username')
				}
			</div>


			<div className="p-field">
	                                <span className="p-float-label">
	                                    <Controller name="password" control={control} rules={{ required: 'Password obbligatoria' }} render={({ field, fieldState }) => (
		                                    <Password id={field.name} {...field} toggleMask feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })} />
	                                    )} />
	                                    <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Password</label>
	                                </span>
				{getFormErrorMessage('password')}
			</div>

			<Button type="submit" label="Accedi" className="mt-2" />
		</form>
	</div>
	</div>
	</div>



	SCSS
	.form-demo .card {
			min-width: 450px;
		}

	.form-demo .card form {
			margin-top: 2rem;
		}

	.form-demo .card .p-field {
			margin-bottom: 1.5rem;
		}
	 **/
};

//Login.whyDidYouRender = true;
