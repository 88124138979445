import qs from 'qs';
/**
 * import querystring from 'querystring';
 * import { classToPlain, plainToClass } from '@marcj/marshal';
 */


export class HttpEngineUtility {
	public static fromObjToQueryString(obj: unknown): string {
		return qs.stringify(obj);
	}



	/**
	 * Conversione da KVP[] a UrlSearchParams (skippando i valori null/undefinited)
	 * @param obj Array di KVP nel formato [ { key : nomekey, value : valoreparamtro }, { key : nomekey2, value : valoreparamtro2 } ]
	 * @returns {URLSearchParams} oggetto UrlSearchParams che in genere andrà poi usato in AxiosHttpEngine
	 */
	public static fromKVToSearchParams(obj: Record<string, any>[]): URLSearchParams {
		const params = new URLSearchParams();

		if (obj != undefined) {
			for (const { key, value } of obj) {      // Uso un for invece che forEach, perchè posso fare un "continue" (dicono che sul forEach posso fare "return" per simulare il continue, ma penso funzioni più tipo un break. Non ho provato)
				if (value === undefined)
					continue;

				params.append(key, value);
			}
		}

		return params;
	}


	/**
	 * Conversione da Object a UrlSearchParams
	 * @param obj dove verranno estrapolate le varie properties
	 * @returns {URLSearchParams} oggetto UrlSearchParams che in genere andrà poi usato in AxiosHttpEngine
	 */
	public static fromObjToSearchParams(obj: any): URLSearchParams {
		const params = new URLSearchParams();

		if (obj != undefined) {
			for (const key of Object.keys(obj)) {
				const value: string = obj[key];

				if (value === undefined)
					continue;

				params.append(key, value);
			}
		}

		return params;
	}

}