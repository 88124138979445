import { useGetArticleGallery } from '@hooks/articles/useGetArticleGallery';
import { useLog } from '@hooks/useLog';
import useBoolean from '@libs/KzHooks/useBoolean';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { ObjectUtility } from '@libs/utility/ObjectUtility';
import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';
import React, { forwardRef } from 'react';
import { useImmer } from 'use-immer';



type GalleryProductsProps = {
	lastSelectedArticle: string,
}


type GalleryImage = {
	itemImageSrc: string,
	thumbnailImageSrc: string,
	alt: string,
	title: string,
}



const GalleryProducts = forwardRef<Galleria, GalleryProductsProps>(({ lastSelectedArticle }: GalleryProductsProps, ref: React.Ref<Galleria>) => {
	const log = useLog();

	const [ canFetch, setCanFetch ] = useBoolean(false);
	const { isLoading, images } = useGetArticleGallery(lastSelectedArticle, canFetch);
	const [ galleryImages, setGalleryImages ] = useImmer<GalleryImage[]>([{ itemImageSrc: '', thumbnailImageSrc: '', alt: '', title: '' } as GalleryImage ]);



	useUpdate(() => {
		if (isLoading)
			return;

		if (ObjectUtility.isEmpty(images) || images?.length == 0) {
			setGalleryImages([{ itemImageSrc: '', thumbnailImageSrc: '', alt: '', title: '' } as GalleryImage]);
			return;
		}

		const gi = images.map((i) => {
			return {
				itemImageSrc: i.url_image,
				thumbnailImageSrc: i.url_thumbnail,
				title: i.title,
				alt: i.description,
			} as GalleryImage
		});

		setGalleryImages(gi);

	}, [ isLoading, images, canFetch ]);



	const onHideGallery = () => {
		setCanFetch.setFalse();
	};



	const onShowGallery = () => {
		setCanFetch.setTrue();
	};



	const galleryResponsiveOptions: GalleriaResponsiveOptions[] = [
		{
			breakpoint: '1500px',
			numVisible: 5,
		},
		{
			breakpoint: '1024px',
			numVisible: 3,
		},
		{
			breakpoint: '768px',
			numVisible: 2,
		},
		{
			breakpoint: '560px',
			numVisible: 1,
		},
	];



	const itemTemplate = (item: any) => {
		return <img src={ item.itemImageSrc } alt={ item.alt } style={ { width: '100%', display: 'block' } } />;
	};



	const thumbnailTemplate = (item: any) => {
		return <img src={ item.thumbnailImageSrc } alt={ item.alt } style={ { display: 'block' } } />;
	};



	return (
		<Galleria ref={ ref }
				  value={ galleryImages }
				  responsiveOptions={ galleryResponsiveOptions }
				  numVisible={ 9 }
				  style={ { maxWidth: '50%' } }
				  circular
				  fullScreen
				  showItemNavigators
				  onShow={ onShowGallery }
				  onHide={ onHideGallery }
				  item={ itemTemplate }
				  thumbnail={ thumbnailTemplate } />
	);
});
GalleryProducts.displayName = 'GalleryProducts';


export { GalleryProducts };
