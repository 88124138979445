import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { StatisticsService } from '@services/StatisticsService';
import { GetSalesByCategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesByCategoryResponse';


export type SalesByCategoryRequest = {
	CustomerID?: number | undefined,
}


export const defaultSalesByCategoryRequest: SalesByCategoryRequest = {
	CustomerID: undefined,
}



export const useSalesByCategoryStats = (scr: SalesByCategoryRequest) => {
	const log = useLog();

	const statisticsService = useSingleton<StatisticsService>(() => new StatisticsService(), []);

	const queryInfo = useQuery<GetSalesByCategoryResponse[]>({
		queryKey: [ 'scr', scr ],
		queryFn: async () => statisticsService.getCustomStat_SalesByCategory(scr.CustomerID)
	});


	return {
		...queryInfo,
		salesByCategoryInfo: queryInfo.data ?? new Array<GetSalesByCategoryResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
