import React, { ReactNode } from 'react';

import { Headerbar } from '@desktop/components/header/Headerbar';


type LayoutProps = {
	children: ReactNode
}



export const PublicLayoutContent: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
	return (
		<>
			<Headerbar />

			{ children }
		</>
	)
};
