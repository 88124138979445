import { GetArticleWithDiscounts } from '@dto/Responses/Articles/GetArticleWithDiscountsResponse';
import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';
import { ArticleRow } from '@services/CartService';



export class ArticlesMapper {
	public static toSelectedArticleRow(gawd: GetArticleWithDiscounts, useMultiplyCoefficient: boolean): ArticleRow {
		if (gawd == undefined)
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (gawd)} DTO`);

		const a: ArticleRow = new ArticleRow();

		a.articolo = gawd.articolo;
		a.descrizione = gawd.descrizione;
		a.um = gawd.unita_di_misura;
		a.codice_iva = gawd.codice_iva;

		// Valori predefiniti per quando scelgo l'articolo dalla lista
		a.quantita_3 = 1;
		a.specialrow = false;
		a.notes = "";

		a.coefficiente_moltiplicativo = gawd.coefficente_moltiplicativo;

		// Se c'è la modalità USA_COEFFICIENTE_MOLTIPLICATIVO, calcolo il prezzo sulla base di quel coefficiente

		// PREIMPOSTATO: Prezzo (da Articoli => Listino_x) 		 (variabile dall'agente)
		a.prezzo = (useMultiplyCoefficient && gawd.coefficente_moltiplicativo > 0) ? gawd.prezzo_listino * gawd.coefficente_moltiplicativo : gawd.prezzo_listino;
		a.sconto = gawd.sconto_personalizzato;                  // PREIMPOSTATO: Sconto (da Listini_Clienti_Corpo)       (variabile dall'agente)

		// Listino_x da Articoli (ridondante con prezzo, ma è fisso e mi da il prezzo originale SENZA variazioni dell'agente)
		a.prezzo_listino = (useMultiplyCoefficient && gawd.coefficente_moltiplicativo > 0) ? gawd.prezzo_listino * gawd.coefficente_moltiplicativo : gawd.prezzo_listino;
		// a.sconto_listino = gawd.sconto_listino;               // Sconto da Articoli (Sconto_Listino_x)  // Non usato

		a.prezzo_personalizzato = gawd.prezzo_personalizzato;   // Prezzo su Listini_Clienti_Corpo
		a.sconto_personalizzato = gawd.sconto_personalizzato;   // Sconto su Listini_Clienti_Corpo

		a.sconto_massimo = gawd.sconto_massimo;
		a.non_scontabile = gawd.non_scontabile === "S";

		a.prezzo_scontato = gawd.prezzo_scontato;           	// Prezzo calcolato scontato dal backend

		return a
	}
}