import { GetArticleImagesResponse } from '@dto/Responses/Articles/GetArticleImagesResponse';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { ArticlesService } from '@services/ArticlesService';
import { useQuery } from '@tanstack/react-query';



export const useGetArticleGallery = (article: string, enabled: boolean) => {
	const log = useLog();

	const articlesService = useSingleton<ArticlesService>(() => new ArticlesService(), []);

	const queryInfo = useQuery<GetArticleImagesResponse[]>({
		queryKey: ['get-gallery', article],
		queryFn: async () => articlesService.getArticleImages(article),
		enabled: article !== '' && enabled
	});

	return {
		...queryInfo,
		//images: queryInfo.data ?? new Array<GetArticleImagesResponse>(),
		images: queryInfo.data,
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
