import { t } from '@deepkit/type';
import { GetFavoritesArticleWithDiscounts } from '@dto/Responses/Articles/GetFavoritesArticleWithDiscountsResponse';
import { GShopPagedResponse } from '@dto/Responses/GShopPagedResponse';



export class GetIvecoArticleWithDiscountsResponse extends GetFavoritesArticleWithDiscounts {
	@t public riferimenti!: string;
	@t public sconto_riga_articolo!: string;
	@t public lotto!: string;
	@t public data_scadenza!: string;
}



export class GetIvecoArticleWithDiscountsPagedResponse extends GShopPagedResponse<GetIvecoArticleWithDiscountsResponse> {
	@t.array(GetIvecoArticleWithDiscountsResponse) public data: GetIvecoArticleWithDiscountsResponse[] = [];
}
