export class DiscountsUtility {

	/**
	 * Normalizzo uno sconto composto (es: 50+25 => 62,5)
	 * @returns {number}
	 * @param complexDiscount
	 */
	public static normalizeDiscount(complexDiscount: string): number {
		let discount = 0;

		if (complexDiscount == '') {
			return discount;
		}

		const singleDiscounts = complexDiscount.split('+');

		for (const sd of singleDiscounts) {
			if (sd != '0') {
				discount += ((100 - discount) * Number.parseFloat(sd) / 100);
			}
		}

		return discount;
	}



	/**
	 * Calcola il valore netto SCONTATO di un valore iniziale (es 100, sconto 25 => 75)
	 * @returns {number}
	 * @param price
	 * @param discount
	 */
	public static calculateDiscount(price: number, discount: string): number {
		const normalizeDiscount = DiscountsUtility.normalizeDiscount(discount);

		const discountPrice = price - (price / 100 * normalizeDiscount);

		return discountPrice;
	}
}
