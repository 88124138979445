import { Avatar } from 'primereact/avatar';
import React, { ReactNode } from 'react';
import { Customer } from '@models/Customer';


type CardCustomerProps = {
	customer?: Customer,
	children?: ReactNode
}



export const CardCustomer: React.FC<CardCustomerProps> = ({ customer }: CardCustomerProps) => {
	return (
		<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round header-cards">
			<div className="flex justify-content-between mb-3">
				<div>
					<span className="block text-500 font-medium mb-3">Cliente</span>
					<div className="text-900 font-medium text-xl mb-2">{customer?.ragione_sociale_1} {customer?.ragione_sociale_2}</div>
					<div className="text-900 font-normal text-base mb-2">{customer?.indirizzo}</div>
					<div className="text-900 font-normal text-base mb-2">{customer?.localita} - {customer?.cap} ({customer?.provincia})</div>
					<div className="text-900 font-normal text-base mb-2">P.Iva {customer?.partita_iva}</div>
					<div className="text-900 font-normal text-base mb-2">Tel. {customer?.telefoni} {customer?.cellulari}</div>
				</div>

				<div className="flex justify-content-center">
					<Avatar icon="pi pi-user" className="mr-2" size="large" style={{ backgroundColor: '#2196F3', color: '#FFFFFF' }} shape="circle" />
				</div>
			</div>
		</div>
	);
};