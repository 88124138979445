import { Recipient } from '@models/Recipient';


export class CustomerWithDetails {
	public codice!: number;
	public ragione_sociale_1!: string;
	public ragione_sociale_2!: string;
	public nickname!: string;
	public indirizzo!: string;
	public localita!: string;
	public cap!: string;
	public provincia!: string;
	public stato!: string;
	public partita_iva!: string;
	public codice_fiscale!: string;
	public telefoni!: string;
	public telefax!: string;
	public cellulari!: string;
	public ufficio!: string;
	public no_split_payment!: string;
	public pagamento!: string;
	public descrizione_pagamento!: string;
	public agente!: number;
	public destinatario!: number;
	public spedizioniere!: number;
	public deposito_predefinito!: number;
	public tipo!: string;
	public descrizione_tipo!: string;
	public area!: string;
	public nazione!: string;
	public listino!: string;
	public sconto!: number;
	public fido!: number;
	public annotazioni!: string;
	public sito_web!: string;
	public riferimento_e_mail_1!: string;
	public indirizzo_e_mail_1!: string;
	public riferimento_e_mail_2!: string;
	public indirizzo_e_mail_2!: string;
	public riferimento_e_mail_3!: string;
	public indirizzo_e_mail_3!: string;
	public riferimento_e_mail_pec!: string;
	public indirizzo_e_mail_pec!: string;
	public pec_fattura_elettronica!: string;

	public recipients: Recipient[] = new Array<Recipient>();
}
