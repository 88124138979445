import { DescriptionType, ModulesType, settingsAtom, SettingsContextState, VOCGroupedType } from '@atoms/SettingsAtom';
import { useLog } from '@hooks/useLog';

import { useMountAsync } from '@libs/KzHooks/useMountAsync';
import { useSingleton } from '@libs/KzHooks/useSingleton';

import { SettingsService } from '@services/SettingsService';
import { useAtom } from 'jotai';
import { useMemo } from 'react';



export const useSettings = (): { settingsState: SettingsContextState } => {
	const log = useLog();
	const settingService = useSingleton<SettingsService>(() => new SettingsService(), []);
	const [settingsState, setSettingsState] = useAtom(settingsAtom);


	// Caricamento dei settings
	useMountAsync(async () => {
		log.info('Loading GShop settings...');

		const settingsResponse = await settingService.getSettings();

		setSettingsState((draft: SettingsContextState) => {
			draft.multipleQuantity = settingsResponse.multiple_quantity;
			draft.discountsEditable = settingsResponse.discounts_editables;
			//draft.allowUnderDiscount = settingsResponse.allow_under_discounts;    // TODO: Creare setting
			draft.pricesEditable = settingsResponse.prices_editables;
			draft.markSpecialRow = settingsResponse.mark_special_row;
			draft.showCategories = settingsResponse.show_categories;
			draft.showUM = settingsResponse.show_um;
			draft.useMultiplyCoefficient = settingsResponse.use_multiply_coefficient;
			draft.splitColumnPriceDiscount = settingsResponse.split_column_price_discount;
			draft.daysArticleNew = settingsResponse.days_article_is_new;
			draft.daysArticleObsolete = settingsResponse.days_article_is_obsolete;
			draft.viewExistenceByDeposit = settingsResponse.view_existence_by_deposit;
			draft.mergeFavorites = settingsResponse.merge_favorites;
			draft.favoritesMaxYears = settingsResponse.favorites_max_year;
			draft.showNewArticles = settingsResponse.show_new_articles;
			draft.showObsolete = settingsResponse.show_obsolete;
			draft.showColumnFreqAcq = settingsResponse.show_column_freq_acq;
			draft.sidebarVertical = settingsResponse.sidebar_vertical;

			// Descrizione dell'articolo
			switch (settingsResponse.type_description.toUpperCase()) {
				case 'D':
					draft.descriptionType = DescriptionType.DESCRIPTION;
					break;

				case 'A':
					draft.descriptionType = DescriptionType.DESCRIPTION_EXTRA;
					break;

				case 'T':
					draft.descriptionType = DescriptionType.DESCRIPTION_AND_DESCRIPTION_EXTRA;
					break;

				default:
					draft.descriptionType = DescriptionType.DESCRIPTION;
					break;
			}


			// Raggruppamento articoli VOC (Categoria / Sottocategoria)
			switch (settingsResponse.voc_grouped_mode.toUpperCase()) {
				case 'C':
					draft.vocGroupedBy = VOCGroupedType.CATEGORY;
					break;

				case 'S':
					draft.vocGroupedBy = VOCGroupedType.SUBCATEGORY;
					break;

				case 'L':
					draft.vocGroupedBy = VOCGroupedType.LINE_PRODUCTION;
					break;

				default:
					draft.vocGroupedBy = VOCGroupedType.SUBCATEGORY;

			}


			// Definisco le statistiche disponibili, che poi abiliterò nella sidebar
			for (const s of settingsResponse.modules_available) {
				switch (s.toLowerCase()) {
					case 'voc':
						draft.modulesAvailables.push(ModulesType.VOC);
						break;

					case 'sales_forecast':
						draft.modulesAvailables.push(ModulesType.CUSTOM_SALES_FORECAST);
						break;

					case 'sales_category':
						draft.modulesAvailables.push(ModulesType.CUSTOM_SALES_BY_CATEGORY);
						break;

					case 'sales_article':
						draft.modulesAvailables.push(ModulesType.CUSTOM_SALES_BY_ARTICLE);
						break;

					case 'sales_subcategory':
						draft.modulesAvailables.push(ModulesType.CUSTOM_SALES_BY_SUBCATEGORY);
						break;

					case 'divercar':
						draft.modulesAvailables.push(ModulesType.CUSTOM_TINTE);
						break;

					case 'custom_ctamonn':
						draft.modulesAvailables.push(ModulesType.CUSTOM_SALES_BY_CATEGORY,
													ModulesType.CUSTOM_SALES_FORECAST);        // Aggiungo di default questa stats (non ho voglia di andar a variar la tabella dei moduli)
						break;
				}
			}
		});
	});


	return useMemo(() => ({
		settingsState: settingsState,
	}), [
		settingsState,
	]);
};
