import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { DashboardService } from '@services/DashboardService';
import { GetDashboardResponse } from '@dto/Responses/Dashboard/GetDashboardResponse';



export const useDashboardInfo = () => {
	const log = useLog();

	const dashboardService = useSingleton<DashboardService>(() => new DashboardService(), []);

	const queryInfo = useQuery<GetDashboardResponse>({
		queryKey: [ 'dashboard' ],
		queryFn: async () => dashboardService.getDashboardData()
	});


	return {
		...queryInfo,
		dashboardInfo: queryInfo.data,
	}
};
