import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';


export class GetPaymentsResponse extends GShopResponse {
	@t public codice!: number;
	@t public ragione_sociale_1!: string;
	@t public ragione_sociale_2!: string;
	@t public nickname!: string;
	@t public indirizzo!: string;
	@t public localita!: string;
	@t public cap!: string;
	@t public provincia!: string;
	@t public partita_iva!: string;
	@t public codice_fiscale!: string;
	@t public telefoni!: string;
	@t public fax!: string;
	@t public cellulari!: string;
	@t public fido!: string;

	@t public stato_pagamento!: string;
	@t public pagamento!: string;
	@t public descrizione_pagamento!: string;
	@t public importo!: number;
	@t public scadenza!: string;
	@t public numero_documento!: string;
	@t public data_documento!: string;
	@t public esercizio!: string;
}
