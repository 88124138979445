import { atomWithImmer } from 'jotai/immer';



export type SettingsContextState = {
	multipleQuantity: boolean;
	discountsEditable: boolean;
	allowUnderDiscount: boolean;
	pricesEditable: boolean;
	vocGroupedBy: VOCGroupedType;
	markSpecialRow: boolean;
	showCategories: boolean;
	showUM: boolean;
	useMultiplyCoefficient: boolean;
	splitColumnPriceDiscount: boolean;
	daysArticleObsolete: number;
	daysArticleNew: number;
	viewExistenceByDeposit: boolean;
	showNewArticles: boolean;
	showObsolete: boolean;
	showColumnFreqAcq: boolean;
	mergeFavorites: boolean;
	favoritesMaxYears: number;
	sidebarVertical: boolean;
	descriptionType: DescriptionType;
	modulesAvailables: ModulesType[];
}


export enum ModulesType {
	VOC,
	CUSTOM_SALES_BY_CATEGORY,
	CUSTOM_SALES_BY_SUBCATEGORY,
	CUSTOM_SALES_BY_ARTICLE,
	CUSTOM_SALES_FORECAST,
	CUSTOM_TINTE
}



export enum DescriptionType {
	DESCRIPTION,
	DESCRIPTION_EXTRA,
	DESCRIPTION_AND_DESCRIPTION_EXTRA,
}



export enum VOCGroupedType {
	CATEGORY,
	SUBCATEGORY,
	LINE_PRODUCTION,
}



const defaultSettingState: SettingsContextState = {
	multipleQuantity: false,
	discountsEditable: false,
	allowUnderDiscount: true,
	pricesEditable: false,
	vocGroupedBy: VOCGroupedType.SUBCATEGORY,
	markSpecialRow: false,
	showCategories: true,
	showUM: true,
	useMultiplyCoefficient: false,
	splitColumnPriceDiscount: true,
	viewExistenceByDeposit: false,
	daysArticleNew: 0,
	daysArticleObsolete: 0,
	showNewArticles: true,
    showObsolete: true,
	showColumnFreqAcq: true,
    mergeFavorites: false,
	favoritesMaxYears: -1,
    sidebarVertical: true,
	descriptionType: DescriptionType.DESCRIPTION,
	modulesAvailables: new Array<ModulesType>()
};



// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const settingsAtom = atomWithImmer<SettingsContextState>(defaultSettingState);