import localForage from 'localforage';


export class AuthRepository {
    private static TOKEN = "TOKEN";


    public static async saveToken(token: string): Promise<string> {
        const t: string = await localForage.setItem(AuthRepository.TOKEN, token);

        return t;
    }

    

    public static async getToken(): Promise<string> {
        const token: string = await localForage.getItem(AuthRepository.TOKEN) || "";

        return token;
    }



    public static async clearToken(): Promise<void> {
        await localForage.removeItem(AuthRepository.TOKEN);
    }
}