import { InsertOrderRequest, InsertOrderRowRequest } from '@dto/Requests/Orders/InsertOrderRequest';
import { GetDocumentsResponse } from '@dto/Responses/Documents/GetDocumentsResponses';
import { GetOrderDetailsResponse, GetOrderRowDetailsResponse } from '@dto/Responses/Orders/GetOrderDetailsResponse';
import { WebException, WebExceptionErrors } from '@libs/KzHttpEngine/Exceptions/WebException';
import { Cart, CartRow } from '@models/Cart';
import { Order, OrderRow } from '@models/Order';



export class OrdersMapper {
	//#region RESPONSE Mapper

	public static toOrderModel(SourceDto: GetDocumentsResponse | GetOrderDetailsResponse): Order {
		if (SourceDto == undefined) {
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (SourceDto)} DTO`);
		}

		const o: Order = new Order();

		o.documento = SourceDto.documento;
		o.flag = SourceDto.flag;
		o.stato = SourceDto.stato;
		o.ordineid = SourceDto.documento + SourceDto.numero;
		o.data = SourceDto.data;
		o.codice = SourceDto.codice;
		o.pagamento = SourceDto.pagamento;
		o.banca = SourceDto.banca;
		o.agente = SourceDto.agente;
		o.destinatario = SourceDto.destinatario;
		o.spedizioniere = SourceDto.spedizioniere;
		o.listino = SourceDto.listino;
		o.sigla = SourceDto.sigla;
		o.imponibile_1 = SourceDto.imponibile_1;
		o.codice_iva_1 = SourceDto.codice_iva_1;
		o.imposta_1 = SourceDto.imposta_1;
		o.imponibile_2 = SourceDto.imponibile_2;
		o.codice_iva_2 = SourceDto.codice_iva_2;
		o.imposta_2 = SourceDto.imposta_2;
		o.imponibile_3 = SourceDto.imponibile_3;
		o.codice_iva_3 = SourceDto.codice_iva_3;
		o.imposta_3 = SourceDto.imposta_3;
		o.imponibile_4 = SourceDto.imponibile_4;
		o.codice_iva_4 = SourceDto.codice_iva_4;
		o.imposta_4 = SourceDto.imposta_4;
		o.imponibile_5 = SourceDto.imponibile_5;
		o.codice_iva_5 = SourceDto.codice_iva_5;
		o.imposta_5 = SourceDto.imposta_5;
		o.imponibile_6 = SourceDto.imponibile_6;
		o.codice_iva_6 = SourceDto.codice_iva_6;
		o.imposta_6 = SourceDto.imposta_6;
		o.totale_merce = SourceDto.totale_merce;
		o.percentuale_sconto = SourceDto.percentuale_sconto;
		o.importo_sconto = SourceDto.importo_sconto;
		o.netto_merce = SourceDto.netto_merce;
		o.spese_trasporto = SourceDto.spese_trasporto;
		o.spese_imballo = SourceDto.spese_imballo;
		o.spese_varie = SourceDto.spese_varie;
		o.spese_incasso = SourceDto.spese_incasso;
		o.spese_bolli = SourceDto.spese_bolli;
		o.totale_imponibile = SourceDto.totale_imponibile;
		o.totale_imposta = SourceDto.totale_imposta;
		o.totale_documento = SourceDto.totale_documento;
		o.totale_in_valuta = SourceDto.totale_in_valuta;
		o.totale_da_pagare = SourceDto.totale_da_pagare;
		o.data_inizio = SourceDto.data_inizio;
		o.ora_inizio = SourceDto.ora_inizio;
		o.colli = SourceDto.colli;
		o.cartoni = SourceDto.cartoni;
		o.peso = SourceDto.peso;
		o.peso_lordo = SourceDto.peso_lordo;
		o.volume = SourceDto.volume;
		o.palette = SourceDto.palette;
		o.trasporto_a_mezzo = SourceDto.trasporto_a_mezzo;
		o.porto = SourceDto.porto;
		o.causale_trasporto = SourceDto.causale_trasporto;
		o.aspetto_beni = SourceDto.aspetto_beni;
		o.annotazioni = SourceDto.annotazioni;
		o.filler = SourceDto.filler;
		o.workstation = SourceDto.workstation;
		o.cig_cup = SourceDto.cig_cup;
		o.id_sdi = SourceDto.id_sdi;

		return o;
	}



	public static toOrderDetailsModel(SourceDto: GetOrderDetailsResponse): Order {
		if (SourceDto == undefined) {
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (SourceDto)} DTO`);
		}

		const o: Order = OrdersMapper.toOrderModel(SourceDto);

		o.righe_documento = SourceDto.righe_documento.map(orr => OrdersMapper.toOrderRowModel(orr));

		return o;
	}



	public static toOrderRowModel(SourceDto: GetOrderRowDetailsResponse): OrderRow {
		if (SourceDto == undefined) {
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (SourceDto)} DTO`);
		}

		const orm: OrderRow = new OrderRow();

		orm.articolo = SourceDto.articolo;
		orm.descrizione = SourceDto.descrizione;
		orm.unita_di_misura = SourceDto.unita_di_misura;
		orm.quantita_1 = SourceDto.quantita_1;
		orm.quantita_2 = SourceDto.quantita_2;
		orm.quantita_3 = SourceDto.quantita_3;
		orm.prezzo = SourceDto.prezzo;
		orm.sconti = SourceDto.sconti;
		orm.importo = SourceDto.importo;
		orm.codice_iva = SourceDto.codice_iva;
		orm.id = SourceDto.id;

		return orm;
	}


	//#endregion


	//#region REQUEST Mapper

	public static toInsertOrder(SourceDto: Cart): InsertOrderRequest {
		if (SourceDto == undefined) {
			throw new WebException(WebExceptionErrors.INVALID_MODEL_REQUEST, `Invalid ${typeof (SourceDto)} DTO`);
		}

		const o: InsertOrderRequest = new InsertOrderRequest();

		o.CodiceCliente = SourceDto.CustomerID;
		o.CodiceDestinatario = SourceDto.RecipientID;
		o.NoteCliente = SourceDto.NoteCliente;
		o.NoteAgente = SourceDto.NoteAgente;
		o.EmailCliente = SourceDto.EmailCustomer ?? "";
		o.SendEmail = SourceDto.isSendEmail;
		o.Deposito = SourceDto.DepositID;

		o.Rows = SourceDto.Rows.map((cr: CartRow): InsertOrderRowRequest => {
			const r: InsertOrderRowRequest = {
				ID: cr.ID,
				Articolo: cr.Product,
				Descrizione: cr.Description,
				Prezzo: cr.Price,
				Sconti: cr.Discount,
				Quantita_1: cr.Packs,
				Quantita_2: cr.Pieces_per_Pack,
				Quantita_3: cr.Quantity,
				Coefficient_Multiply: cr.Coefficient_Multiply,
				UM: cr.UM,
				Codice_IVA: cr.VAT,
				Notes: cr.Notes,
				SpecialRow: cr.SpecialRow
			}

			return r;
		}).toArray();

		return o;
	}

	//#endregion
}
