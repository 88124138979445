import { t, classToPlain } from '@deepkit/type';
import { GShopPagedRequest } from '@dto/Requests/GShopPagedRequest';



export class GetOrdersHeadersRequest implements GShopPagedRequest {
    @t public Flag!: string;
    @t public CodiceCliente?: number;
    @t.type(Number) public page = 1;
    @t public pagesize?: number;


    public constructor(flag: string, page: number, pageSize?: number, customerID?: number) {
        this.Flag = flag;
        this.CodiceCliente = customerID;
        this.page = page;
        this.pagesize = pageSize;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(GetOrdersHeadersRequest, this);

        return js;
    }
}
