import { CustomerWithDetails } from '@models/CustomerWithDetails';
import React, { ReactNode } from 'react';


type CustomerInfoProps = {
	customerInfo: CustomerWithDetails | undefined,
	children?: ReactNode
}



export const CustomerInfo: React.FC<CustomerInfoProps> = ( { customerInfo }) => {
	if (customerInfo == undefined)
		return (<></>);

	return (
		<div>
			<div className="text-900 font-medium text-xl mb-2">{customerInfo?.ragione_sociale_1} {customerInfo?.ragione_sociale_2}</div>
			<div className="text-900 font-normal text-base mb-2">{customerInfo?.indirizzo}</div>
			<div className="text-900 font-normal text-base mb-2">{customerInfo?.localita} - {customerInfo?.cap} ({customerInfo?.provincia})</div>
			<div className="text-900 font-normal text-base mb-2">P.Iva {customerInfo?.partita_iva}</div>
			<div className="text-900 font-normal text-base mb-2">Tel. {customerInfo?.telefoni} {customerInfo?.cellulari}</div>
		</div>
	);
}