/* eslint-disable @typescript-eslint/unbound-method */

import { paginatorTemplate } from '@desktop/components/commons/DataTablePaginator';
import { useLog } from '@hooks/useLog';
import { TableRecipients } from '@layouts/desktop/pages/private/customers/CustomersList/TableRecipients';
import { Customer } from '@models/Customer';
import { CustomerWithDetails } from '@models/CustomerWithDetails';
import classNames from 'classnames';

import { Column } from 'primereact/column';
import { DataTable, DataTableProps, DataTableRowClickEvent, DataTableRowToggleEvent } from 'primereact/datatable';
import React, { ReactNode } from 'react';

import { useImmer } from 'use-immer';

import './TableCustomers.scss';



type TableCustomersProps = {
	customers: CustomerWithDetails[],
	isLoading: boolean,
	children?: ReactNode
}



/**
 * formatRow coloro TUTTA la riga in base all'esistenza customer
 * @param data - model dell'customer
 */
const formatRow = (data: Customer) => {
	return {
		'nostock-row': data.codice <= 0,
	};
};



/**
 * stockBodyTemplate coloro la cella in base all'esistenza customer
 * @param rowData - model customer della riga
 */
const stockBodyTemplate = (rowData: Customer) => {
	const stockClassesColors = classNames({
		'insolventi': rowData.codice === 0,
	});

	return (
		<div className={stockClassesColors}>
			{rowData.cap}
		</div>
	);
};



/**
 * customerBodyHasRecipients visualizzo l'expand se ho almeno un destinatario
 * @param data
 */
const expanderHasRecipientsBody = (data: CustomerWithDetails) => {
	return data.recipients.length > 0;
}



/**
 * recipientsBodyTemplate visualizzazione del numero destinatari
 * @param rowData - model customer della riga
 */
const recipientsBodyTemplate = (rowData: CustomerWithDetails) => {
	return (rowData.recipients.length === 0) ? "" : rowData.recipients.length;
};



//Metodo 1
/*interface KeyOfExpandedRows {
	[codiceCliente: string]: boolean;
}*/

// Metodo 2
type KeyOfExpandedRows = Record<string, boolean>

const KEEP_EXPANDED_ROWS = true;



export const TableCustomers: React.FC<TableCustomersProps> = ({ customers, isLoading }: TableCustomersProps) => {
	const log = useLog();
	const [ expandedRows, setExpandedRows ] = useImmer<KeyOfExpandedRows>({});

	const onRowExpansionTemplate = (data: CustomerWithDetails) => {
		if (data.recipients?.length != 0)
			return <TableRecipients recipients={data.recipients} />

		return <></>;
	}



	const toggleExpandedRows = (openRows: string[], keepOpen: boolean) => {
		setExpandedRows((draft) => {
			if (openRows.length === 0 || keepOpen)
				for (const k of Object.keys(draft))
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete draft[k];

			for (const k of openRows) {
				if (draft[k] && !keepOpen)
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete draft[k];
				else
					draft[k] = true;
			}
		});
	}



	const onRowToggle = (e: DataTableRowToggleEvent) => {
		// e.data è un dictionary con chiave il campo dataKey (in questo caso field CODICE dell'obj CustomerWithDetails) ed il valore true
		// es: { 1: true, 9 : true}, che indica quali righe sono espanse.
		const customerKeys = Object.keys(e.data) as unknown as string[];

		toggleExpandedRows(customerKeys, KEEP_EXPANDED_ROWS);
	}



	const onRowClick = (e: DataTableRowClickEvent) => {
		const customer = e.data as unknown as CustomerWithDetails;

		// Se non c'è un destinatario, evito di espanderla
		if (customer.recipients.length === 0)
			return;

		// La routine qui sotto emula il comportamento della DataTable (onRowToggle) dove viene passata un dictionaty <string, boolean> fatto da <datakey (codice cliente in sto caso, true>
		// Tramite una singola routine (toggleExpandedRows) riesco a usarla uguale per entrambe
		const key: string = customer.codice.toString();

		const customerKeys: string[] = Object.keys(expandedRows);

		// Alla lista di righe espanse, se trovo nuovamente lo stesso ID, è perchè ci ho cliccato, quindi faccio il toggle (ovvero la rimuovo), diversamente la aggiunto a quelle già esistenti
		const idx = customerKeys.indexOf(key);
		if (idx === -1) {
			customerKeys.push(key);
		}
		else {
			customerKeys.splice(idx, 1);        // Va usato SPLICE sugli array e NON delete!!! Attenzione perchè delete elimina le property e non gli elementi, quindi non fa il reindex
		}

		toggleExpandedRows(customerKeys, KEEP_EXPANDED_ROWS);
	}



	const dtParameters: DataTableProps<CustomerWithDetails[]> = {
		scrollable: true,
		scrollHeight: "750px",
		rowHover: true,
		stripedRows: true,
		paginator: true,
		paginatorTemplate: paginatorTemplate,

		lazy: false,
		loading: isLoading,
		rows: 25,
		value: customers,

		columnResizeMode: "fit",
		emptyMessage: "Nessun cliente trovato",
		footer: "",
		dataKey: "codice",
		rowClassName: formatRow,

		expandedRows: expandedRows,
		onRowToggle: onRowToggle,
		onRowClick: onRowClick,
		rowExpansionTemplate : onRowExpansionTemplate,
	}


	return (
		<DataTable className="p-datatable-sm" { ...dtParameters }>
			<Column expander={expanderHasRecipientsBody} style={ { width: '3%' } } />
			<Column field="codice" header="Codice" sortable style={ { width: '3%' } } />
			<Column field="ragione_sociale_1" header="Ragione Sociale" sortable />
			<Column field="indirizzo" header="Indirizzo" style={ { width: '30%' } } />
			<Column field="localita" header="Localita" style={ { width: '30%' } } />
			<Column field="provincia" header="Provincia" sortable style={ { width: '6%' } } />
			<Column field="destinatari" header="# Destinatari" sortable body={ recipientsBodyTemplate } style={ { width: '8%' } } />
		</DataTable>
	);
};
