import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PublicRoute } from '@desktop/components/router/PublicRoute';
import { PrivateRoute } from '@desktop/components/router/PrivateRoute';

import { useAuthentication } from '@hooks/useAuthentication';


// Public
import { Homepage } from '@layouts/desktop/pages/public/homepage/Homepage';
import { Login } from '@layouts/desktop/pages/public/login/Login';


// Private
import { Dashboard } from '@layouts/desktop/pages/private/dashboard/Dashboard';
import { CustomersList } from '@layouts/desktop/pages/private/customers/CustomersList/CustomersList';
import { ProductsList } from '@layouts/desktop/pages/private/products/ProductsList/ProductsList';
import { ProductsUnsold } from '@layouts/desktop/pages/private/products/ProductsUnsold/ProductsUnsold';
import { OrdersList } from '@layouts/desktop/pages/private/orders/OrdersList/OrdersList';
import { OrdersNew } from '@layouts/desktop/pages/private/orders/OrdersNew/OrdersNew';
import { About } from '@layouts/desktop/pages/private/about/About';
import { OrderDetail } from '@layouts/desktop/pages/private/orders/OrderDetails/OrderDetail';
import { SalesByCategory } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesByCategory/SalesByCategory';
import { VOCStatistics } from '@layouts/desktop/pages/private/statistics/voc/VOCStatistics';
import { PaymentsList } from '@layouts/desktop/pages/private/payments/PaymentsList';
import { SalesForecast } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesForecast/SalesForecast';
import { AuthenticationState } from '@atoms/AuthAtom';
import { useLog } from '@hooks/useLog';
import { SalesBySubcategory } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesBySubcategory/SalesBySubcategory';
import { useAtomValue } from 'jotai/utils';
import { ModulesType, settingsAtom } from '@atoms/SettingsAtom';
import { SalesByArticle } from './private/statistics/custom/ctamonn/SalesByArticle/SalesByArticle';
import { DebugZone } from '@layouts/desktop/pages/private/debug/DebugZone';



export const LayoutDesktopRoutes: React.FC = () => {
	const log = useLog();
	const { status } = useAuthentication();
	const settingAtom = useAtomValue(settingsAtom);

	const isAuthenticated = status === AuthenticationState.LOGGED;

	/**
	 Le route vengono processate subito, mentre la fase di verifica se sono autenticato o no è async e potrebbe metterci un po' di tempo.
	 Il problema è che se risulto autenticato la prima volta, poi useAuth non viene rimontato e lo stato non viene alterato.
	 Se faccio refresh della pagina o vado su un url diverso, viene ricaricata la webapp, e l'utente inizialmente non risulta loggato, in quanto avevo solo la stato auth true/false.
	 Inserisco quindi uno stato completo dove capisco se sono in fase di verifica dell'autenticazione. In quel caso non vado avanti a processare le routes, dato che quando lo stato di auth verrà aggiornato, verrà re-renderizzato il tutto.
	 Invece che lasciar pagina vuota (che non si vede dato che è tutto molto veloce come render) potrei metter uno spinner, ma forse è peggio far comparire un "flash" di spinner
	*/
	if (status === AuthenticationState.PENDING) {
		return <></>;
	}

	// NB: Importante fare il controllo dei permessi dei moduli abilitati anche sulle route, non solo sui menù, altrimenti, ovviamente, ci si può accedere conoscendo la route

	return (
		<>
			<Switch>
				{ /* Route pubbliche */ }
				<PublicRoute exact path="/" component={ <Homepage /> } />
				<PublicRoute isAuth={isAuthenticated} redirectpath="/dashboard" exact path="/login" component={ <Login /> } />

				{ /* Route private */ }
				<PrivateRoute isAuth={isAuthenticated} path="/dashboard" component={ <Dashboard /> } />

				<PrivateRoute isAuth={isAuthenticated} exact path="/customers/" component={ <CustomersList />} />
				<PrivateRoute isAuth={isAuthenticated} path="/customers/list" component={ <CustomersList />} />

				<PrivateRoute isAuth={isAuthenticated} exact path="/products/" component={ <ProductsList /> } />
				<PrivateRoute isAuth={isAuthenticated} path="/products/unsold" component={ <ProductsUnsold /> } />

				<PrivateRoute isAuth={isAuthenticated} exact path="/payments/" component={ <PaymentsList />} />
				<PrivateRoute isAuth={isAuthenticated} path="/payments/list" component={ <PaymentsList />} />

				<PrivateRoute isAuth={isAuthenticated} exact path="/orders/" component={ <OrdersList /> } />
				<PrivateRoute isAuth={isAuthenticated} path="/orders/new" component={ <OrdersNew /> } />
				<PrivateRoute isAuth={isAuthenticated} path="/orders/list" component={ <OrdersList /> } />
				<PrivateRoute isAuth={isAuthenticated} path="/orders/:orderCode/:orderNumber" component={ <OrderDetail /> } />

				<PrivateRoute isAuth={isAuthenticated && (settingAtom.modulesAvailables.some(x => x == ModulesType.VOC))} path="/statistics/voc" component={ <VOCStatistics /> } />
				<PrivateRoute isAuth={isAuthenticated && (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_CATEGORY))} path="/statistics/custom/sales-by-category" component={ <SalesByCategory /> } />
				<PrivateRoute isAuth={isAuthenticated && (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_SUBCATEGORY))} path="/statistics/custom/sales-by-subcategory" component={ <SalesBySubcategory /> } />
				<PrivateRoute isAuth={isAuthenticated && (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_ARTICLE))} path="/statistics/custom/sales-by-article" component={ <SalesByArticle /> } />
				<PrivateRoute isAuth={isAuthenticated && (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_FORECAST))} path="/statistics/custom/sales-forecast" component={ <SalesForecast /> } />

				<PrivateRoute isAuth={isAuthenticated} path="/about" component={ <About /> } />

				<PrivateRoute isAuth={isAuthenticated} path="/debug" component={ <DebugZone /> } />

				{ /* Redirect path inesistente sulla home */}
				<Redirect to="/" />
			</Switch>

			{/*<Route path="/notfound" exact component={NotFound} />*/}
			{/*<Redirect to="/notfound" />*/}
		</>
	)
};
