//import { atom } from 'jotai';
import { atomWithImmer } from 'jotai/immer'
import { LoggedUser } from '@models/LoggedUser';


export type AuthContextState = {
	user: LoggedUser | undefined;
	status: AuthenticationState;
}


export enum AuthenticationState {
	PENDING,
	LOGGED,
	UNAUTHORIZED,
	TOKEN_EXPIRED,
}


const defaultAuthState: AuthContextState = {
	user: undefined,
	status: AuthenticationState.PENDING,
};



// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const authAtom = atomWithImmer<AuthContextState>(defaultAuthState);