import React from 'react';
import { Link } from 'react-router-dom';

import { UserMenu } from '@desktop/components/header/UserMenu';

import GShopLogo from '@images/store.png';
import './Headerbar.scss';



export const Headerbar: React.FC = () => {
	const logoStyle = {
		marginRight: 10
	}

	return (
			<div className="layout-topbar">
				{/* Tocca metter due link ridicoli, perchè il foglio stile è sminchiato e mi andrebbero a capo mettendone solo uno. Non ho voglia di andar a cercare dov'è l'intoppo */}
				<Link to="/" className="">
					<img className="logo" width="32" alt="logo" src={GShopLogo} />
					{ /* <img style={{ marginLeft:10 }} width="32" alt="logo" src={ 'assets/logo192.png' } /> */ }
				</Link>
				<Link to="/">
					<h1>GShop</h1>
				</Link>

				<UserMenu />
			</div>
	);
}
