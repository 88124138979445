import { t } from '@deepkit/type';
import { GetFavoritesArticleWithDiscounts } from '@dto/Responses/Articles/GetFavoritesArticleWithDiscountsResponse';
import { GShopPagedResponse } from '@dto/Responses/GShopPagedResponse';



export class GetTinteArticleWithDiscountsResponse extends GetFavoritesArticleWithDiscounts {
	@t public riferimenti!: string;
}



export class GetTinteArticleWithDiscountsPagedResponse extends GShopPagedResponse<GetTinteArticleWithDiscountsResponse> {
	@t.array(GetTinteArticleWithDiscountsResponse) public data: GetTinteArticleWithDiscountsResponse[] = [];
}
