// noinspection DuplicatedCode

import { GetSalesByCategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesByCategoryResponse';
import { DataFormatters } from '@guiutility/DataFormatters';

import { useLog } from '@hooks/useLog';

import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import React, { ReactNode } from 'react';



type SalesChartProps = {
	salesInfo: GetSalesByCategoryResponse[],
	isLoading: boolean,
	children?: ReactNode
}



const importoBodyTemplate = (rowData: GetSalesByCategoryResponse) => {
	return DataFormatters.formatPrice(rowData.importo_venduto);
};




export const CardTableSalesForecast: React.FC<SalesChartProps> = ({ salesInfo, isLoading }: SalesChartProps) => {
	const log = useLog();


	const formatFooterTemplate = () => {
		const totalSales: number = salesInfo.reduce((total, current) => total + current.importo_venduto, 0);

		return (
			<>
				<span className="font-bold">
					Totale acquistato: {DataFormatters.formatPrice(totalSales)}
				</span>
			</>
		)
	}


	const dtParameters: DataTableProps<GetSalesByCategoryResponse[]> = {
		scrollable: true,
		scrollHeight: "500px",
		rowHover: true,
		stripedRows: true,
		paginator: false,

		lazy: false,
		loading: isLoading,
		value: salesInfo,

		columnResizeMode: "fit",
		emptyMessage: "Nessuna categoria venduta",

		footer: formatFooterTemplate(),
		dataKey: "categoria",
	}


	return (
		<>
			<DataTable className="tablecategory p-datatable-sm" {...dtParameters}>
				<Column field="categoria" header="Cod.Art." style={{width: '10rem'}} sortable />
				<Column field="descrizione_categoria" header="Categoria" sortable />

				<Column field="importo_venduto" header="Importo" style={{width: '10rem'}} body={importoBodyTemplate} sortable />
			</DataTable>
		</>
	);
};
