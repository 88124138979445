import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { OrdersService, OrderType } from '@services/OrdersService';
import { GetOrdersHeadersResponse } from '@dto/Responses/Orders/GetOrdersHeadersResponse';
import { PagedData } from '@models/PagedData';


const DEFAULT_ORDERS_PER_PAGE = 25;


export type OrderListRequest = {
	PageNumber: number,
	PageSize: number,
	CustomerID?: number,
	OrderID?: string,
	OrderType: OrderType,
}


export const defaultOrderListRequest: OrderListRequest = {
	OrderType: "A",
	PageNumber: 1,
	PageSize: DEFAULT_ORDERS_PER_PAGE,
}



export const useOrdersList = (olr: OrderListRequest) => {
	const log = useLog();

	const ordersService = useSingleton<OrdersService>(() => new OrdersService(), []);

	const queryInfo = useQuery<PagedData<GetOrdersHeadersResponse>>({
		queryKey: ['list-orders-headers', olr],
		queryFn: async () => ordersService.listOrdersHeaders(olr.OrderType, olr.PageNumber, olr.PageSize, olr.CustomerID)
	});

	return {
		...queryInfo,
		ordersHeader: queryInfo.data?.data ?? new Array<GetOrdersHeadersResponse>(),
		totalRecords: queryInfo.data?.TotalRecords ?? 0,
	}
};
