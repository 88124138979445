import { GetForecastSalesByCategoryRequest } from '@dto/Requests/Statistics/Customs/CTAmonn/GetForecastSalesByCategoryRequest';
import { GetSalesByArticleRequest } from '@dto/Requests/Statistics/Customs/CTAmonn/GetSalesByArticleRequest';
import { GetSalesByCategoryRequest } from '@dto/Requests/Statistics/Customs/CTAmonn/GetSalesByCategoryRequest';
import { GetSalesBySubcategoryRequest } from '@dto/Requests/Statistics/Customs/CTAmonn/GetSalesBySubcategoryRequest';
import { GetVOCStatsRequest } from '@dto/Requests/Statistics/GetVOCStatsRequest';
import { GetForecastSalesByCategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetForecastSalesByCategoryResponse';
import { GetSalesByArticleResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesByArticleResponse';
import { GetSalesByCategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesByCategoryResponse';
import { GetSalesBySubcategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesBySubcategoryResponse';
import { GetVOCStatsResponse } from '@dto/Responses/Statistics/GetVOCStatsResponse';
import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';

import { STATISTICS } from '@transport/ApiDefinitions';

import { GShopHttpEngine } from '@transport/GShopHttpEngine';



export class StatisticsService {
	private log: BaseLogger;
	private httpEngine: GShopHttpEngine;



	constructor() {
		this.log = new KzLogger('StatisticsService');

		this.httpEngine = new GShopHttpEngine();
	}



	public async getVocStats(customerID: number): Promise<GetVOCStatsResponse[]> {
		this.log.debug(`Getting VOC stats`);

		if (customerID === 0) {
			return new Array<GetVOCStatsResponse>();
		}

		const vocParams: GetVOCStatsRequest = new GetVOCStatsRequest(customerID);

		const responseDTO: GetVOCStatsResponse[] = await this.httpEngine.getAsArray<GetVOCStatsResponse>(GetVOCStatsResponse, STATISTICS.VOC, vocParams);
		//const responseDTOSorted = responseDTO.sort((a, b) => a.categoria.toLowerCase() > b.categoria.toLowerCase() ? 1 : -1);

		return responseDTO;
	}



	public async getCustomStat_SalesByArticle(customerID?: number): Promise<GetSalesByArticleResponse[]> {
		this.log.debug(`Getting Sales by Article`);

		const salesByArticleParams: GetSalesByArticleRequest = new GetSalesByArticleRequest(customerID);

		const responseDTO: GetSalesByArticleResponse[] = await this.httpEngine.getAsArray<GetSalesByArticleResponse>(GetSalesByArticleResponse, STATISTICS.CUSTOM_SALES_BY_ARTICLE, salesByArticleParams);

		return responseDTO;
	}



	public async getCustomStat_SalesByCategory(customerID?: number): Promise<GetSalesByCategoryResponse[]> {
		this.log.debug(`Getting Sales by Category`);

		const salesByCategoryParams: GetSalesByCategoryRequest = new GetSalesByCategoryRequest(customerID);

		const responseDTO: GetSalesByCategoryResponse[] = await this.httpEngine.getAsArray<GetSalesByCategoryResponse>(GetSalesByCategoryResponse, STATISTICS.CUSTOM_SALES_BY_CATEGORY, salesByCategoryParams);

		return responseDTO;
	}



	public async getCustomStat_SalesBySubcategory(customerID?: number): Promise<GetSalesBySubcategoryResponse[]> {
		this.log.debug(`Getting Sales by Subcategory`);

		const salesBySubcategoryParams: GetSalesBySubcategoryRequest = new GetSalesBySubcategoryRequest(customerID);

		const responseDTO: GetSalesBySubcategoryResponse[] = await this.httpEngine.getAsArray<GetSalesBySubcategoryResponse>(GetSalesBySubcategoryResponse, STATISTICS.CUSTOM_SALES_BY_SUBCATEGORY, salesBySubcategoryParams);

		return responseDTO;
	}



	public async getCustomStat_ForecastSalesByCategory(customerID?: number): Promise<GetForecastSalesByCategoryResponse[]> {
		this.log.debug(`Getting forecast sales`);

		const forecastSalesByCategoryParams: GetForecastSalesByCategoryRequest = new GetForecastSalesByCategoryRequest(customerID);

		const responseDTO: GetForecastSalesByCategoryResponse[] = await this.httpEngine.getAsArray<GetForecastSalesByCategoryResponse>(GetForecastSalesByCategoryResponse, STATISTICS.CUSTOM_SALES_FORECAST, forecastSalesByCategoryParams);

		return responseDTO;
	}
}