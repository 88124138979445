import { t, classToPlain } from '@deepkit/type';
import { GShopRequest } from '@dto/Requests/GShopRequest';



export class GetBackOrdersRequest implements GShopRequest {
    @t public CustomerID!: number;


    public constructor(customerID: number) {
        this.CustomerID = customerID;
    }



    public toJson(): unknown {
        const js: unknown = classToPlain(GetBackOrdersRequest, this);

        return js;
    }
}
