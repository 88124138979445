import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';


export class GetCustomerResponse extends GShopResponse {
	@t public codice!: number;
	@t public ragione_sociale_1!: string;
	@t public ragione_sociale_2!: string;
	@t public nickname!: string;
	@t public indirizzo!: string;
	@t public localita!: string;
	@t public cap!: string;
	@t public provincia!: string;
	@t public stato!: string;
	@t public partita_iva!: string;
	@t public codice_fiscale!: string;
	@t public telefoni!: string;
	@t public telefax!: string;
	@t public cellulari!: string;
	@t public ufficio!: string;
	@t public no_split_payment!: string;
	@t public pagamento!: string;
	@t public descrizione_pagamento!: string;
	@t public agente!: number;
	@t public destinatario!: number;
	@t public spedizioniere!: number;
	@t public deposito_predefinito!: number;
	@t public tipo!: string;
	@t public descrizione_tipo!: string;
	@t public area!: string;
	@t public nazione!: string;
	@t public listino!: string;
	@t public sconto!: number;
	@t public fido_massimo!: number;
	@t public annotazioni!: string;
	@t public sito_web!: string;
	@t public riferimento_e_mail_1!: string;
	@t public indirizzo_e_mail_1!: string;
	@t public riferimento_e_mail_2!: string;
	@t public indirizzo_e_mail_2!: string;
	@t public riferimento_e_mail_3!: string;
	@t public indirizzo_e_mail_3!: string;
	@t public riferimento_e_mail_pec!: string;
	@t public indirizzo_e_mail_pec!: string;
	@t public pec_fattura_elettronica!: string;
}
