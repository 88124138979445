import React, { ChangeEvent, ReactNode } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';

import { useLog } from '@hooks/useLog';
import { useAtom } from 'jotai';

import { OrderAnnotazioni, orderAnnotazioniAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderAnnotazioniAtom';
import { useUpdate } from '@libs/KzHooks/useUpdate';



type TabAnnotazioniProps = {
	noteCliente: string | undefined,
	noteAgente: string | undefined,
	children?: ReactNode
}



export const TabNotes: React.FC<TabAnnotazioniProps> = ({ noteCliente, noteAgente }: TabAnnotazioniProps) => {
	const log = useLog();

	const [ annotazioni, setAnnotazioni ] = useAtom(orderAnnotazioniAtom);


	useUpdate(() => {
		setAnnotazioni((draft: OrderAnnotazioni) => {
			draft.NoteCliente = noteCliente ?? "";
			draft.NoteAgente = noteAgente ?? "";
		});

	}, [ noteCliente, noteAgente ]);



	const onAnnotazioniClienteTextChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setAnnotazioni((draft: OrderAnnotazioni) => {
			draft.NoteCliente = e.target.value;
		});
	}


	const onAnnotazioniAgenteTextChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setAnnotazioni((draft: OrderAnnotazioni) => {
			draft.NoteAgente = e.target.value;
		});
	}



	// Non riesco a passar un object di properties (InputTextareaProps): TS2769: No overload matches this call.
	const itaNoteClienteProperties = {
		value: noteCliente,     // potrei usare quello dell'atom che corrisponde alle props (annotazioni.NoteCliente)
		rows: 5,
		cols: 50,
		placeholder: "Inserisci eventuali annotazioni del cliente per l'ordine",
		onChange: onAnnotazioniClienteTextChanged
	}



	const itaNoteAgenteProperties = {
		value: noteAgente,      // potrei usare quello dell'atom che corrisponde alle props (annotazioni.NoteAgente)
		rows: 5,
		cols: 50,
		placeholder: "Note personali dell'agente riguardanti questo ordine",
		onChange: onAnnotazioniAgenteTextChanged
	}



	return (
		<>
			<h6>Note cliente sull&lsquo;ordine</h6>
			<InputTextarea {...itaNoteClienteProperties} style={ { width: "100%" } } />
			<Divider />

			<h6>Note agente sull&lsquo;ordine</h6>
			<InputTextarea {...itaNoteAgenteProperties} style={ { width: "100%" } } />
			<Divider />
		</>
	);
};

//TabNotes.whyDidYouRender = true