import React, { ReactNode} from 'react';

import { useLog } from '@hooks/useLog';
import { useImmer } from 'use-immer';
import { GetVOCStatsResponse } from '@dto/Responses/Statistics/GetVOCStatsResponse';

//import 'chart.js/auto';       <<-- Senza tree-shaking (non serve Chart.register)
import { Chart, DoughnutController, ArcElement, CategoryScale, ChartData, ChartOptions, Filler, Legend, Title, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2'

import { useUpdate } from '@libs/KzHooks/useUpdate';


// Da capire se è meglio metterlo in onMount()
Chart.register(
	DoughnutController,
	ArcElement,
	CategoryScale,
	Filler,
	Title,
	Tooltip,
	Legend
);



type VocChartProps = {
	vocInfo: GetVOCStatsResponse[],
	isLoading: boolean,
	children?: ReactNode
}



type VocChartModel = {
	labels: string[],
	data: number[],
}


const defaultVocChartModel: VocChartModel = {
	labels: [],
	data: []
}



const lightOptions: ChartOptions<'doughnut'> = {
	responsive: true,
	plugins: {
		legend: {
			display: true,
			// position: 'bottom',
			// align: 'start',
			position: 'left',
			align: 'center',
			// align: 'start',
			// fullSize: true,
			// maxWidth: 100,
			labels: {
				color: '#495057'
			}
		}
	}
};



export const CardVOCChart: React.FC<VocChartProps> = ({ vocInfo, isLoading }: VocChartProps) => {
	const log = useLog();

	const [ chartValues, setChartValues ] = useImmer<VocChartModel>(defaultVocChartModel);

	const chartData: ChartData<'doughnut'> = {
		labels: chartValues.labels,
		datasets: [
			{
				data: chartValues.data,
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
				],
				hoverBackgroundColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				]
			}
		]
	}

	/*
	function groupBy(objectArray: Array<string>, property: string) {
		return objectArray.reduce(function (acc, obj) {
			let key = obj[property]
			if (!acc[key]) {
				acc[key] = []
			}
			acc[key].push(obj)
			return acc
		}, {})
	}
	*/

	const mapVocToChartModel = () => {
		const cd: VocChartModel = {
			labels: [],
			data: []
		};


		const groupedVoc = new Map<string, number>();
		for (const gvsr of vocInfo) {
			const gKey= `[${gvsr.sottocategoria}] ${gvsr.descrizione_sottocategoria ?? "---"}`;
			const gValue: number = gvsr.peso_voc_consumato;

			groupedVoc.set(gKey, (groupedVoc.get(gKey) || 0) + gValue);
		}


		// eslint-disable-next-line unicorn/no-array-for-each
		groupedVoc.forEach((gValue: number, gKey: string) => {
			cd.labels.push(gKey.toLowerCase() ?? "");
			cd.data.push(gValue);
		});

		setChartValues(cd);
	}



	useUpdate(() => {
		if (!isLoading)
			mapVocToChartModel();
	}, [ vocInfo ]);


	return (
		<>
			<div className="flex justify-content-center">
				{/*<Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '40%' }} />*/}
				{/*<Chart type="doughnut" data={chartData} options={lightOptions} style={{ width: '50%' }} />*/}
				{/*<Chart type="doughnut" data={chartData} options={lightOptions} height="500" width="200" />*/}
				<Doughnut data={chartData} options={lightOptions} style={ {height: "250px" }} />
			</div>
		</>
	);
};
