import { atomWithImmer } from 'jotai/immer'


export interface OrderExtra {
	Deposito: number;
	SendEmail: boolean;
	EmailCliente: string;
}


const defaultExtra: OrderExtra = {
	Deposito : -1,
	SendEmail : true,
	EmailCliente : "",
};


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const orderExtraAtom = atomWithImmer<OrderExtra>(defaultExtra);
