import { CustomerListRequest, useCustomersList } from '@hooks/customers/useCustomersList';

import { useLog } from '@hooks/useLog';
import { TableCustomers } from '@layouts/desktop/pages/private/customers/CustomersList/TableCustomers';

import { useMount } from '@libs/KzHooks/useMount';
import React from 'react';
import { useImmer } from 'use-immer';



export const CustomersList: React.FC = () => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<CustomerListRequest>({ onlyActive: false });
	const { isLoading, customers, totalRecords } = useCustomersList(reqParams);


	useMount(() => {
		log.clear();
	});



	return (
		<div className="card">
			<h5>Clienti</h5>
			<TableCustomers customers={customers} isLoading={isLoading}/>
		</div>
	);
};
