import { GShopResponse } from '@dto/Responses/GShopResponse';
import { t } from '@deepkit/type';
import { GShopPagedResponse } from '@dto/Responses/GShopPagedResponse';



export class GetOrdersHeadersResponse extends GShopResponse {
	@t public documento!: string;
	@t public flag!: string;
	@t public stato!: string;
	@t public numero!: number;
	@t public data!: string;
	@t public codice_cliente!: number;
	@t public nominativo_cliente!: string;
	@t public codice_destinatario!: number;
	@t public nominativo_destinatario!: string;
	@t public articoli_ordinati!: number;
	@t public agente!: number;
	@t public spedizioniere!: number;
	@t public sigla!: string;
	@t public totale_merce!: number;
	@t public percentuale_sconto!: number;
	@t public importo_sconto!: number;
	@t public netto_merce!: number;
	@t public spese_trasporto!: number;
	@t public spese_imballo!: number;
	@t public spese_varie!: number;
	@t public spese_incasso!: number;
	@t public spese_bolli!: number;
	@t public totale_imponibile!: number;
	@t public totale_imposta!: number;
	@t public totale_documento!: number;
	@t public totale_in_valuta!: number;
	@t public totale_da_pagare!: number;
	@t public annotazioni!: string;
	@t public workstation!: string;
}


export class GetOrdersHeadersPagedResponse extends GShopPagedResponse<GetOrdersHeadersResponse> {
	@t.array(GetOrdersHeadersResponse) public data: GetOrdersHeadersResponse[] = [];
}

