import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';


type LoginRedirectProps = {
	children?: ReactNode
}



export const LoginRedirect: React.FC<LoginRedirectProps> = () => {
	return (
		<Redirect to="/login" />
	);
};
