import { CustomerSelection } from '@desktop/components/customers/CustomerSelection';
import { defaultSalesBySubcategoryRequest, SalesBySubcategoryRequest, useSalesBySubcategoryStats } from '@hooks/statistics/custom/ctamonn/useSalesBySubcategoryStats';

import { useLog } from '@hooks/useLog';
import { CardChartSalesBySubcategory } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesBySubcategory/CardChartSalesBySubcategory';

import { CardTableSalesBySubcategory } from '@layouts/desktop/pages/private/statistics/custom/ctamonn/SalesBySubcategory/CardTableSalesBySubcategory';

import { useMount } from '@libs/KzHooks/useMount';

import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';
import React from 'react';
import { useImmer } from 'use-immer';



export const SalesBySubcategory: React.FC = () => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<SalesBySubcategoryRequest>(defaultSalesBySubcategoryRequest);
	const { isLoading, salesBySubcategoryInfo, totalRecords } = useSalesBySubcategoryStats(reqParams);


	useMount(() => {
		log.clear();
	});



	//#region Eventi CardCustomer: Selezione del cliente dal TreeSelect

	const onSelectedCustomer = (customer: CustomerWithDetails, recipient: Recipient | undefined) => {
		setRequest((draft) => {
			draft.CustomerID = customer.codice;
		});
	};

	//#endregion



	return (
		<>
			<div className="content-section introduction">
				<h1>Statistiche Venduto (per sottocategoria)</h1>
				<p>Analisi prodotti venduti per sottocategoria articolo</p>
			</div>

			<div className="content-section implementation">
				<div className="card">
					<CustomerSelection showOnlyActive={true} onCustomerSelectedHandler={onSelectedCustomer} />
				</div>

				{(salesBySubcategoryInfo.length > 0) &&
                    <div className="card">
                        <CardTableSalesBySubcategory salesInfo={salesBySubcategoryInfo} isLoading={isLoading} />
                    </div>
				}

				{(salesBySubcategoryInfo.length > 0) &&
                    <div className="card">
                        <CardChartSalesBySubcategory salesInfo={salesBySubcategoryInfo} isLoading={isLoading} />
                    </div>
				}
			</div>
		</>
	);
};
