import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { StatisticsService } from '@services/StatisticsService';
import { GetVOCStatsResponse } from '@dto/Responses/Statistics/GetVOCStatsResponse';


export type VocRequest = {
	CustomerID: number,
}


export const defaultVocRequest: VocRequest = {
	CustomerID: 0,
}



export const useVOCInfo = (vr: VocRequest) => {
	const log = useLog();

	const statisticsService = useSingleton<StatisticsService>(() => new StatisticsService(), []);

	const queryInfo = useQuery<GetVOCStatsResponse[]>({
		queryKey: [ 'voc', vr ],
		queryFn: async () => statisticsService.getVocStats(vr.CustomerID),
		enabled: vr.CustomerID !== 0
	});


	return {
		...queryInfo,
		vocInfo: queryInfo.data ?? new Array<GetVOCStatsResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
