import { useQuery } from '@tanstack/react-query';
import { useLog } from '@hooks/useLog';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import { StatisticsService } from '@services/StatisticsService';
import { GetSalesBySubcategoryResponse } from '@dto/Responses/Statistics/Customs/CTAmonn/GetSalesBySubcategoryResponse';


export type SalesBySubcategoryRequest = {
	CustomerID?: number | undefined,
}


export const defaultSalesBySubcategoryRequest: SalesBySubcategoryRequest = {
	CustomerID: undefined,
}



export const useSalesBySubcategoryStats = (scr: SalesBySubcategoryRequest) => {
	const log = useLog();

	const statisticsService = useSingleton<StatisticsService>(() => new StatisticsService(), []);

	const queryInfo = useQuery<GetSalesBySubcategoryResponse[]>({
		queryKey: [ 'sscr', scr ],
		queryFn: async () => statisticsService.getCustomStat_SalesBySubcategory(scr.CustomerID)
	});


	return {
		...queryInfo,
		salesBySubcategoryInfo: queryInfo.data ?? new Array<GetSalesBySubcategoryResponse>(),
		totalRecords: queryInfo.data?.length ?? 0,
	}
};
