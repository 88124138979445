import React, { ReactNode } from 'react';

import { useLog } from '@hooks/useLog';
import { useMount } from '@libs/KzHooks/useMount';

import { useImmer } from 'use-immer';

import { ArticlesFavoritesRequest, createDefaultArticlesFavoritesRequest, useArticlesFavoritesList } from '@hooks/articles/useArticlesFavoritesList';
import { TableSelectArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/TableSelectArticles';
import { useFavoritesCategoriesList } from '@hooks/articles/useFavoritesCategoriesList';
import { useUpdate } from '@libs/KzHooks/useUpdate';
import { GetCategoriesResponse } from '@dto/Responses/Articles/GetCategoriesResponse';
import { CategoryItem } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/CategoryItem';
import { CustomFilters } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/CustomFilters';
import { TableType } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/TableType';
import { FilterType } from '@layouts/desktop/pages/private/orders/OrdersNew/DialogArticles/FilterType';



type PageFavoritesProps = {
	customerID: number,
	year: number,
	children?: ReactNode
}


export const PageFavoritesArticles: React.FC<PageFavoritesProps> = ({ customerID, year }: PageFavoritesProps) => {
	const log = useLog();

	const dafr = createDefaultArticlesFavoritesRequest(customerID, year);
	const [ reqParams, setRequest ] = useImmer<ArticlesFavoritesRequest>(dafr);
	const { isLoading, products, totalRecords } = useArticlesFavoritesList(reqParams);
	const { isLoading : isLoadingCategories, categories } = useFavoritesCategoriesList(customerID);
	const [ favoritesCategories, setFavoritesCategories ] = useImmer<Array<CategoryItem>>([]);


	useMount(() => {
		log.info("Loading customer favorites products list...");
	});


	useUpdate(() => {
		if (isLoadingCategories)
			return;

		const mappedCats = categories.map((c: GetCategoriesResponse) => (
			{
				categoryCode : c.codice,
				categoryDescription : c.descrizione
			}) as CategoryItem
		);

		setFavoritesCategories(mappedCats);
	}, [ isLoadingCategories, categories ]);



	const onPaginator = (currentPage: number, rowsPerPage: number): void => {
		setRequest((draft) => {
			draft.PageNumber = currentPage +1;
			draft.PageSize = rowsPerPage;
		});
	};



	const onSort = (fieldName: string, sortMode: number): void => {
		setRequest((draft) => {
			draft.SortField = fieldName;
			draft.SortMode = sortMode;
		});
	}



	const onFilter = (filters: FilterType[]): void => {
		setRequest((draft) => {
			// I filtri van sempre resettati altrimenti restan dentro valori sporchi
			// Es: articolo = ABC descrizione = Pippo => articolo = ABC descrizione = "", ho un bug
			// Ovvero non viene cancellata il filtro dalla descrizione in quanto non è presente in filters, di conseguenza non viene resettato il valore draft.descrizione che resta "pippo"
			draft.Article = undefined;
			draft.Description = undefined;
			draft.PageNumber = 1;               // Riparto sempre dalla prima pagina

			for (const f of filters) {
				switch (f.field.toLowerCase()) {
					case "articolo":
						draft.Article = f.value;
						break;

					case "descrizione":
						draft.Description = f.value;
						break;

					default:
						break;
				}
			}
		});
	}



	const onCustomFilter = (filterName: CustomFilters, filterValue: string[]) => {
		switch (filterName) {
			case CustomFilters.CATEGORIA:
				setRequest((draft) => {
					draft.Categories = filterValue;
					draft.PageNumber = 1;
				});
				break;

			default:
				break;
		}
	}



	return (
		<TableSelectArticles tableType={TableType.FAVORITES_ARTICLES} isLoading={isLoading} products={products} categories={favoritesCategories} totalRecords={totalRecords} pageSize={reqParams.PageSize} onPageChangedHandler={onPaginator} onSortHandler={onSort} onFilterHandler={onFilter} onCustomFilterHandler={onCustomFilter} />
	);
};