/* eslint-disable unicorn/consistent-function-scoping */
import React, { forwardRef, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { CSSTransition } from 'react-transition-group';
import { useLog } from '@hooks/useLog';
import { SidebarMenuItem } from '@desktop/components/menu/sidebar/kz/SidebarKz';


import "src/gui/layouts/desktop/components/menu/sidebar/kz/SidebarMenuKz.scss"



type SidebarMenuKzProps = {
	items: SidebarMenuItem[] | undefined,

	root?: boolean,
	menuMode: string,
	className?: string,

	parentMenuItemActive: boolean,

	onMenuItemClickHandler?: (event: React.SyntheticEvent<EventTarget>, item: SidebarMenuItem) => void,
	onRootMenuItemClickHandler?: (event: React.SyntheticEvent<EventTarget>) => void,

	onToggleMenuHandler?: (event: React.SyntheticEvent<EventTarget>) => void,
	children?: ReactNode
}



const SidebarMenuKz = forwardRef<HTMLUListElement, SidebarMenuKzProps>(({ root, items, className, menuMode, parentMenuItemActive, onMenuItemClickHandler, onRootMenuItemClickHandler, children }: SidebarMenuKzProps, ref: React.Ref<HTMLUListElement>) => {
	const log = useLog();
	const nodeRef = useRef(null);
	const [ activeIndex, setActiveIndex ] = useState<number | undefined>(undefined);


	const onItemClick = (event: React.SyntheticEvent<EventTarget>, item: SidebarMenuItem, index: number | undefined) => {
		if (item.disabled) {
			event.preventDefault();
			return;
		}

		if (item.command) {
			item.command(event, item);
			event.preventDefault();
		}

		if (item.items) {
			event.preventDefault();
		}

		if (root && onRootMenuItemClickHandler) {
			onRootMenuItemClickHandler(event);
		}

		if (onMenuItemClickHandler)
			onMenuItemClickHandler(event, item);

		setActiveIndex(index === activeIndex ? undefined : index);
	};



	const onMenuItemMouseEnter = (index: number | undefined) => {
		//log.info("onMenuItemMouseEnter");

		//if (root && menuActive && isSlimMenu()) {
			//setActiveIndex(index);
		//}
	};



	const visible = (item: SidebarMenuItem): boolean => {
		return item.visible !== false;
		//return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
	};



	const createMenuEntry = (item: SidebarMenuItem, index:  number | undefined) => {
		let iconElement: string | ReactNode

		if (typeof item.icon === "string") {
			const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
			iconElement = <i className={menuitemIconClassName}></i>;
		}
		else {
			iconElement = <i>{item.icon}</i>;
		}

		const content = (
			<>
				{ iconElement }
				<span className="layout-menuitem-text">{item.label}</span>
				{ item.items && <i className="pi pi-fw pi-chevron-down layout-submenu-toggler"></i>}
				{ item.badge && <Badge value={item.badge} style={{ height: '100%' }} />}
			</>
		);

		const commonLinkProps = {
			'style': item.style,
			'className': classNames(item.className, 'p-ripple', { 'p-disabled': item.disabled }),
			'target': item.target,
			'onClick': (e: any) => onItemClick(e, item, index),
			'onMouseEnter': () => onMenuItemMouseEnter(index),
		};


		if (item.to) {
			return <NavLink to={item.to} exact activeClassName="active-route" {...commonLinkProps}>{content}</NavLink>;
		}

		return <a href={item.url} rel="noopener noreferrer" {...commonLinkProps}>{content}</a>;
	};



	const generateMenuItems = () => {
		const transitionTimeout = { enter: 500, exit: 40 };

		return items?.map((currentItem, i) => {

			if (visible(currentItem)) {
				const menuitemClassName = classNames({ 'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !currentItem.disabled });
				const menuEntry = createMenuEntry(currentItem, i);
				const tooltip = (
					<div className="layout-menu-tooltip">
						<div className="layout-menu-tooltip-arrow"></div>
						<div className="layout-menu-tooltip-text">{currentItem.label}</div>
					</div>
				);

				const subitems = visible(currentItem) ? currentItem.items : new Array<SidebarMenuItem>();

				return (
					<li key={currentItem.label || i} className={menuitemClassName} role="menuitem">
						{ menuEntry }
						{/*{tooltip}*/}
						{/*<CSSTransition classNames="p-toggleable-content" timeout={transitionTimeout} in={activeIndex === i} unmountOnExit>*/}
						<CSSTransition nodeRef={nodeRef} classNames="p-toggleable-content" timeout={transitionTimeout} in={activeIndex === i} unmountOnExit>
							<SidebarMenuKz ref={nodeRef} items={subitems ?? undefined} menuMode={menuMode} parentMenuItemActive={activeIndex === i} onMenuItemClickHandler={onMenuItemClickHandler} />
						</CSSTransition>
					</li>
				);
			}

			return <></>;
		});
	};


	if (!items) {
		return <></>;
	}

	const generatedItems = generateMenuItems();

	return (
		<ul ref={ref} className={className} role="menu">
			{ generatedItems }
		</ul>
	);
});
SidebarMenuKz.displayName = "SidebarMenuKz";


export { SidebarMenuKz };