import React, { ReactNode } from 'react';

import { CustomerListRequest, defaultCustomerListRequest, useCustomersList } from '@hooks/customers/useCustomersList';
import { useImmer } from 'use-immer';
import { useLog } from '@hooks/useLog';

import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';
import { CustomerInfo } from '@desktop/components/customers/CustomerInfo';
import { CustomerTreeSelect } from '@desktop/components/customers/CustomerTreeSelect';
import { useUpdate } from '@libs/KzHooks/useUpdate';



type CardCustomerProps = {
	customerID?: number,
	recipientID?: number,
	showOnlyActive: boolean,
	onCustomerSelectedHandler: (customer: CustomerWithDetails, recipient: Recipient | undefined) => void,
	formatCustomer?: (customer: CustomerWithDetails, recipient: Recipient | undefined) => string,
	formatCustomerStyle?: (customer: CustomerWithDetails, recipient: Recipient | undefined) => Promise<string>,
	children?: ReactNode
}



export const CustomerSelection: React.FC<CardCustomerProps> = ({ customerID, recipientID, showOnlyActive, onCustomerSelectedHandler, formatCustomer, formatCustomerStyle, children }: CardCustomerProps) => {
	const log = useLog();

	const [ reqParams, setRequest ] = useImmer<CustomerListRequest>(defaultCustomerListRequest);
	const { isLoading, customers, totalRecords, ...queryInfo } = useCustomersList(reqParams);

	const [ customerInfo, setCustomerInfo ] = useImmer<CustomerWithDetails | undefined>(undefined);


	useUpdate(() => {
		setRequest((draft) => {
			/** Info su "Cannot assign to read only property 'onlyActive' of object '#<Object>'" che c'era prima.
			Il problema è che draft se modificato, DEVE esser un nuovo object.
			NON FUNZIONA
			draft = defaultCustomerListRequest non crea un nuovo oggetto, ma assegna la reference/puntatore (quindi al primo render funziona, al secondo, è considerata readonly e da exception.
			Un exception che nn son riuscito a triggherare in dev mode (ho levato anche tutti gli ErrorHandlers, metter try/catch), niente.
			In production invece salta fuori.

			FUNZIONA
			draft = { ...defaultCustomerListRequest }
			 oppure
			draft = ObjectUtility.cloneObject<CustomerListRequest>(defaultCustomerListRequest);
			*/
			draft.onlyActive = showOnlyActive;
		});

	}, [ showOnlyActive ]);



	const onCustomerSelected = (customer: CustomerWithDetails | undefined, recipient: Recipient | undefined) => {
		if (customer != undefined)
			onCustomerSelectedHandler(customer, recipient);

		setCustomerInfo(customer);
	}



	return (
		<>
			<h6>Seleziona un cliente</h6>
			<CustomerTreeSelect isLoading={isLoading} customers={customers} customerID={customerID} recipientID={recipientID} onCustomerSelectedHandler={onCustomerSelected} formatCustomer={formatCustomer} formatCustomerStyle={formatCustomerStyle} />
			<CustomerInfo customerInfo={customerInfo} />
		</>
	);
};