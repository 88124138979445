import { BaseLogger } from '@libs/loggers/BaseLogger';
import { KzLogger } from '@libs/loggers/KzLogger';

import { Cart, CartRow } from '@models/Cart';
import { CartRepository } from '@repositories/CartRepository';
import dayjs from 'dayjs';



// ProductRow è il model UI che corrisponde alla riga che seleziono dal TabArticles, e che aggiungo al carrello. Internamente uso Cart e CartRow, perchè hanno degli ID sulla riga.
// Ecco perchè non sono interscambiali
// In futuro potrei valutare di unire i due model, e gestire anche gli ID in modo diverso
export class ArticleRow {
    public articolo!: string;
    public descrizione!: string;
    public um!: string;
    public codice_iva!: string;

    public quantita_1!: number;
    public quantita_2!: number;
    public quantita_3!: number;

    public coefficiente_moltiplicativo!: number;

    public prezzo!: number;         // Variabile da Agente
    public sconto!: string;         // Variabile da Agente

    public prezzo_listino!: number;

    public prezzo_personalizzato!: number;
    public sconto_personalizzato!: string;

    public non_scontabile!: boolean;
    public sconto_massimo!: string;

    public prezzo_scontato!: number;        // Calcolato scontato dal backend

    public specialrow!: boolean;
    public notes!: string;
}



export class CartService {
    private cartRepository: CartRepository;
    private log: BaseLogger;


    constructor() {
        this.log = new KzLogger("Cart Service");

        this.cartRepository = new CartRepository();
    }



    /**
     * Crea un nuovo carrello vuoto
     * @returns {Cart}
     */
    public createCart(): Cart {
        this.log.debug(`Creating new cart`);

        const cart: Cart = new Cart();
        cart.ID = `## [${dayjs().format("YYYY-MM-DD")}]_[${dayjs().format("HH:mm:ss.SSS")}] ##`;

        return cart;
    }



    /**
     * Carica il carrello di un cliente NON ancora confermato, salvato localmente
     * @returns {Promise<Cart | undefined>}
     * @param customerID codice cliente
     */
    public async loadCart(customerID: number): Promise<Cart | undefined> {
        this.log.debug(`Loading temporany local cart for customer #${customerID}`);

        try {
            const localCart = await this.cartRepository.loadCart(customerID);
            if (localCart == undefined) {
                this.log.info(`No cart found for customer #${customerID}.`);
            }

            return localCart;
        }
        catch (ex) {
            this.log.logException(ex);

            throw ex;
        }
    }



    /**
     * Controlla se esiste un carrello in locale per un cliente
     * @returns {Promise<Cart | undefined>}
     * @param customerID codice cliente
     */
    public async existsCart(customerID: number): Promise<boolean> {
        //this.log.debug(`Check if exists local cart for customer #${customerID}`);

        try {
            return await this.cartRepository.existsCart(customerID);
        }
        catch (ex) {
            this.log.logException(ex);

            throw ex;
        }
    }



    /**
     * Carica tutti i carrelli NON confermati salvati localmente
     * @returns {Promise<Cart>}
     */
    public async loadCarts(): Promise<Cart[]> {
        this.log.debug(`Loading ALL temporany local carts`);

        try {
            let carts = await this.cartRepository.loadCarts();

            if (carts?.length === 0) {
                this.log.debug("No local carts found");
                carts = new Array<Cart>();
            }
            else {
                this.log.info(`Found ${carts?.length} local carts`);
            }

            return carts;
        }
        catch (ex) {
            this.log.logException(ex);

            throw ex;
        }
    }



    /**
     * Salva un carrello NON confermato in locale
     * @param cart carrello interessato
     * @returns {Promise<void>}
     */
    public async saveCart(cart: Cart | undefined): Promise<void> {
        try {
            if (cart != undefined) {
                this.log.debug(`Saving local cart for customer #${cart.CustomerID}`);

                await this.cartRepository.saveCart(cart);
            }
        }
        catch (ex) {
            this.log.logException(ex);

            throw ex;
        }
    }



    /**
     * Svuota un carrello esistente
     * @param cart carrello interessato
     * @returns {Promise<void>}
     */
    public async emptyCart(cart: Cart | undefined): Promise<void> {
        try {
            if (cart != undefined) {
                this.log.debug(`Emptying local cart for customer #${cart.CustomerID}`);

                await this.cartRepository.clearCart(cart.CustomerID);

                cart.clear();
            }
        }
        catch (ex) {
            this.log.logException(ex);

            throw ex;
        }
    }



    /**
     * Imposta il cliente / destinatario di un carrello
     * @param cart carrello
     * @param customerID codice cliente
     * @param recipientID codice destinatario
     */
    public setCustomerInfo(cart: Cart | undefined, customerID: number, recipientID: number | undefined): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.CustomerID = customerID;
            cart.RecipientID = (recipientID == undefined) ? 0 : recipientID;
        }
        catch {
            this.log.warn("Cannot set customer. No cart created");
        }
    }




    /**
     * Imposta i dati extra di un carrello (email, deposito)
     * @param cart carrello
     * @param sendEmail inviare l'email di conferma
     * @param emailCustomer email cliente
     * @param depositID deposito scelto
     */
    public setExtraInfo(cart: Cart | undefined, sendEmail: boolean, emailCustomer: string, depositID: number): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.DepositID = (depositID == 0) ? 1 : depositID;
            cart.isSendEmail = sendEmail;
            cart.EmailCustomer = emailCustomer;
        }
        catch {
            this.log.warn("Cannot set customer. No cart created");
        }
    }



    /**
     * Imposta le note per un carrello
     * @param cart carrello
     * @param noteAgente nota del cliente
     * @param noteCliente nota del cliente
     */
    public setNote(cart: Cart | undefined, noteCliente: string, noteAgente: string): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.NoteCliente = noteCliente;
            cart.NoteAgente = noteAgente;
        }
        catch {
            this.log.warn("Cannot set NoteCliente. No cart created");
        }
    }



    /**
     * Imposta le note di un cliente per un carrello
     * @param cart carrello
     * @param noteCliente nota del cliente
     */
    public setNoteCustomer(cart: Cart | undefined, noteCliente: string): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.NoteCliente = noteCliente;
        }
        catch {
            this.log.warn("Cannot set NoteCliente. No cart created");
        }
    }




    /**
     * Imposta le note dell'agente per un carrello (non visibili dal cliente)
     * @param cart carrello
     * @param noteAgente nota dell'agente
     */
    public setNoteAgent(cart: Cart | undefined, noteAgente: string): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.NoteAgente = noteAgente;
        }
        catch {
            this.log.warn("Cannot set NoteCliente. No cart created");
        }
    }




    /**
     * Aggiungo un prodotto al carrello
     * @param cart carrello interessato
     * @param product prodotto da aggiungere
     */
    public addProduct(cart: Cart | undefined, product: ArticleRow): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            this.log.debug("Adding product: ", product);
            cart.addProduct(product);
        }
        catch (ex) {
            this.log.error(ex);
        }
    }



    /**
     * Rimuovo un prodotto dal carrello
     * @param cart carrello
     * @param cartRow prodotto da rimuovere
     */
    public removeProduct(cart: Cart | undefined, cartRow: CartRow): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.removeProduct(cartRow.ID);
        }
        catch (ex) {
            this.log.error(ex);
        }
    }



    /**
     * Marca una riga come special
     * @param cart carrello
     * @param cartRow riga da considerare special
     */
    public setSpecialRow(cart: Cart | undefined, cartRow: CartRow): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.updateSpecialRow(cartRow.ID, !cartRow.SpecialRow);
        }
        catch (ex) {
            this.log.error(ex);
        }
    }



    /**
     * Aggiorna la quantità di un prodotto dal carrello
     * @param cart carrello
     * @param cartRow prodotto da rimuovere
     * @param quantity quantità da aggiungere
     * @param price
     * @param discount
     */
    public updateProduct(cart: Cart | undefined, cartRow: CartRow, quantity: number, price: number, discount: string): void {
        try {
            if (cart == undefined)
                throw new Error("No valid cart found!");

            cart.updateCartRow(cartRow.ID, quantity, price, discount);
        }
        catch (ex) {
            this.log.error(ex);
        }
    }
}
