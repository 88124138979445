import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';

import { useSidebarBadge } from '@hooks/sidebar/useSidebarBadge';
import { useAtomValue } from 'jotai/utils';
import { sidebarBadgeAtom } from '@atoms/SidebarBadgesAtom';
import { useSettings } from '@hooks/useSettings';
import { ModulesType, settingsAtom } from '@atoms/SettingsAtom';
import { useHistory } from 'react-router-dom';
import { useUpdate } from '@libs/KzHooks/useUpdate';

import { SidebarMenuKz } from '@desktop/components/menu/sidebar/kz/SidebarMenuKz';

import { useLog } from '@hooks/useLog';

import 'src/gui/layouts/desktop/components/menu/sidebar/kz/SidebarMenuKz.scss';
import {
	FaBezierCurve,
	FaBox,
	FaBoxes,
	FaBug,
	FaChartLine,
	FaClipboardList,
	FaEuroSign,
	FaHome,
	FaInfo,
	FaList,
	FaRegFolderOpen,
	FaStar,
	FaUsers,
} from 'react-icons/fa';

import { GiGasMask } from 'react-icons/gi';
import { ImStatsDots } from 'react-icons/im';
import { IoPeople } from 'react-icons/io5';



export type SidebarMenuItem = {
	label: string,
	badge?: string,
	disabled?: boolean,
	to?: string,
	url?: string,
	icon?: string | ReactNode,
	style?: React.CSSProperties,
	target?: string,
	className?: string,
	command?: (event: React.SyntheticEvent<EventTarget>, item: SidebarMenuItem) => void,
	visible?: boolean,
	items?: SidebarMenuItem[],
}



type SidebarKzProps = {
	onMenuItemClickHandler?: (event: React.SyntheticEvent<EventTarget>, item: SidebarMenuItem) => void,

	children?: ReactNode
}



export const SidebarKz: React.FC<SidebarKzProps> = ({ onMenuItemClickHandler }: SidebarKzProps) => {
	const log = useLog();

	// L'atom è superfluo, però in caso lo volessi usare da qualche altra parte, ho qui pronto uno snippet di codice già fatto
	const { isLoading, sidebarInfo } = useSidebarBadge();
	const badgesAtom = useAtomValue(sidebarBadgeAtom);

	const { settingsState } = useSettings();
	const settingAtom = useAtomValue(settingsAtom);

	const history = useHistory();

	const [ sidebarStatic, setSidebarStatic ] = useState<boolean>(false);
	const [ sidebarActive, setSidebarActive ] = useState<boolean>(false);

	const [ menuItems, setMenuItems ] = useState<SidebarMenuItem[]>([]);

	const menuMode = 'sidebar';



	const loadMenu = useCallback(() => {
		const menu: Array<SidebarMenuItem> = [
			{ label: 'Dashboard', icon: <FaHome />, to: '/dashboard' },
			{
				label: 'Archivi', icon: <IoPeople />,
				items: [
					{ label: 'Clienti', icon: <FaUsers />, badge: sidebarInfo?.total_customers.toString(), to: '/customers/' },
					{ label: 'Articoli', icon: <FaStar />, badge: sidebarInfo?.total_articles.toString(), to: '/products/' },
					{ label: 'Scadenziario', icon: <FaEuroSign />, to: '/payments/' },
				],
			},
			{
				label: 'Ordini', icon: <FaClipboardList />,
				items: [
					{ label: 'Nuovo ordine', icon: <FaList />, to: '/orders/new' },
					{ label: 'Visualizza ordini', icon: <FaRegFolderOpen />, to: '/orders/list' },
				],
			},
			{
				label: 'Statistiche', icon: <ImStatsDots />,
				items: [
					{ label: 'VOC', icon: <GiGasMask />, to: '/statistics/voc', visible: (settingAtom.modulesAvailables.some(x => x == ModulesType.VOC)) },
					{
						label: 'Venduto per categoria', icon: <FaBezierCurve />, to: '/statistics/custom/sales-by-category',
						visible: (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_CATEGORY)),
					},
					{
						label: 'Venduto per sottocategoria', icon: <FaBoxes />, to: '/statistics/custom/sales-by-subcategory',
						visible: (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_SUBCATEGORY)),
					},
					{
						label: 'Venduto per articolo', icon: <FaBox />, to: '/statistics/custom/sales-by-article',
						visible: (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_BY_ARTICLE)),
					},
					{
						label: 'Previsione fatturato', icon: <FaChartLine />, to: '/statistics/custom/sales-forecast',
						visible: (settingAtom.modulesAvailables.some(x => x == ModulesType.CUSTOM_SALES_FORECAST)),
					},
				],
			},
			{ label: 'About', icon: <FaInfo />, to: '/about' },
			{ label: 'Debug', icon: <FaBug />, to: '/debug', visible: import.meta.env.DEV },
		];

		return menu;
	}, [ sidebarInfo, settingAtom ]);



	useUpdate(() => {
		if (isLoading) {
			return;
		}

		const menuItems = loadMenu();
		setMenuItems(menuItems);
	}, [ isLoading, sidebarInfo, settingAtom ]);



	const onSidebarMouseOver = () => {
		if (menuMode === 'sidebar' && !sidebarStatic) {
			setSidebarActive(true);
		}
	};



	const onSidebarMouseLeave = () => {
		if (menuMode === 'sidebar' && !sidebarStatic) {
			setTimeout(() => {
				setSidebarActive(false);
			}, 250);
		}
	};



	const onToggleMenu = () => {
		setSidebarStatic(prevState => !prevState);
	};



	return (
		<div className="layout-menu-light">
			<div className={classNames('menu-wrapper', { 'layout-sidebar-active': sidebarActive })} onMouseOver={onSidebarMouseOver} onMouseLeave={onSidebarMouseLeave}>
				<div className="sidebar-logo">
					<button className="p-link" onClick={() => history.push('/')}>
						{/*<img className="logo" width="32" alt="logo" src={GShopLogo} />*/}
						{/*<img src="assets/layout/images/logo-freya-single.svg" alt="freya-layout" />*/}
					</button>
					<button className="sidebar-pin p-link" onClick={() => onToggleMenu()}>
						<span className="pin"></span>
					</button>
				</div>

				<div className="layout-menu-container">
					<SidebarMenuKz className="layout-menu" menuMode={menuMode} root parentMenuItemActive items={menuItems} onMenuItemClickHandler={onMenuItemClickHandler} />
				</div>
			</div>
		</div>
	);
};
